export default {
  recordUnion: {
    url: '/v1/equipment/material/recordUnion',
    method: 'GET',
    auth: true
  },
  recordCnt: {
    url: '/v1/equipment/material/recordCnt',
    method: 'GET',
    auth: true
  },
  recordList: {
    url: '/v1/equipment/material/recordList',
    method: 'GET',
    auth: true
  },
  sorts:{
    url: '/v1/equipment/material/sorts',
    method: 'GET',
    auth: true
  },
  recordCreate:{
    url: '/v1/equipment/material/record',
    method: 'POST',
    auth: true
  }
  
}
