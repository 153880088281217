export default {
    list: {
      url: '/v1/equipment/errorRecord/list',
      method: 'GET',
      auth: true
    },
    code:{
        url: '/v1/equipment/errorRecord/code',
        method: 'GET',
        auth: true
    },
    cnt:{
        url: '/v1/equipment/errorRecord/cnt',
        method: 'GET',
        auth: true
    },
    export:{
        url: '/v1/equipment/errorRecord/export',
        method: 'GET',
        auth: true
    },
    union:{
        url: '/v1/equipment/errorRecord/union',
        method: 'GET',
        auth: true
    },
    queryExportTask:{
        url: '/v1/equipment/errorRecord/queryExportTask',
        method: 'GET',
        auth: true
    }
}
