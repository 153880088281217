
import Vuex from 'vuex'
import getters from './getters'
import account from './modules/account'
import factory from './modules/factory'
import app from './modules/app'
import customer from './modules/customer'



const store = new Vuex.Store({
    modules: {
        account,
        factory,
        app,
        customer
    },
    getters
    
})


export default store
