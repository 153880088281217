/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t=i18n.global.t
export default {
    name: 'equipment',
    path: 'equipment',
    meta: {title: t('equipment.device.router')},
    component: () => import('@/pages/redirect'),
    children:[
    ]
}
