import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {api, resetBaseUri} from './api'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import i18n from '@/i18n';
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { install } from '@icon-park/vue-next/es/all';
import '@/assets/svgs/iconfont'
import SvgIcon from '@/icons'

import RouterBack from './components/RouterBack/index.vue'
// import hasPermission from '@/util/permission'
import VueQrcode from '@chenfengyuan/vue-qrcode';

import * as echarts from 'echarts'

import axios from 'axios'


Date.prototype.Format = function (fmt) { // author: meizz
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
    return fmt
}

const app = createApp(App)
app.use(router)

function initAppContainer() {
   
    install(app);
    SvgIcon(app)

    app.component('router-back', RouterBack)
    app.component(VueQrcode.name, VueQrcode);

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    app.use(ElementPlus, {
        locale: zhCn,
    })
    app.use(store)
    app.use(i18n)
    // app.use(hasPermission)


    app.config.globalProperties.$btnCheck = function (val) {
        return app.config.globalProperties.$store.getters.permissionMap.includes(val)
    }

    app.config.globalProperties.$echarts = echarts;
    app.config.globalProperties.$api = api;
    app.config.globalProperties.$app = app;



    app.mount('#app')
}


// 异步函数，用于获取本地文件内容
async function fetchLocalConfig() {
  try {
    const response = await axios.get('/static/config.json');
    const localConfig = response.data;

    console.log("re")
    // 更新 API_URL 
    resetBaseUri(localConfig.api)
    initAppContainer()
   
  } catch (error) {
    initAppContainer()
    console.error('Failed to fetch local config:', error);
  }
}

// 异步加载本地文件并更新 API_URL
fetchLocalConfig();


