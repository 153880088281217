export default {
  relate: {
    url: '/v1/account/org/relate',
    method: 'GET',
    auth: true
  },

  balanceAlarm: {
    url: '/v1/account/org/balanceAlarm',
    method: 'GET',
    auth: true
  },

  balanceAlarmSet: {
    url: '/v1/account/org/balanceAlarmSet',
    method: 'POST',
    auth: true
  },

  infoUnion: {
    url: '/v1/account/org/infoUnion',
    method: 'GET',
    auth: true
  },

  modify: {
    url: '/v1/account/org/modify',
    method: 'POST',
    auth: true
  },

  union: {
    url: '/v1/account/org/union',
    method: 'GET',
    auth: true
  },

  list: {
    url: '/v1/account/org/list',
    method: 'GET',
    auth: true
  },
  cnt :{
    url:'/v1/account/org/cnt',
    method:'GET',
    auth: true
  },
  create :{
    url:'/v1/account/org/create',
    method:'POST',
    auth: true
  },
  apply :{
    url:'/v1/account/org/apply',
    method:'POST',
    auth: true
  },
  modify :{
    url:'/v1/account/org/modify',
    method:'POST',
    auth: true
  },  
  updateSetting :{
    url:'/v1/account/org/updateSetting',
    method:'POST',
    auth: true
  },  
  getSetting :{
    url:'/v1/account/org/getSetting',
    method:'GET',
    auth: true
  }

  




}
