import user from './api-user'
import role from './api-role'
import department from './api-department'


export default {
  user: user,
  role: role,
  department: department
}
