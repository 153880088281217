export default {
    cupModel: {
      url: '/v1/equipment/product/cupModel',
      method: 'GET',
      auth: true
    },
    list: {
      url:'/v1/equipment/product/list',
      method: 'GET',
      auth: true
    },
    create:{
      url:'/v1/equipment/product/create',
      method:'POST',
      auth: true
    },
    cnt: {
      url:'/v1/equipment/product/cnt',
      method: 'GET',
      auth: true
    },
    versionList:{
      url:'/v1/equipment/product/versionList',
      method:'GET',
      auth: true
    },
    versionCreate:{
      url:'/v1/equipment/product/versionCreate',
      method: 'POST',
      auth: true
    },
    versionUpdate:{
      url:'/v1/equipment/product/versionUpdate',
      method: 'POST',
      auth: true
    },
    versionCnt:{
      url:'/v1/equipment/product/versionCnt',
      method:'GET',
      auth: true
    },
    detail:{
      url:'/v1/equipment/product/detail',
      method:'GET',
      auth: true
    },
    versionSetDefault:{
      url:'/v1/equipment/product/versionSetDefault',
      method:'POST',
      auth: true
    },
    versionDelete:{
      url:'/v1/equipment/product/versionDelete',
      method:'POST',
      auth :true
    },
    union:{
      url:'/v1/equipment/product/union',
      method:'GET',
      auth: true
    },
    versionUnion:{
      url:'/v1/equipment/product/versionUnion',
      method:'GET',
      auth: true
    },
    versionBindDevice:{
      url:'/v1/equipment/product/versionBindDevice',
      method:'POST',
      auth: true
    },
    versionFullUpdate:{
      url:'/v1/equipment/product/versionFullUpdate',
      method:'POST',
      auth: true
    },
    orgProducts:{
      url:'/v1/equipment/product/orgProducts',
      method:'GET',
      auth: true
    },
    drinkSteps:{
      url:'/v1/equipment/product/drinkSteps',
      method:'GET',
      auth: true
    },
    drinkStepFields:{
      url:'/v1/equipment/product/drinkStepFields',
      method:'GET',
      auth: true
    },
    deleteDrinkStep:{
      url:'/v1/equipment/product/deleteDrinkStep',
      method:'GET',
      auth: true
    },
    updateDrinkStep:{
      url:'/v1/equipment/product/updateDrinkStep',
      method:'POST',
      auth: true
    },
    bindDrinkStep:{
      url:'/v1/equipment/product/bindDrinkStep',
      method:'POST',
      auth: true
    },
    updateDrinkStepField:{
      url:'/v1/equipment/product/updateDrinkStepField',
      method:'POST',
      auth: true
    },
    deleteDrinkStepField:{
      url:'/v1/equipment/product/deleteDrinkStepField',
      method:'GET',
      auth: true
    },
    maintains:{
      url:'/v1/equipment/product/maintains',
      method:'GET',
      auth: true
    }

}