/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t = i18n.global.t
const device = {
  name: 'equipment.order.router',
  path: '/delivery/order',
  meta: {title: t('equipment.order.router')},
  component: () => import('@/pages/redirect'),
  redirect: {name: 'equipment.order'},
  children: [
    {
      name: 'equipment.order',
      path: 'orders',
      meta: {title: t('equipment.order.list'),keepAlive:true},
      component: () => import('@/pages/Delivery/Order/List'),
    },
  ]
}

export default device
