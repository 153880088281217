/**
 * Created by fworld on 2019/6/29.
 */
import library from './library'
import cupEdit from './cupEdit'
import group from './group'
import groupDrink from './groupDrink'
import i18n from '@/i18n'
import productGroup from './productGroup'

const t =i18n.global.t

const device = {
    name: 'library',
    path: '/delivery/library',
    meta: {title: t('equipment.drink.router')},
    component: () => import('@/pages/redirect'),
    redirect: {name: 'delivery.library'},
    children: [
        {
            name: 'equipment.drink.material',
            path: 'material',
            meta: {title: t('equipment.drink.material')},
            component: () => import('@/pages/Delivery/Device/DrinkLibrary/material'),
          },
    ].concat(library).concat(cupEdit).concat(group).concat(groupDrink).concat(productGroup)
}

export default device


