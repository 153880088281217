export default {
    list: {
        url: '/v1/equipment/device/list',
        method: 'GET',
        auth: true
    },
    cnt: {
        url: '/v1/equipment/device/cnt',
        method: 'GET',
        auth: true
    },
    drinks: {
        url: '/v1/equipment/device/drinks',
        method: 'GET',
        auth: true
    },
    drinkSync: {
        url: '/v1/equipment/device/drinkSync',
        method: 'POST',
        auth: true
    },
    cupModel :{
        url:'/v1/equipment/product/cupModel',
        methods: 'GET',
        auth: true
    },
    union:{
        url: '/v1/equipment/device/union',
        method:'GET',
        auth: true
    },
    drinkDelete:{
        url:'/v1/equipment/device/drinkDelete',
        method: 'POST',
        auth : true
    },
    createDrink:{
        url:'/v1/equipment/device/createDrink',
        method:'POST',
        auth: true
    },
    createDrinkCup:{
        url:'/v1/equipment/device/createDrinkCup',
        method:'POST',
        auth: true
    },
    update:{
        url:'/v1/equipment/device/update',
        method: 'POST',
        auth: true
    },
    notifyDevicePull:{
        url:'/v1/equipment/device/notifyDevicePull',
        method:'GET',
        auth: true
    },
    allocateDelivery:{
        url:'/v1/equipment/device/allocateDelivery',
        method:'POST',
        auth:true
    },
    detail:{
        url:'/v1/equipment/device/detail',
        method:'GET',
        auth:true
    },
    groupCnt:{
        url:'/v1/equipment/device/groupCnt',
        method:'GET',
        auth:true
    },
    groupList:{
        url:'/v1/equipment/device/groupList',
        method:'GET',
        auth:true
    },
    groupUnion:{
        url:'/v1/equipment/device/groupUnion',
        method:'GET',
        auth:true
    },
    autoPower:{
        url:'/v1/equipment/device/autoPower',
        method:'GET',
        auth:true
    },
    autoPowerCreate:{
        url:'/v1/equipment/device/autoPowerCreate',
        method:'POST',
        auth:true
    },
    autoPowerDelete:{
        url:'/v1/equipment/device/autoPowerDelete',
        method:'GET',
        auth:true
    },
    autoPowderUpdate:{
        url:'/v1/equipment/device/autoPowderUpdate',
        method:'POST',
        auth:true
    },
    medias:{
        url:'/v1/equipment/device/medias',
        method:'GET',
        auth:true    
    },
    mediaUpdate:{
        url:'/v1/equipment/device/mediaUpdate',
        method:'POST',
        auth:true 
    },
    runningList:{
        url:'/v1/equipment/device/runningList',
        method:'GET',
        auth:true 
    },
    runningCnt:{
        url:'/v1/equipment/device/runningCnt',
        method:'GET',
        auth:true 
    },
    runningUnion:{
        url:'/v1/equipment/device/runningUnion',
        method:'GET',
        auth:true 
    },
    cleans:{
        url:'/v1/equipment/device/cleans',
        method:'GET',
        auth:true 
    },
    cleanUpdate:{
        url:'/v1/equipment/device/cleanUpdate',
        method:'POST',
        auth:true 
    },

    machineSetting:{
        url:'/v1/equipment/device/machineSetting',
        method:'GET',
        auth:true
    },
    machineSettingUpdate:{
        url:'/v1/equipment/device/machineSettingUpdate',
        method:'POST',
        auth:true
    },
    machineMaintainSync:{
        url:'/v1/equipment/device/machineMaintainSync',
        method:'GET',
        auth:true
    },
    drinkSort:{
        url:'/v1/equipment/device/drinkSort',
        method:'GET',
        auth:true
    },
    powderCnfs:{
        url:'/v1/equipment/device/powderCnfs',
        method:'GET',
        auth:true
    },
    powderUpdate:{
        url:'/v1/equipment/device/powderUpdate',
        method:'POST',
        auth:true
    },
    drinkSortUpdate:{
        url:'/v1/equipment/device/drinkSortUpdate',
        method:'POST',
        auth:true
    },
    drinkList:{
        url:'/v1/equipment/device/drinkList',
        method:'GET',
        auth:true
    },
    drinkUnion:{
        url:'/v1/equipment/device/drinkUnion',
        method:'GET',
        auth:true
    },
    drinkCups:{
        url:'/v1/equipment/device/drinkCups',
        method:'GET',
        auth:true
    },
    autoPowerUnion:{
        url:'/v1/equipment/device/autoPowerUnion',
        method:'GET',
        auth:true
    },
    importDwDrink:{
        url:'/v1/equipment/device/importDwDrink',
        method:'POST',
        auth:true
    },
    deviceDrinkReport:{
        url:'/v1/equipment/device/deviceDrinkReport',
        method:'GET',
        auth:true
    },
    factoryUnion:{
        url:'/v1/equipment/device/factoryUnion',
        method:'GET',
        auth:true
    },
    factoryCnt:{
        url:'/v1/equipment/device/factoryCnt',
        method:'GET',
        auth:true
    },
    factoryList:{
        url:'/v1/equipment/device/factoryList',
        method:'GET',
        auth:true
    },
    deleteAllDrink:{
        url:'/v1/equipment/device/deleteAllDrink',
        method:'GET',
        auth:true
    },
    importSN:{
        url:'/v1/equipment/device/importSN',
        method:'POST',
        auth:true
    },
    downloadInfo:{
        url:'/v1/equipment/device/downloadInfo',
        method:'GET',
        auth:true
    },
    queryDeviceInfo:{
        url:'/v1/equipment/device/queryDeviceInfo',
        method:'GET',
        auth:true
    },
    importDevice:{
        url:'/v1/equipment/device/importDevice',
        method:'POST',
        auth:true
    },
    downloadList:{
        url:'/v1/equipment/device/downloadList',
        method:'POST',
        auth:true
    },
    queryRunInfo:{
        url:'/v1/equipment/device/queryRunInfo',
        method:'GET',
        auth:true,
    },
    opr:{
        url:'/v1/equipment/device/opr',
        method:'GET',
        auth:true,
    },
    changeImei:{
        url:'/v1/equipment/device/changeImei',
        method:'GET',
        auth:true,
    },
    countOnlineOffline:{
        url:'/v1/equipment/device/countOnlineOffline',
        method:'GET',
        auth:true,
    },
    drinkTopCount:{
        url:'/v1/equipment/order/drinkTopCount',
        method:'GET',
        auth:true,
    },
    countTop:{
        url:'/v1/equipment/device/countTop',
        method:'GET',
        auth:true,
    },
    beanCountMonthly:{
        url:'/v1/equipment/device/beanCountMonthly',
        method:'GET',
        auth:true,
    }
}
