/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t = i18n.global.t
const device = {
  name: 'equipment.card.router',
  path: '/delivery/card',
  meta: { title: t('equipment.card.router') },
  component: () => import('@/pages/redirect'),
  redirect: { name: 'equipment.card' },
  children: [
    {
      name: 'equipment.card',
      path: 'cards',
      meta: { title: t('equipment.card.list'), keepAlive: true },
      component: () => import('@/pages/Delivery/Card'),
    },
    {
      name: 'equipment.card.redirect',
      path: 'cards',
      meta: { title: t('equipment.card.list'), keepAlive: true },
      component: () => import('@/pages/redirect'),
      children: [
        {
          name: 'equipment.card.register',
          path: 'register',
          meta: { title: t('equipment.card.register') },
          component: () => import('@/pages/Delivery/Card/detail.vue'),
        }
      ]
    }
  ]
}

export default device
