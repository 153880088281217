export default {
    drinkUpdate:{
      url:'/v1/equipment/dw/drinkUpdate',
      method:'POST',
      auth: true
    },
    drinkList:{
      url:'/v1/equipment/dw/drinkList',
      method: 'GET',
      auth : true
    },
    drinkCnt:{
      url:'/v1/equipment/dw/drinkCnt',
      method: 'GET',
      auth: true
    },
    drinkCupUpdate:{
      url: '/v1/equipment/dw/drinkCupUpdate',
      method: 'POST',
      auth: true
    },
    updateDrink:{
      url :'/v1/equipment/dw/drinkUpdate',
      method: 'POST',
      auth : true
    },
    drinkCupModel:{
      url :'/v1/equipment/dw/drinkCupModel',
      method: 'GET',
      auth : true
    },
    drinkDelete:{
      url:'/v1/equipment/dw/drinkDelete',
      method: 'POST',
      auth : true
    },
    union:{
      url:'/v1/equipment/dw/union',
      method: 'GET',
      auth : true
    },
    drinkSortUpdate: {
        url:'/v1/equipment/dw/drinkSortUpdate',
        method: 'POST',
        auth : true
    },
    drinkSort: {
        url:'/v1/equipment/dw/drinkSort',
        method: 'GET',
        auth : true
    },
    drinkUnion:{
      url:'/v1/equipment/dw/drinkUnion',
      method: 'GET',
      auth : true
    },
    drinkCup:{
        url:'/v1/equipment/dw/drinkCup',
        method:'GET',
        auth:true
    },
    bindCustomer:{
      url:'/v1/equipment/dw/bindCustomer',
      method:'POST',
      auth:true
    },
    deleteAllDrink:{
      url:'/v1/equipment/dw/deleteAllDrink',
      method:'GET',
      auth:true
    },
    dwCreate:{
      url:'/v1/equipment/dw/create',
      method:'POST',
      auth:true
    },
    dwList:{
      url:'/v1/equipment/dw/list',
      method:'GET',
      auth:true
    },
    dwCnt:{
      url:'/v1/equipment/dw/cnt',
      method:'GET',
      auth:true
    },
    dwDeviceShow:{
      url:'/v1/equipment/dw/deviceShow',
      method:'GET',
      auth:true
    },
    dwDeviceBind:{
      url:'/v1/equipment/dw/deviceBind',
      method:'POST',
      auth:true
    },
    dwBindDevice:{
      url:'/v1/equipment/dw/bindDevice',
      method:'POST',
      auth:true
    },
    unbindDevice:{
      url:'/v1/equipment/dw/unbindDevice',
      method:'POST',
      auth:true
    },
    deviceList:{
      url:'/v1/equipment/dw/deviceList',
      method:'GET',
      auth:true
    },
    deviceCnt:{
      url:'/v1/equipment/dw/deviceCnt',
      method:'GET',
      auth:true
    },
    dwDeviceUnion:{
      url:'/v1/equipment/dw/deviceUnion',
      method:'GET',
      auth:true
    },
    dwUnion:{
      url:'/v1/equipment/dw/dwUnion',
      method:'GET',
      auth:true
    },
    dwUpdate:{
      url:'/v1/equipment/dw/update',
      method:'POST',
      auth:true
    },
    dwDelete:{
      url:'/v1/equipment/dw/delete',
      method:'GET',
      auth:true
    },
    dwProductList:{
      url:'/v1/equipment/dw/productList',
      method:'GET',
      auth:true
    },
    drinkDownload:{
      url:'/v1/equipment/dw/dwDrinkDownload',
      method:'GET',
      auth:true
    },
    dwVersionList:{
      url:'/v1/equipment/dw/versionList',
      method:'GET',
      auth:true
    },
    dwVersionUnion:{
      url:'/v1/equipment/dw/versionUnion',
      method:'GET',
      auth:true
    },
    dwVersionSaveCopy:{
      url:'/v1/equipment/dw/dwVersionSaveCopy',
      method:'GET',
      auth:true
    },
    dwVersionQueryCopy:{
      url:'/v1/equipment/dw/dwVersionQueryCopy',
      method:'GET',
      auth:true
    },
    syncDevice:{
      url:'/v1/equipment/dw/syncDevice',
      method:'POST',
      auth:true
    },
    unSyncDevice:{
      url:'/v1/equipment/dw/unSyncDevice',
      method:'POST',
      auth:true
    },
    drinkTemplateDownload:{
      url:'/v1/equipment/product/drinkTemplateDownload',
      method:'GET',
      auth:true
    },
    
  }
