export default {
  orgModules: {
    url: '/v1/org/role/orgModules',
    method: 'GET',
    auth: true
  },
  list:{
    url: '/v1/org/role/list',
    method: 'GET',
    auth: true
  },
  create:{
    url:'/v1/org/role/create',
    method:'POST',
    auth:true
  },
  delete:{
    url:'/v1/org/role/delete',
    method:'GET',
    auth:true
  },
  proves:{
    url:'/v1/org/role/proves',
    method:'GET',
    auth:true
  },
  roleInfo:{
    url:'/v1/org/role/roleInfo',
    method:'GET',
    auth:true    
  },
  update:{
    url:'/v1/org/role/update',
    method:'POST',
    auth: true
  },
  modules:{
    url:'/v1/org/role/modules',
    method:'GET',
    auth: true
  },
  proveBind:{
    url:'/v1/org/role/proveBind',
    method:'POST',
    auth :true
  },
  proveUnbind:{
    url:'/v1/org/role/proveUnbind',
    method:'POST',
    auth :true
  }
}
