import delivery from "./delivery/delivery";
import factory from "./factory";
import i18n from "@/i18n";

// 1.引入创建路由需要的组件
import {createRouter, createWebHistory,createWebHashHistory } from "vue-router";

const t=i18n.global.t
// 2.配置系统所有路由页面
const routes = [

    {
        path: "/login",
        name: "login",
        component: () => import('@/layout/login.vue'),
        meta: {
            title: t('menus.login')
        }
    },
    {
        path: "/dashboard",
        name: "dashboard.router",
        component: () => import('@/layout/dashboard.vue'),
        meta: {
            title: t('menus.login')
        }
    },
    {...delivery},
    {...factory},
];

// 3.创建路由实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});



router.beforeEach((to,from,next)=>{
    if(to.matched.length===0){
        router.push(to.path)
    }
    const token = localStorage.getItem('token'); // 假设token存储在localStorage中
    if (!token && to.name !== 'login') {
      // 没有token且不是前往登录页，则跳转到登录页
      next({ name: 'login' });
    } else {
      next();
    }
    
})

// 4.声明,为路由提供外部引用的入口
export default router;


