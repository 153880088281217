// import Cookies from 'js-cookie'

const TokenKey = 'token'
const ProveNameKey ='proveName'
const CurrentOrgName ='currentOrgName'
const ReplaceOrgName ='replaceOrgName'
const FactoryOrg='factoryOrg'

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getProveNameKey() {
  return localStorage.getItem(ProveNameKey);
}

export function setProveNameKey(proveName) {
  return localStorage.setItem(ProveNameKey, proveName)
}

export function removeProveNameKey() {
  return localStorage.removeItem(ProveNameKey)
}

export function getCurrentOrgNameKey() {
  return localStorage.getItem(CurrentOrgName);
}

export function setCurrentOrgNameKey(currentOrgName) {
  return localStorage.setItem(CurrentOrgName, currentOrgName)
}

export function removeCurrentOrgNameKey() {
  return localStorage.removeItem(CurrentOrgName)
}

export function getReplaceOrgNameKey() {
  return localStorage.getItem(ReplaceOrgName);
}

export function setReplaceOrgNameKey(replaceOrgName) {
  return localStorage.setItem(ReplaceOrgName, replaceOrgName)
}

export function removeReplaceOrgNameKey() {
  return localStorage.removeItem(ReplaceOrgName)
}


export function setFactoryOrgKey(factoryOrg) {
  return localStorage.setItem(FactoryOrg, factoryOrg)
}

export function getFactoryOrKey() {
  return localStorage.getItem(FactoryOrg);
}

export function removeFactoryOrgNameKey() {
  return localStorage.removeItem(FactoryOrg)
}

