import {createI18n} from 'vue-i18n';
import zh from "./langs/zh" ;
import en from "./langs/en" ;
import elementEnLocale from 'element-plus/lib/locale/lang/en';
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn';


const messages = {
    en: {
        ...en,
        ...elementEnLocale,
    },
    zh: {
        ...zh,
        ...elementZhLocale,
    },
};

const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'zh',
    messages: messages,
});

export default i18n;