/**
 * Created by fworld on 2019/9/11.
 */
import i18n from '@/i18n/'
import types from '../mutationTypes';
import type from '../mutationTypes';

export default {
  state: {
    navMenus: [],
    navMap: [],
    permissionMap:[],
    employee: {},
    org:{},
    connect: false,
    proveName:'',
    currentOrgName:'',
    replaceOrgName:'',
  },
  mutations: {
    [types.SET_ENTERPRISE_NAV_MENU](state, menu) {
      state.navMenus = menu.navMenus
      state.navMap = menu.map
    },
    [types.SET_ENTERPRISE_EMPLOYEE](state, data) {
      state.employee = data.employee
      state.org = data.org
    },
    [types.SET_NOTIFY_CONNECT](state, connect) {
      state.connect = connect
    },
    [types.SET_PERMISSION_MAP](state,connect){
      state.permissionMap=connect
    },
    [types.SET_PROVE_NAME](state,proveName){
      state.proveName =proveName
    },
    [types.SET_CURRENT_ORG_NAME](state,currentOrgName){
      state.currentOrgName =currentOrgName
    },
    [types.SET_REPLACE_ORG_NAME](state,replaceOrgName){
      state.replaceOrgName =replaceOrgName
    },
  },
  actions: {
    setConnect({commit}, ok) {
      commit(types.SET_NOTIFY_CONNECT, ok)
    },
    setEnterpriseMenu({commit}, menu) {
      let map = {}
      let moduleSorts = menu
      moduleSorts.forEach((ms) => {
        map[ms.uuid] = 't_' + ms.uuid
        let _map = {}
        ms.engName=ms.engName+'.router'
        ms.modules = ms.modules || []
        ms.modules.forEach((m) => {
          _map[m.uuid] = 't_' + m.uuid
        })
        ms.moduleMap = _map
      })
      commit(types.SET_ENTERPRISE_NAV_MENU, {map: map, navMenus: moduleSorts})
    },
    setPermissionMap({commit},permissions){
      commit(types.SET_PERMISSION_MAP, permissions)
    },

    setEnterpriseEmployee({commit}, employee) {
      commit(types.SET_ENTERPRISE_EMPLOYEE, employee)
    },
    setProveName({commit}, proveName){
      commit(types.SET_PROVE_NAME,proveName)
    },
    setCurrentOrgName({commit},currentOrgName){
      commit(types.SET_CURRENT_ORG_NAME,currentOrgName)
    },
    setReplaceOrgName({commit},replaceOrgName){
      commit(types.SET_REPLACE_ORG_NAME,replaceOrgName)
    },
    logout({commit}) {
      commit(types.SET_ENTERPRISE_EMPLOYEE, {})
    },
  }
}
