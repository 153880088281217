export default {
    list: {
      url: '/v1/equipment/clean/list',
      method: 'GET',
      auth: true
    },
    synDevice:{
      url: '/v1/equipment/clean/synDevice',
      method: 'POST',
      auth: true
    },
    create:{
      url: '/v1/equipment/clean/create',
      method: 'POST',
      auth: true
    },
    delete:{
      url: '/v1/equipment/clean/delete',
      method: 'POST',
      auth: true
    },
    cnt:{
      url: '/v1/equipment/clean/cnt',
      method: 'POST',
      auth: true
    },
    update:{
      url: '/v1/equipment/clean/update',
      method: 'POST',
      auth: true
    },
    tmpl:{
      url: '/v1/equipment/clean/tmpl',
      method: 'GET',
      auth: true
    }
}