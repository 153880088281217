/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t= i18n.global.t
const payset = [
    {
        name:'delivery.library.group.redirect',
        path: 'group',
        meta: {title: t('equipment.drink.group')},
        component: () => import('@/pages/redirect'),
        children:[
            {
                name: 'delivery.library.group.drink',
                path: 'drink',
                meta: {title: t('equipment.customer.drinkSetting')},
                component: () => import('@/pages/equipment/drink/drink.vue'),
            },
            {
                name: 'delivery.library.group.add',
                path: 'add',
                meta: {title: t('group.addCustomer')},
                component: () => import('@/pages/equipment/drink/addGroup.vue'),
            },
            {
                name: 'delivery.library.group.history',
                path: 'history',
                meta: {title: t('system.historyVersion')},
                component: () => import('@/pages/equipment/drink/history.vue'),
            },
        
        ]
    }

]

export default payset
