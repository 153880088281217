export default {
  navMenus: state => state.account.navMenus, // 导航菜单
  navMap: state => state.account.navMap,
  employee: state => state.account.employee, // 导航菜单
  permissionMap: state=> state.account.permissionMap,//权限菜单
  org: state => state.account.org,
  notifyConnect: state => state.account.connect, // 导航菜单
  factoryProductNames: state => state.factory.productNames, // 导航菜单
  siderType: state => state.app.siderType,
  customer: state=>state.customer,
  proveName: state=>state.proveName,
  currentOrgName: state=> state.currentOrgName,
  replaceOrgName: state=> state.replaceOrgName,
}
