export default {
  managerResetPassword: {
    url: '/v1/account/prove/managerResetPassword',
    method: 'POST',
    auth: true
  },
  modifyPassword: {
    url: '/v1/account/prove/modifyPassword',
    method: 'POST',
    auth: false
  },
  getCode: {
    url: '/v1/account/prove/getCode',
    method: 'POST',
    auth: false
  },
  orgs: {
    url: '/v1/account/prove/orgs',
    method: 'GET',
    auth: false
  },
  login: {
    url: '/v1/account/prove/login',
    method: 'POST',
    auth: false
  },
  sendSms: {
    url: '/v1/account/prove/sendSms',
    method: 'GET',
    auth: false
  },
  smsGetCode: {
    url: '/v1/account/prove/smsGetCode',
    method: 'POST',
    auth: false
  },
  changeEnterprise:{
    url: '/v1/account/prove/changeEnterprise',
    method: 'GET',
    auth: true
  }
}
