/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t=i18n.global.t
export default {
    
    name: 'factory.delivery.list.redrict',
    path: 'list',
    meta: {title: t('org.manager.list')},
    component: () => import('@/pages/redirect'),
    children: [
        {
            name: 'factory.delivery.list.paySet',
            path: 'detail/:uuid/:name',
            meta: {title: t('org.manager.paySetting')},
            component: () => import('@/pages/Factory/Delivery/paySet.vue'),
        },
    ]
}
