export default {
    list: {
      url: '/v1/equipment/customer/list',
      method: 'GET',
      auth: true
    },
    union: {
        url: '/v1/equipment/customer/union',
        method: 'GET',
        auth: true
    },
    create: {
        url: '/v1/equipment/customer/create',
        method: 'POST',
        auth: true
    },
    delete: {
        url: '/v1/equipment/customer/delete',
        method: 'POST',
        auth: true
    },
    cnt: {
        url: '/v1/equipment/customer/cnt',
        method: 'GET',
        auth: true
    },
    update: {
        url: '/v1/equipment/customer/update',
        method: 'POST',
        auth: true
    },
    bindDevice: {
        url: '/v1/equipment/customer/bindDevice',
        method: 'POST',
        auth: true
    },
    unbindDevice: {
        url: '/v1/equipment/customer/unbindDevice',
        method: 'POST',
        auth: true
    },
    alipay:{
        url:'/v1/equipment/customer/aliPay',
        method:'GET',
        auth: true,
    },
    aliAuthQr:{
        url:'/v1/equipment/customer/aliAuthQr',
        method:'GET',
        auth: true
    },
    wxPayDetail:{
        url:'/v1/equipment/customer/wxPayDetail',
        method:'GET',
        auth: true
    },
    wxPayUpdate:{
        url:'/v1/equipment/customer/wxPayUpdate',
        method:'POST',
        auth : true
    },
    aliTestPay:{
        url:'/v1/equipment/customer/aliTestPay',
        method:'GET',
        auth: true
    },
    productMaterial:{
        url:'/v1/equipment/customer/productMaterial',
        method:'GET',
        auth: true
    },
    material:{
        url:'/v1/equipment/customer/material',
        method:'GET',
        auth: true
    },
    materialDelete:{
        url:'/v1/equipment/customer/materialDelete',
        method:'POST',
        auth: true
    },
    materialCreate:{
        url:'/v1/equipment/customer/materialCreate',
        method:'POST',
        auth: true
    },
    materialUpdate:{
        url:'/v1/equipment/customer/materialUpdate',
        method:'POST',
        auth: true
    },
    productMaterialUpdate:{
        url:'/v1/equipment/customer/productMaterialUpdate',
        method:'POST',
        auth: true
    },
    productMaterialEmpty:{
        url:'/v1/equipment/customer/productMaterialEmpty',
        method:'POST',
        auth: true
    },
    wxSupport:{
        url:'/v1/equipment/customer/wxSupport',
        method:'GET',
        auth: true
    },
    wxQueryStatus:{
        url:'/v1/equipment/customer/wxQueryStatus',
        method:'GET',
        auth: true
    },
    wxResourceCommit:{
        url:'/v1/equipment/customer/wxResourceCommit',
        method:'POST',
        auth: true
    },
    wxGetResource:{
        url:'/v1/equipment/customer/wxGetResource',
        method:'GET',
        auth: true
    },
    queryBank:{
        url:'/v1/equipment/customer/queryBank',
        method:'GET',
        auth: true
    },
    bindProve:{
        url:'/v1/equipment/customer/bindProve',
        method:'POST',
        auth:true
    },
    deleteAliPay:{
        url:'/v1/equipment/customer/deleteAliPay',
        method:'GET',
        auth:true
    },
    checkV2Pay:{
        url:'/v1/equipment/customer/checkV2Pay',
        method:'POST',
        auth:true
    },
    updateBalance:{
        url:'/v1/equipment/customer/update/balance',
        method:'POST',
        auth:true,

    },
    message:{
        url:'/v1/equipment/customer/message',
        method:'GET',
        auth:true,
    },
    messageUnion:{
        url:'/v1/equipment/customer/message/union',
        method:'GET',
        auth:true,
    },
    balanceLog:{
        url:'/v1/equipment/customer/balanceLog',
        method:'GET',
        auth:true,
    },
    balanceLogUnion:{
        url:'/v1/equipment/customer/balanceLog/union',
        method:'GET',
        auth:true,
    },
    messageStat:{
        url: '/v1/equipment/customer/message/stat',
        method:'GET',
        auth:true, 
    }
  }
  