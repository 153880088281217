/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t= i18n.global.t
export default {
    name: 'org.manager.machine',
    path: 'machine',
    meta: {title: t('org.manager.machine'),keepAlive:true},
    component: () => import('@/pages/Factory/Delivery/machine'),

}