import Request from './request'
import apiList from './yunkapro.js'

let baseUri = ''

export function resetBaseUri(uri) {
  baseUri = uri
}

export  function api(url, params = {}, data = {}, showToast = true) {
  const request = new Request()
  const api = getApiObj(url)

  request.interceptor.request((config, cancel) => { /* 请求之前拦截器 */
    if (api.auth) {
      const token = localStorage.getItem('token')
      if (!token) {
        cancel()
        // eslint-disable-next-line no-throw-literal
        throw ('暂未登录,已阻止此次API请求~')
      }

      config.params.token = token
    }

    const proveName = localStorage.getItem('proveName')
    const currentOrgName = localStorage.getItem('currentOrgName')
    const replaceOrgName = localStorage.getItem('replaceOrgName')
    const language =localStorage.getItem('locale')

    if (currentOrgName != '') {
      config.params.currentOrgName = currentOrgName
    }
    if (replaceOrgName != '') {
      config.params.replaceOrgName = replaceOrgName
    }
    if (proveName != '') {
      config.params.proveName = proveName
    }
    if(language != ''){
      config.params.language = proveName
    }

    if (baseUri != '') {
      config.baseUrl = baseUri
    }



    return config
  })

  request.interceptor.response((response) => { /* 请求之后拦截器 */
    if (response.data.code !== '0') {
      if (response.data.code === '15') {
       localStorage.removeItem('token') 
       window.location.href='/'
       return
      }
      if (showToast) {
        window.$message.error(response.data.msg)
        return Promise.reject(response.data.msg)
      }
    }

  

    if (response.status === 404) { // 服务端返回的状态码不等于200，则reject()
      window.$message.error('api 不存在')
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('无授权')
    }

    if (response.status === 401) { // 服务端返回的状态码不等于200，则reject()
      localStorage.removeItem('token')
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('无授权')
    }
    if (response.status === 500) { // 服务端返回的状态码不等于200，则reject()
      window.$message.error('网络异常')
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('网络异常')
    }
    return response.data.data
  }, (response) => { // 预留可以日志上报
    console.log(response)
    window.$message.error('网络异常')
    return response
  })

  return request.request({
    url: api.url,
    params: params,
    data: data,
    method: api.method
  })
}

function getApiObj(url) {
  const apiArray = url.split('.')
  let api = apiList
  apiArray.forEach(v => {
    api = api[v]
  })
  return api
}


