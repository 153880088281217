import { API_URL } from './env'

import axios from 'axios'

export default class Request {
  config = {
    baseUrl: API_URL,
    header: {
      'content-type': 'application/json',
      platform: 'web'
    },
    method: 'GET',
    dataType: 'json',
    custom: {},
    timeout: 10000
  }

  static posUrl (url) {
    /* 判断url是否为绝对路径 */
    return /(http|https):\/\/([\w.]+\/?)\S*/.test(url)
  }

  static addQueryString (params) {
    let paramsData = ''
    Object.keys(params).forEach(function (key) {
      paramsData += key + '=' + encodeURIComponent(params[key]) + '&'
    })
    return paramsData.substring(0, paramsData.length - 1)
  }

  /**
   * @property {Function} request 请求拦截器
   * @property {Function} response 响应拦截器
   * @type {{request: Request.interceptor.request, response: Request.interceptor.response}}
   */
  interceptor = {
    /**
     * @param {Request~requestCallback} cb - 请求之前拦截,接收一个函数（config, cancel）=> {return config}。
     *  第一个参数为全局config,第二个参数为函数，调用则取消本次请求。
     */
    request: (cb) => {
      if (cb) {
        this.requestBeforeFun = cb
      }
    },
    /**
     * @param {Request~responseCallback} cb 响应拦截器，对响应数据做点什么
     * @param {Request~responseErrCallback} ecb 响应拦截器，对响应错误做点什么
     */
    response: (cb, ecb) => {
      if (cb && ecb) {
        this.requestComFun = cb
        this.requestComFail = ecb
      }
    }
  }

  requestBeforeFun (config) {
    return config
  }

  requestComFun (response) {
    return response
  }

  requestComFail (response) {
    return response
  }

  /**
   * 自定义验证器，如果返回true 则进入响应拦截器的响应成功函数(resolve)，否则进入响应拦截器的响应错误函数(reject)
   * @param { Number } statusCode - 请求响应体statusCode（只读）
   * @return { Boolean } 如果为true,则 resolve, 否则 reject
   */
  validateStatus (statusCode) {
    return statusCode === 200
  }

  /**
   * @Function
   * @param {Request~setConfigCallback} f - 设置全局默认配置
   */
  setConfig (f) {
    this.config = f(this.config)
  }

  /**
   * @Function
   * @param {Object} options - 请求配置项
   * @prop {String} options.url - 请求路径
   * @prop {Object} options.data - 请求参数
   * @prop {Object} [options.responseType = config.responseType] [text|arraybuffer] - 响应的数据类型
   * @prop {Object} [options.dataType = config.dataType] - 如果设为 json，会尝试对返回的数据做一次 JSON.parse
   * @prop {Object} [options.header = config.header] - 请求header
   * @prop {Object} [options.method = config.method] - 请求方法
   * @returns {Promise<AxiosInstance>}
   */
  async request (options = {}) {
    options.baseUrl = this.config.baseUrl
    options.dataType = options.dataType || this.config.dataType
    options.timeout = options.timeout || this.config.timeout
    options.url = options.url || ''
    options.data = options.data || {}
    options.params = options.params || {}
    options.header = options.header || this.config.header
    options.method = options.method || this.config.method

    try {
      this.requestBeforeFun(options, function () {
      })
    } catch (e) {
      return Promise.reject(e)
    }

    const _config = {
      ...options
    }

    _config.url = Request.posUrl(_config.url) ? _config.url : (_config.baseUrl + _config.url)

    const service = axios.create()

    service.interceptors.response.use((response) => {
      if (response.status === 200) { // 成功
        return this.requestComFun(response)
      } else if (response.status === 401) {
        return Promise.reject(response)
      } else if (response.status === 500) {
        this.requestComFail(response)
        return Promise.reject(response)
      } else {
        return this.requestComFail(response)
      }
    })
    const srv = service(_config)
    srv.catch((err) => {
      console.error('网络异常', err)
    })
    return srv
  }

  get (url, options = {}) {
    return this.request({
      url,
      method: 'GET',
      ...options
    })
  }

  post (url, data, options = {}) {
    return this.request({
      url,
      data,
      method: 'POST',
      ...options
    })
  }
}
