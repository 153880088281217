/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t =i18n.global.t

export default {
    name: 'equipment.drink.group',
    path: 'group',
    meta: {title: t('equipment.drink.group')},
    component: () => import('@/pages/equipment/drink/group.vue'),

}
