/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t = i18n.global.t
export default {
    name: 'equipment.machine.router',
    path: 'model',
    meta: { title: t('equipment.machine.products') },
    component: () => import('@/pages/redirect'),
    children: [
        {
            name: 'equipment.machine.fieldRouter',
            path: 'model',
            meta: { title: t('equipment.machine.model') },
            component: () => import('@/pages/redirect'),
            children:[
                {
                    name: 'equipment.machine.model.field',
                    path: 'field',
                    meta: { title: t('equipment.machine.field') },
                    component: () => import('@/pages/Factory/Product/DrinkStep/field.vue')
                }
            ]
        }
    ]
}
