export default {
  union: {
    url: '/v1/org/user/union',
    method: 'GET',
    auth: true
  },
  managerUnion: {
    url: '/v1/org/user/managerUnion',
    method: 'GET',
    auth: true
  },
  list: {
    url: '/v1/org/user/list',
    method: 'GET',
    auth: true
  },
  departmentRoleUnion: {
    url: '/v1/org/user/departmentRoleUnion',
    method: 'GET',
    auth: true
  },
  create: {
    url: '/v1/org/user/create',
    method: 'POST',
    auth: true
  },
  update: {
    url: '/v1/org/user/update',
    method: 'POST',
    auth: true
  },
  recycleList:{
    url:'/v1/org/user/recycleList',
    method:'GET',
    auth: true,
  },
  recycleUnion:{
    url:'/v1/org/user/recycleUnion',
    method:'GET',
    auth:true
  },
  recycleCnt:{
    url:'/v1/org/user/cnt',
    method:"GET",
    auth: true
  },
  recover:{
    url:'/v1/org/user/recover',
    method:'POST',
    auth:true
  },
  delete:{
    url:'/v1/org/user/delete',
    method:'POST',
    auth:true
  }
}
