
import delivery from "./delivery/index";
import product from "./product/index";
export default {
    path: '/factory',
    name: 'factory',
    component: () => import('@/layout/main'),
    redirect: {name: 'factory.index'},
    children: [
        {...delivery},
        {...product},
    ]
}
