export default {
    cardCreate:{
      url:'/v1/equipment/card/create',
      method:'POST',
      auth:true
    },
    cardList:{
      url:'/v1/equipment/card/list',
      method:'GET',
      auth:true
    },
    cardUpdate:{
      url:'/v1/equipment/card/update',
      method:'POST',
      auth:true
    },
    cardDelete:{
      url:'/v1/equipment/card/delete',
      method:'GET',
      auth:true
    },
    union:{
      url:'/v1/equipment/card/union',
      method:'GET',
      auth:true
    },
    historyList:{
      url:'/v1/equipment/card/historyList',
      method:'GET',
      auth:true
    },
    historyUnion:{
      url:'/v1/equipment/card/historyUnion',
      method:'GET',
      auth:true
    },

  }
