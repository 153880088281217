<template>
  <el-config-provider :locale="locale">

    <router-view />
  </el-config-provider>
</template>

<script>
import { mapActions } from 'vuex'
import { getToken, setToken } from '@/util/auth'

  import { ElConfigProvider } from 'element-plus'
  import zhCn from 'element-plus/lib/locale/lang/zh-cn'
  import en from 'element-plus/lib/locale/lang/en';



export default {
  name: 'App',
  components: {
    ElConfigProvider
  },
  data() {
    return {
      module: [
      ],
      locale: localStorage.getItem('locale')==='en'?en:zhCn,
    }
  },
  setup() {
    return { getToken }
  },
  created() {
    this.$app.config.globalProperties.$tr = this.$t
    this.loadMenu()
    window.$message = this.$message
    const token = getToken();

    console.log(token)
    if (token) {
      this.loadUserData(token)
    } else {
      this.$router.replace({ name: 'login' })
    }
  },
  methods: {
    ...mapActions(['setEnterpriseMenu', 'setEnterpriseEmployee', 'setPermissionMap']),
    loadMenu() {
      this.setEnterpriseMenu(this.module)
    },
    loadUserData(token) {
      this.$api('org.user.union', { token: token }).then(data => {
        setToken(data.user.refreshToken)
        this.setEnterpriseEmployee({ employee: data.user, org: data.org })
        var modules =data.modules.module.filter(item=>{
          if(!(item.engName == 'equipment.work' || item.engName == 'accredit.info')){
            return item
          }
  
        })
        this.setEnterpriseMenu(modules)
        this.setPermissionMap(data.modules.button)

      })
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;

}

html,
body {
  height: 100%;
  margin: 0px;
}
</style>
