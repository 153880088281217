
import order from "./order";
import library from './library/index'
import system from './system'
import device from './device/index'
import equipment from './equipment/index'
import organization from './organization/index'
import card from './card/card'

export default {
    path: '/',
    name: 'home',
    component: () => import('@/layout/main'),
    children: [
        {...equipment},
        {...library},
        {...order},
        {...system},
        {...device},
        {...organization},
        {...card}
    ]
}