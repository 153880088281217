/**
 * Created by fworld on 2019/6/29.
 */
import devices from './device'
import detail from './detail'
import i18n from '@/i18n'
const t= i18n.global.t

const device = {
    name: 'equipment.device',
    path: '/delivery/device',
    meta: {title: t('equipment.device.router')},
    component: () => import('@/pages/redirect'),
    redirect: {name: 'equipment.device.list'},
    children: [
        {
            name: 'equipment.device.task',
            path: 'automatic',
            meta: {title: t('equipment.device.task')},
            component: () => import('@/pages/Delivery/Device/Devices/automatic'),
        },
        {
            name: 'equipment.device.error',
            path: 'exception',
            meta: {title: t('equipment.device.error')},
            component: () => import('@/pages/Delivery/Device/Devices/exception/index.vue'),
        },
        {
            name: 'equipment.device.maintain',
            path: 'maintain',
            meta: {title: t('equipment.device.maintain')},
            component: () => import('@/pages/Delivery/Device/Devices/maintain/index.vue'),
        },
        {
            name: 'equipment.device.file',
            path: 'material',
            meta: {title: t('equipment.device.file')},
            component: () => import('@/pages/Delivery/Device/Devices/material/index.vue'),
        },
        {
            name: 'equipment.device.daily',
            path: 'clean',
            meta: {title: t('equipment.device.daily')},
            component: () => import('@/pages/Delivery/Device/Devices/clean/index.vue'),
        },
        {
            name: 'equipment.device.list',
            path: 'devices',
            meta: {title: t('equipment.device.list'),keepAlive:true},
            component: () => import('@/pages/Delivery/Device/Devices'),
        },
        {
            name:'equipment.device.org',
            path:'org',
            meta:{title:t('equipment.device.org')},
            component:()=>import('@/pages/Factory/system/setting.vue')
    
        },
        {
            name:'equipment.device.flex',
            path:'flex',
            meta:{title:t('equipment.device.flex')},
            component:()=>import('@/pages/Delivery/Device/Devices/flex.vue')
    
        },
        {
            name:'equipment.device.department',
            path:'department',
            meta:{title:t('equipment.device.department')},
            component:()=>import('@/pages/Delivery/Device/Devices/department.vue')
    
        },
        
    ].concat(detail)
}

export default device
