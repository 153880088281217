export default {
  successTip: 'save successful',
  warningTip: 'warning',
  sureBtn: 'confirm',
  cancelBtn: 'cancel',
  editBtn: 'edit',
  delete: 'delete',
  opr: 'operation',
  que_ding: 'confirm',
  qu_xiao: 'cancel',
  ti_shi: 'prompt',
  fan_hui: 'return',
  cao_zuo: 'action',
  shi_fou_ji_xu_shan_chu: 'do you want to continue deleting?',
  welcome_to_you: 'welcome to you',
  company_number: 'company number',
  safe_quit: 'safe logout',
  shan_chu_ti_shi: 'deletion prompt',
  forget_password_tip: 'please contact the administrator to reset your password',
  refresh: 'refresh',
  refreshSuccess: 'refresh successful!',
  empty_info: 'No corresponding information!',
  errorHandleWay: 'Exception resolution list',
  yes: 'yes',
  no: 'no',
  deviceErrorList: 'Device exception message',
  runStatusLabel: 'Run Status:',
  continueTip: 'Confirmation prompt',
  drinkPicUploadTip: 'Drink images can only be uploaded in png format!',



  dashboard: {
    coffeeConsumeToday: 'Coffee consumption today',
    dataOperate: 'Data operation',
    coffeeConsumePercent: 'Coffee consumption percentage of consumption',
    deviceRunStatus: 'Equipment operating status',
    eachFloorConsumeCups: 'Number of cups of coffee consumed per floor',
    blackMilkCoffeePercent: 'Black coffee milk coffee accounted for the number of cups',
    drinkTop: 'Drinks List',
    machineCupTop: 'Coffee machine out cup size list',
    onlineDevice: 'online device',
    offlineDevice: 'offline device',
    saveSetting: 'save setting'

  },

  menus: {
    login: 'login'
  },
  language: {
    China: 'chinese',
    English: 'english',
  },
  message: {
    Home: 'home',
    SystemManage: 'system management',
    RouterManage: 'router management'
  },
  upload: {
    fileAlreadyUpload: 'file already uploaded',
    fileTip: 'Drag and drop file here or',
    clickUpload: 'click to upload'
  },
  login: {
    chooseLanguage: 'Language',
    language: 'chinese',
    login_entry: 'system login',
    deng_lu: 'login',
    huan_ying_shi_yong: 'welcome to use',
    wang_ji_mi_ma: 'forget password',
    TB_tip: 'technology empowers business development',
    qing_shu_ru_shou_ji_hao_ma: 'please enter mobile number',
    qing_shu_ru_deng_lu_mi_ma: 'please enter login password',
    shou_ji_hao_ma: 'mobile number',
    mi_ma: 'password',
    ji_zhu_mi_ma: 'remember password',
    passwordLogin: 'password login',
    checkLogin: 'verification code login',
  },
  customer: {
    addCustomer: 'add customer',
    pleaseEnterName: 'please enter customer name',
    xu_hao: 'index',
    bian_hao: 'number',
    ming_cheng: 'name',
    ke_hu_ming_cheng: 'customer name',
    qing_shu_ru_ke_hu_ming_cheng: 'please enter customer name',
    bian_ji_ke_hu: 'edit customer',
    geng_xin_ke_hu_xin_xi_cheng_gong: 'update customer information successfully!',
    xin_zeng_ke_hu: 'add customer',
    xin_zeng_ke_hu_cheng_gong: 'add customer successfully!',
    yin_pin_pei_zhi: 'beverage configuration',
    customerSwtich: 'customer Swtich',
    customerBalance: 'customer Balance',
    customerSwtichLabel: 'customer Swtich：',
    customerBalanceLabel: 'customer Balance：',
    distributeBalance: 'Distribute Balance',
    distributeBalanceTip: 'Before allocating the balance, please turn on the group switch',



  },
  group: {
    addCustomer: 'add group',
    pleaseEnterName: 'please enter group name',
    xu_hao: 'index',
    bian_hao: 'number',
    ming_cheng: 'name',
    ke_hu_ming_cheng: 'group name',
    qing_shu_ru_ke_hu_ming_cheng: 'please enter group name',
    bian_ji_ke_hu: 'edit group',
    geng_xin_ke_hu_xin_xi_cheng_gong: 'update group information successfully!',
    xin_zeng_ke_hu: 'add group',
    xin_zeng_ke_hu_cheng_gong: 'add group successfully!',
    yin_pin_pei_zhi: 'beverage configuration',
    customerSwtich: 'Group Swtich',
    customerBalance: 'Group Balance',
    customerSwtichLabel: 'Group Swtich：',
    customerBalanceLabel: 'Group Balance：',
    distributeBalance: 'Distribute Balance',
    distributeBalanceTip: 'Before allocating the balance, please turn on the group switch',
    updatedAt: 'Last updated time',
    addStepOne: '1：basic info',
    addStepTwo: '2.drink init',
    addStepThree: '3：sync device',
    chooseProductType: 'product：',
    enterGroupName: 'group name：',
    importDrinks: 'import dirnks：',
    drinkSetting: 'drink setting：',
    drinks: 'drinks',
    powder: 'powder',
    powderSetting: 'powder setting:',
    powderType: 'powder type:',
    importCustomerDevice: 'import customer device',
    selectDevice: 'select device',
    isSelectedDevceis: 'is selected devices',
    drinkImage: 'drink image',
    version: 'version',
    isDefault: 'is default',
    isVisible: 'is visible',
    updateAt: 'update at',
    createAt: 'create at ',
    defaultOne: 'default',
    defaultTwo: 'undefault',
    isVisibleOne: 'visible',
    isVisibleTwo: 'unvisible',
    saveCopy: 'save copy',
    saveCopySuccess: 'save copy success!',
    downloadParam: 'download param',



  },
  department: {
    addCustomer: 'add group',
    pleaseEnterName: 'please enter group name',
    xu_hao: 'index',
    bian_hao: 'number',
    ming_cheng: 'name',
    ke_hu_ming_cheng: 'group name',
    qing_shu_ru_ke_hu_ming_cheng: 'please enter group name',
    bian_ji_ke_hu: 'edit group',
    geng_xin_ke_hu_xin_xi_cheng_gong: 'update group information successfully!',
    xin_zeng_ke_hu: 'add group',
    xin_zeng_ke_hu_cheng_gong: 'add group successfully!',
    yin_pin_pei_zhi: 'beverage configuration',
    customerSwtich: 'Group Swtich',
    customerBalance: 'Group Balance',
    customerSwtichLabel: 'Group Swtich：',
    customerBalanceLabel: 'Group Balance：',
    distributeBalance: 'Distribute Balance',
    distributeBalanceTip: 'Before allocating the balance, please turn on the group switch',
    updatedAt: 'Last updated time',
    addStepOne: '1：basic info',
    addStepTwo: '2.drink init',
    addStepThree: '3：sync device',
    chooseProductType: 'product：',
    enterGroupName: 'group name：',
    importDrinks: 'import dirnks：',
    drinkSetting: 'drink setting：',
    drinks: 'drinks',
    powder: 'powder',
    powderSetting: 'powder setting:',
    powderType: 'powder type:',
    importCustomerDevice: 'import customer device',
    selectDevice: 'select device',
    isSelectedDevceis: 'is selected devices',
    drinkImage: 'drink image',
    version: 'version',
    isDefault: 'is default',
    isVisible: 'is visible',
    updateAt: 'update at',
    createAt: 'create at ',
    defaultOne: 'default',
    defaultTwo: 'undefault',
    isVisibleOne: 'visible',
    isVisibleTwo: 'unvisible',
    saveCopy: 'save copy',
    saveCopySuccess: 'save copy success!',
    downloadParam: 'download param',



  },
  audit: {
    operateName: 'operate name',
    operateTime: 'operate time',
    operateResult: 'operate result',
    operateType: 'opreate type',
    operateModule: 'operate module',
    success: 'success',
    fail: 'fail',
    pleaseEnterOperateName: 'please enter operate name',
    pleaseSelectOperateType: 'please select operate type',
    pleaseSelectOperateResult: 'please select operate result',
    bind: 'bind',
    unbind: 'unbind',
    create: 'create',
    update: 'update',
    delete: 'delete',
    open: 'open',
    clear: 'clear',
    fullUpdate: 'fullUpdate',
    recoverDefault: 'recoverDefault',
    sync: 'sync',
    callback: 'callback',
    refund: 'refund',
    pay: 'pay',
    import: 'import',
    save: 'save',
    im: 'im',
    register: 'register',
    replaceOperate: 'replaceOperate',
    login: 'login',
    notify: 'notify',
    audit: 'audit',
    query: 'query',
  },
  delivery: {
    xin_zeng_jing_xiao_shang: 'add distributor',
    qing_shu_ru_jing_xiao_shang_ming_cheng: 'please enter distributor name',
    jing_xiao_shang_bian_hao: 'distributor number',
    jing_xiao_shang_ming_cheng: 'distributor name',
    zui_da_ren_shu: 'maximum number of people',
    zhang_hu_yu_e_yuan: 'account balance (yuan)',
    zhu_ce_shi_jian: 'registration time',
    cheng_yuan_hui_shou_zhan: 'member recycle bin',
    jing_xiao_shang_ming_cheng_label: 'distributor name:',
    deng_lu_shou_ji_hao: 'login mobile number',
    deng_lu_mi_ma: 'login password',
    qing_shu_ru_deng_lu_shou_ji_hao: 'please enter login mobile number',
    qing_shu_ru_deng_lu_mi_ma: 'please enter login password',
    dai_wei_yun_ying: 'operate on behalf',
    qi_ye_ming_cheng_label: 'company name:',
    xiang_qing: 'edit',
    shang_hu_zhi_fu_gong_neng: 'merchant payment function:',
    open: 'open',
    close: 'close',
    forcePushVersiontToDevice: 'force push?',

  },

  role: {
    gen_ju_yuan_gong_zhi_neng_xuan_ze_jue_se: 'choose a role based on the employee\'s function and then add an account.custom roles can be created and permissions can be configured.',
    pei_zhi_quan_xian: 'configure permissions',
    gai_jue_se_mu_qian_yi_pei_zhi: 'the role is currently configured',
    ge_zhang_hao: ' accounts.',
    cha_kan_quan_xian: 'view permissions',
    xin_zeng_zhang_hao: 'add account',
    guan_li_zhang_hao: 'manage accounts',
    jue_se_quan_xian_pei_zhi: 'role permission configuration',
    jue_se_ming_cheng: 'role name',
    shan_chu_gai_jue_se: 'delete this role',
    xuan_ze_quan_xian: 'permissions',
    zi_ding_yi_xin_zeng: 'custom add',
    pei_zhi_zi_ding_yi_jue_se: 'configure custom roles and manage employee accounts under this role, flexibly manage merchant platform permissions.',
    xiu_gai_jue_se: 'modify role',
    xin_zeng_jue_se: 'add role',
    cha_kan_zhang_hao: 'view accounts',
    qing_shu_ru_jiao_se_ming_cheng: 'please enter the role name',
  },
  user: {
    xin_zeng_yuan_gong: 'add employee',
    xu_hao: 'index',
    bian_hao: 'number',
    ming_cheng: 'name',
    shou_ji_hao: 'mobile number',
    zhuang_tai: 'status',
    yuan_gong_tu_xiang: 'employee image',
    chuang_jian_shi_jian: 'creation time',
    yuan_gong_ming_cheng_label: 'employee name:',
    qing_shu_ru_yuan_gong_ming_cheng: 'please enter the employee name',
    deng_lu_mi_ma_label: 'login password:',
    qing_shu_ru_deng_lu_mi_ma: 'please enter the login password',
    deng_lu_shou_ji_label: 'login mobile:',
    qing_shu_ru_deng_lu_shou_ji: 'please enter the login mobile',
    yong_hu_zhuang_tai_label: 'user status:',
    bian_ji_yuan_gong: 'edit employee',
    shen_qing_zhong: 'pending',
    shen_qing_ju_jue: 'application rejected',
    zheng_chang_shi_yong: 'normal use',
    dong_jie: 'freeze',
    yuan_gong_tu_pian_label: 'employee picture:',
    shen_he_zhong: 'under review',
    shen_he_ju_jue: 'review rejected',
    jin_yong: 'disabled',
    geng_xin_yuan_gong_xin_xi: 'update employee information!',
    xin_zneg_yuan_gong_cheng_gong: 'successfully added employee!',
    hui_shou_zhan_lei_xing: 'recycle bin type',
    qing_xuan_ze_hui_shou_zhan_lei_xing: 'please select recycle bin type',
    ming_cheng_label: 'name:',
    qing_shu_ru_ming_chneg: 'please enter the name',
    hui_fu_shi_yong: 'restore use',
    quan_ti_cheng_yuan: 'all members',
    hui_shou_zhan_cheng_yuan: 'recycle bin members',
    zheng_chang_cheng_yuan: 'normal members',
    shi_fou_hui_fu_shi_yong: 'restore use?',
    hui_fu_shi_yong_cheng_gong: 'successfully restored use!',
  },

  system: {
    tiao_jian_sou_suo: 'search',
    bian_ji: 'edit',
    shan_chu: 'delete',
    cao_zuo: 'operation',
    bang_ding_she_bei: 'bind device',
    cha_kan_she_bei: 'view device',
    paySetting: 'payment settings',
    que_ding: 'confirm',
    qu_xiao: 'cancel',
    bao_cun: 'save',
    jie_chu_bang_ding: 'unbind',
    qing_tian_xie_dui_ying_xin_xi: 'please fill in the corresponding information',
    qing_xuan_ze_xia_la_cai_dan_zhong_de_yin_hang: 'please select the bank information from the dropdown menu!',
    shan_chu_cheng_gong: 'delete successful!',
    shi_fou_ji_xu_shan_chu: 'do you want to continue deleting?',
    ti_shi: 'tip',
    shan_chu_ti_shi: 'delete tip',
    bang_ding_she_bei_cheng_gong: 'device binding successful!',
    tong_bu_she_bei_cheng_gong: 'sync binding successful!',
    shi_fou_jie_bang_suo_xuan_she_bei: 'do you want to unbind the selected devices?',
    qing_xuan_qu_xu_yao_bang_ding_de_she_bei: 'please select the devices to be bound',
    qing_xuan_qu_xu_yao_jie_bang_de_she_bei: 'please select the devices to be unbound',
    jie_bang_she_bei_cheng_gong: 'device unbinding successful!',
    zhi_fu_bao_zhi_fu: 'Alipay',
    wei_xin_zhi_fu: 'WeChat Pay',
    bao_cun_she_zhi: 'save settings',
    geng_xin_cheng_gong: 'update successful',
    xin_zeng_cheng_gong: 'addition successful',
    fen_pei_she_bei: 'assign device',
    zhi_fu_she_zhi: 'payment settings',
    ti_jiao: 'submit',
    tian_jia_cheng_gong: 'added successfully!',
    ming_cheng: 'name',
    qing_shu_ru_ming_cheng: 'please enter the name',
    jian_jie: 'description',
    qing_shu_ru_jian_jie: 'please enter the description',
    complete_info: 'please provide complete information',
    jin_tian: 'today',
    zuo_tian: 'yesterday',
    zui_jin_yi_zhou: 'last week',
    zui_jin_yi_yue: 'last month',
    zui_jin_san_yue: 'last three months',
    fan_hui: 'return',
    dao_chu: 'export',
    dao_chu_cheng_gong: 'export successful!',
    shi_jian_fan_wei: 'time range',
    zhi: 'to',
    kai_shi_shi_jian: 'start time',
    jie_shu_shi_jian: 'end time',
    chuang_jian_shi_jian: 'creation time',
    xiu_gai_shi_jian: 'modification time',
    tong_bu_she_bei: 'synchronize devices',
    tong_bu_she_bei_cheng_gong: 'device synchronization successful!',
    chuang_jian_cheng_gong: 'creation successful!',
    xia_zai: 'download',
    quan_xuan: 'select all',
    que_ren: 'confirm',
    chan_pin: 'product',
    xu_hao: 'index',
    bian_hao: 'index',
    jie_bang: 'unbind',
    shi_fou_ji_xu_jie_bang: 'do you want to continue unbinding?',
    jie_bang_cheng_gong: 'unbind successful!',
    qing_xuan_qu_xu_yao_bang_ding_de_zhang_hao: 'please select the accounts to be bound',
    xin_zeng_zhang_hao_cheng_gong: 'new account added successfully!',
    tui_chu_deng_lu: 'logout',
    geng_xin: 'update',
    shang_chuan_cheng_gong: 'upload successful!',
    shang_chuan_shi_bai: 'upload failed!',
    bao_cun_cheng_gong: 'save successful!',
    cai_dan_shou_qi: 'collapse menu',
    ci_gong_neng_wei_kai_fang: 'this feature is not available!',
    bang_ding: 'bind',
    bang_ding_cheng_yuan: 'bind member',
    bang_ding_cheng_gong: 'binding successful!',
    deviceExport: 'device export',
    payEntranceTile: '1:Pay Entrance',
    scanPay: '2:Scan Pay',
    surplusAvailableLabel: 'Remaining available:',
    numOfMchineLabel: 'Number of machines to recharge:',
    rechargeDaysForSingleMachineLabel: 'Recharge days for a single machine:',
    payEntranceTip: 'The number of recharge days or machines cannot be 0',
    payCodeLabel: 'Payment QR Code:',
    payNumLabel: 'Payment Amount:',
    historyVersion: 'History Version',
    logoUrl: 'Logo Picture:',
    videoUrl: 'Dashboard video:',
    picUrl: 'Dashboard Picture:',

    log: {
      changeType: 'Variation type',
      changeTypeOne: 'Systematic deduction',
      changeTypeTwo: 'Assigned customer',
      changeTypeThree: 'Customer return',
      changeTypeFour: 'top-up',
      changeTypeFive: 'System delivery',
      changeTypeLabel: 'Variation type',
      chargeAmount: 'Variable quantity',
      chargeTime: 'Variable time',
      chargeBeforeAmount: 'Quantity before change',
      chargeAfterAmount: 'Variable after quantity',
      chargeRemark: 'remarks',
      pleaseChoosechangeType: 'Please select the change type',

    },

    balance: {
      deviceSn: 'Equipment number',
      deviceName: 'Equipment name',
      retainDay: 'Remaining days',
      expireTime: 'Estimated time of maturity',
    },


    wxpay: {
      wei_xin_pu_tong_shang_hu: 'WeChat ordinary merchant',
      wei_xin_zi_shang_hu: 'WeChat sub-merchant',
      gong_zhong_hao: 'official account',
      qing_shu_ru_gong_zhong_hao: 'Please enter the official account',
      shang_hu: 'merchant',
      shang_hu_hao: 'merchant number',
      qing_shu_ru_shang_hu_hao: 'Please enter the merchant number',
      qing_shu_ru_shang_hu: 'Please enter the merchant',
      shou_quan_zheng_shu: 'authorization certificate',
      qing_dao_ru_shou_quan_zheng_shu: 'Please import the authorization certificate, if no refund is required',
      shou_quan_mi_yao: 'authorization key',
      qing_dao_ru_shou_quan_mi_yao: 'Please import the authorization key, if no refund is required',
      shen_qing_bian_hao: 'application number',
      zhang_hu_ming_cheng: 'account name',
      shen_qing_zhuang_tai: 'application status',
      chuang_jian_shi_jian: 'creation time',
      shen_qing_chuang_jian: 'application creation',
      xiu_gai_shen_qing_jin_du: 'check application progress',
      xiu_gai_shen_qing_xin_xi: 'modify application information',
      shan_chu: 'delete',
      wei_shen_qing: 'unapplied',
      shen_qing_zhong: 'in application',
      shen_qing_cheng_gong: 'application successful',
      shen_qing_shi_bai: 'application failed',
      qi_ye_ji_ben_xin_xi: 'basic company information',
      yin_hang_kai_hu_hang_xin_xi: 'bank account opening information',
      fa_ren_xin_xi: 'legal representative information',
      men_dian_di_zhi: 'store address',
      lian_xi_ren_xin_xi: 'contact information',
      qi_ye_ming_cheng_label: 'Company name:',
      qing_shu_ru_qi_ye_ming_cheng: 'Please enter the company name',
      qualificationType: 'Main business',
      qi_ye_xing_zhi: 'Business nature',
      qing_xuan_ze_jing_ying_xing_zhi: 'Please select the business nature',
      ge_ren_shang_hu: 'individual merchant',
      qi_ye_shang_hu: 'enterprise merchant',
      licenseNumber: 'registration number/unified social credit code',
      licenseCopyUrl: 'business license photo',
      qing_shu_ru_tong_yi_xin_yong_dai_ma: 'Please enter the unified credit code',
      exampleImgLabel: 'Example image:',
      uploadTips: 'Must be a color image, less than 500k in size, and the file format is jpg',
      qualifications: 'food license',
      next: 'next',
      pre: 'previous',
      shen_fen_zheng_xing_ming: 'ID card name',
      qing_shu_ru_shen_fen_zheng_xing_ming: 'Please enter the ID card name',
      shen_fen_zheng_hao_ma: 'ID card number',
      qing_shu_ru_shen_fen_zheng_18_wei_hao_ma: 'Please enter the 18-digit ID card number',
      shen_fen_zheng_you_xiao_qi: 'ID card expiration date',
      xuan_ze_ri_qi: 'Select date',
      yong_jiu: 'permanent',
      shen_fen_zheng_ren_xiang_mian_zhao_pian: 'ID card portrait photo',
      shen_fen_zheng_guo_hui_zhao_pian: 'ID card emblem photo',
      uploadTips_2M: 'Must be a color photo less than 2MB in JPG format',
      shang_pu_jian_cheng: 'Shop abbreviation',
      shang_pu_qu_yu: 'Shop area',
      shang_pu_xiang_xi_di_zhi: 'Shop address',
      dian_pu_men_tou_zhao: 'Shop front photo',
      dian_pu_nei_bu_zhao: 'Shop interior photo',
      qing_shu_ru_shang_pu_jian_cheng: 'Please enter the shop abbreviation',
      qing_shu_ru_shang_pu_xiang_xi_di_zhi: 'Please enter the detailed shop address',
      shang_pu_jian_cheng: 'Merchant abbreviation',
      qing_shu_ru_shang_hu_jian_cheng: 'Please enter the merchant abbreviation for display',
      ke_fu_dian_hua: 'Customer service phone',
      qing_shu_ru_ke_fu_dian_hua: 'Please enter the customer service phone number',
      guan_li_yuan_xing_ming: 'Administrator name',
      qing_shu_ru_guan_li_yuan_xing_ming: 'Please enter the administrator name',
      guan_li_yuan_shen_fen_zheng: 'Administrator ID card',
      qing_shu_ru_guan_li_yuan_shen_fen_zheng: 'Please enter the administrator ID card number',
      guan_li_yuan_shou_ji_hao: 'Administrator mobile number',
      qing_shu_ru_guan_li_yuan_shou_ji_hao: 'Please enter the administrator mobile number',
      guan_li_yuan_you_xiang: 'Administrator email',
      qing_shu_ru_guan_li_yuan_you_xiang: 'Please enter the administrator email, leave blank to use the default system email',
      ti_jiao_shen_he: 'Submit application',
      qualification_type_one: 'Catering',
      qualification_type_two: 'Food and fresh produce',
      qualification_type_three: 'Retail',
      bank_type_one: 'Industrial and Commercial Bank of China',
      bank_type_two: 'Bank of Communications',
      bank_type_three: 'China Merchants Bank',
      bank_type_four: 'China Minsheng Bank',
      bank_type_five: 'China CITIC Bank',
      bank_type_six: 'Shanghai Pudong Development Bank',
      bank_type_seven: 'Industrial Bank Co., Ltd.',
      bank_type_eight: 'China Everbright Bank',
      bank_type_nine: 'China Guangfa Bank',
      bank_type_ten: 'Ping An Bank',
      bank_type_eleven: 'Bank of Beijing',
      bank_type_twelve: 'Huaxia Bank',
      bank_type_thirteen: 'Agricultural Bank of China',
      bank_type_fourteen: 'China Construction Bank',
      bank_type_fifteen: 'Postal Savings Bank of China',
      bank_type_sixteen: 'Bank of China',
      bank_type_seventeen: 'Bank of Ningbo',
      bank_type_eighteen: 'Other banks',
      chang_du_bu_shao_yu_three: 'Length should be no less than 3 characters',
      chang_du_bu_shao_yu_two: 'Length should be no less than 2 characters',
      chang_du_bu_shao_yu_one: 'Length should be no less than 1 characters',
      qing_xuan_qu_zhu_ying_ye_wu: 'Please select main business',
      qing_shu_ru_zhu_ce_hao: 'Please enter your registration number/Uniform Social Credit code',
      qing_shang_chuan_ying_ye_zhi_zhao: 'Please upload your business license',
      qing_tian_xie_kai_hu_ming_cheng: 'Please fill in the account name',
      qing_xuan_qu_yin_hang: 'Please select bank',
      qing_xuan_qu_yin_hang_qu_yu: 'Please select the bank area',
      qing_tian_xie_zhi_hang_ming_cheng: 'Please select a branch name',
      qing_tian_xie_yin_hang_zhang_hao: 'Please fill in your bank account number',
      qing_tian_xie_shang_pu_jian_cheng: 'Please fill in the short name of the store',
      qing_tian_xie_shang_pu_xiang_xi_di_zhi: 'Please fill in the full address of the shop',
      qing_xuan_qu_shang_pu_qu_yu: 'Please select the shop area',
      qing_shang_chuan_men_tou_zhao: 'Please upload the door photo',
      qing_shang_chuan_dian_nei_zhao: 'Please upload a photo of the store',
      qing_tian_xie_shang_hu_jian_cheng: 'Please fill in the merchant abbreviation',
      qing_tian_xie_ke_fu_dian_hua: 'Please fill in the customer service number',
      qing_shu_ru_shou_ji_hao: 'Please enter your mobile phone number',
      shou_ji_hao_ge_shi_bu_dui: 'The format of the phone number is incorrect',
      qing_tian_xie_kai_hu_ming_cheng: 'Please fill in the account name',
      qing_tian_xie_zheng_jian_hao_ma: 'Please fill in the ID number',
      zheng_jian_hao_ma_ge_shi_you_wu: 'Wrong format of ID number!',
      qing_tian_xie_fa_ren_ming_cheng: 'Please fill in the legal person name',
      qing_tian_xie_shen_fen_zheng_you_xiao_qi: 'Please fill in the validity period of your ID card',
      qing_shang_chuan_shen_fen_zheng_ren_xiang_mian_zhao_pian: 'Please upload a face photo of your ID card',
      qing_shang_chuan_shen_fen_zheng_guo_hui_mian_zhao_pian: 'Please upload a face photo of your ID card',
      kai_hu_ming_cheng: 'Account name',
      qing_shu_ru_gong_si_ge_ren_kai_hu_ming_cheng: 'Please enter the company/individual account name',
      kai_hu_yin_hang: 'Bank of deposit',
      kai_hu_yin_hang_sheng_shi: 'Place of deposit',
      kai_hu_zhi_hang_quan_cheng: 'Full name of the branch where the account was opened',
      qing_shu_ru_kai_hu_zhi_hang_quan_cheng: 'Enter the full name of the branch where the account is opened, such as the business Department of Industrial and Commercial Bank of China Beijing Branch',
      yin_hang_zhang_hao: 'Bank account number',
      qing_shu_ru_yin_hang_zhang_hao: 'Please enter your bank account number',
      APPLYMENT_STATE_FINISHED: 'The application for merchant occupancy has been completed.',
      APPLYMENT_STATE_CANCELED: 'The application has been withdrawn.',
      APPLYMENT_STATE_SIGNING: 'The system is enabling related rights, please wait patiently.',
      APPLYMENT_STATE_TO_BE_SIGNED: 'Please use wechat to open the returned "signing link" and complete the signing according to the page guidelines.',
      APPLYMENT_STATE_TO_BE_CONFIRMED: 'Please use wechat to open the returned "contract link" and complete the account verification according to the page guidelines.',
      APPLYMENT_STATE_REJECTED: 'Please modify the application materials according to the reasons for rejection. The super administrator opens the "signing link" on wechat and completes the binding of the wechat signal. The subsequent application progress will be notified to the super administrator through the wechat public account.',
      APPLYMENT_STATE_AUDITING: 'The application form is under review. The super administrator opens the "contract link" with wechat, and after completing the binding of the wechat signal, the progress of the application form will be notified to the super administrator through the wechat public account to guide the subsequent steps.',
      APPLYMENT_STATE_EDITTING: 'An error occurred in submitting the application. Please try to submit it again.',

    },

    alipay: {
      ce_shi_zhi_fu: 'Test payment',
      ce_shi_zhi_fu_label: 'Test payment:',
      zhi_fu_bian_hao_label: 'Number:',
      zhi_fu_zhuang_tai_label: 'Status:',
      dao_qi_shi_jian_label: 'Maturity time:',
      zhi_fu_shou_quan_ma: 'Authorization code',
      createAuthorizedQrCode: 'Create an authorized QR code',
      produceAuthorizedQrCode: 'Generate authorized QR code',
      dang_mian_fu_shou_quan_ma_label: 'Pay the authorization code in person',
      deng_dai_shou_quan: 'Waiting for authorization',
      yi_shou_quan: 'authorized',
      shen_qing_zhong: 'Under application',
      wu_xiao_er_wei_ma: 'Invalid QR code',
      zan_wu_bian_hao: 'No number for now',
      shan_chu_zhi_fu: 'Delete payment',
      bang_ding_er_dai_zhi_fu: 'Bind  second version payment',


    }


  },

  order: {
    ding_dan_bian_hao: 'order number',
    qing_shu_ru_ding_dan_bian_hao: 'please enter order number',
    ming_cheng_sou_suo: 'name search',
    qing_shu_ru_she_bei_ming_cheng: 'please enter device name',
    bian_hao_sou_suo: 'number search',
    qing_shu_ru_she_bei_bian_hao: 'please enter device number',
    qing_xuan_ze_zhi_fu_fang_shi: 'please select payment method',
    qing_xuan_ze_zhi_zuo_lei_xing: 'please select production type',
    zhi_zuo_zhuang_tai: 'production status',
    zhi_zuo_shi_jian: 'production time',
    jie_shu_ri_qi: 'end date',
    zhi: 'to',
    kai_shi_ri_qi: 'start date',
    order_number: 'order number',
    pay_number: 'payment number',
    she_bei_ming_cheng: 'device name',
    she_bei_bian_hao: 'number',
    yin_pin_ming_cheng: 'beverage name',
    yin_pin_bei_liang: 'beverage cup size',
    ding_dan_zhuang_tai: 'order status',
    zhi_fu_jin_e: 'payment amount',
    zhi_fu_fang_shi: 'payment method',
    zhi_zuo_lei_xing: 'production type',
    tui_kuan_zhuang_tai: 'refund status',
    ding_dan_shi_jian: 'order time',
    ding_dan_tui_kuan: 'order refund',
    suo_you_zhi_fu: 'all payments',
    wu_zhi_fu: 'no payment',
    zhi_fu_bao: 'Alipay',
    wei_xin_zhi_fu: 'WeChat Pay',
    su_zhou_nong_hang: 'Suzhou Rural Commercial Bank',
    suo_you_ding_dan: 'all orders',
    ce_shi_ding_dan: 'test orders',
    wu_zhi_fu_ding_dan: 'no payment orders',
    zhi_fu_ding_dan: 'payment orders',
    all: 'all',
    make_success: 'make success',
    make_fail: 'make fail',
    chu_shi_zhuang_tai: 'initial status',
    yi_zhi_fu: 'paid',
    yi_tui_kuan: 'refunded',
    zhi_zuo_zhong: 'in production',
    cheng_gong: 'success',
    zhi_zuo_shi_bai: 'production failed',
    xiao_bei: 'small cup',
    zhong_bei: 'medium cup',
    da_bei: 'large cup',
    tui_kuan_cheng_gong: 'refund success',
    ci_cao_zuo_jiang_hui_tui_huan_ding_dan_jin_e: 'This operation will refund the order amount, continue?',
    bei_xing_bai_fen_bi: 'Cup percentage(%)',
    exportExcelTip: 'The maximum order export time is half a year!',
    tempratureLabel: 'Extraction Tempture:',
    extractionTimeLabel: 'Extraction Time:',
    drinkInfoTitle: 'Drink Info',
    isCancel: 'is Cancel:',
    steamVol: 'steam Vol:',
    waterVol: 'coffee Vol:',
    pieNum: 'pie Num:',
    powderWeight: 'powder Weight:',
    makeStartAt: 'make Start At:',
    makeEndAt: 'make End At:',

  },

  product: {
    xin_zeng_chan_pin: 'add product',
    qing_shu_ru_chan_pin_ming_cheng: 'Please enter the model name',
    bian_hao: 'number',
    ji_xing_ming_cheng: 'model name',
    chan_pin_ming_cheng: 'product name',
    chan_pin_ming_cheng_label: 'product name',
    chan_pin_ying_wen_ming_cheng: 'English name',
    chan_pin_bei_zhu: 'product remark',
    qing_shu_ru_chan_pin_ying_wen_ming_cheng: 'please enter product English name',
    qing_shu_ru_chan_pin_bei_zhu: 'please enter product remark',
    chan_pin_bian_hao_label: 'product number:',
    shang_chuan_xin_ban_ben: 'upload new version',
    mo_ren_fa_bu_ke_jiang_wu_ban_ben_she_bei_bang_ding_mo_ren_ban_ben: 'Default publishing can bind default version for unversioned devices',
    ban_ben_lie_biao: 'version list',
    ban_ben_hao: 'version number',
    mo_ren: 'default',
    ruan_jian_ban_ben_hao: 'software version number',
    miao_shu_xin_xi: 'description information',
    chuang_jian_shi_jian: 'create time',
    she_wei_mo_ren: 'set as default',
    shan_chu_ban_ben: 'delete version',
    she_bei_quan_liang_geng_xin: 'device full update',
    shang_chuan_ban_ben: 'upload version',
    ban_ben_xin_xi: 'version information',
    ban_ben_shuo_ming: 'version description',
    qing_xuan_qu_chan_pin: 'please select product',
    wen_jian_qian_ming_cuo_wu: 'file signature error',
    qing_shang_chuan_wen_jian: 'please upload file',
    qing_tian_xie_miao_shu: 'please fill in description',
    shang_chuan_cheng_gong: 'upload success!',
    she_ding_cheng_gong: 'setting successful!',
    device_full_update_successful: 'device full update successful!',
    she_bei_quan_liang_geng_xin_cheng_gong: 'Default version only allows full update!',
    zhi_yun_xu_mo_ren_ban_ben_quan_liang_geng_xin: 'Default version is not allowed to delete',
    ci_cao_zuo_jiang_shan_chu_zhi_ding_ban_ben: 'This operation will delete the specified version, continue?',
    qing_xuan_qu_she_bei: 'please select device',
    fen_pei_cheng_gong: 'allocation successful!',
    qing_shu_ru_chan_pin_can_shu: 'please enter product parameters',
    drinkModelSetting: 'Beverage model configuration',
    name: 'name',
    color: 'color',
    makeType: 'Production type',
    makeChildType: 'Make subtypes',
    canTransferMaterial: 'Whether to edit material',
    stepEdit: 'Step Edit',
    modelStep: 'Model Step',
    addModelItem: 'Add model',
    nameLabel: 'name：',
    colorLabel: 'color：',
    makeTypeLabel: 'Production type：',
    makeChildTypeLabel: 'Make subtypes：',
    canTransferMaterialLabel: 'Whether to edit material：',
    modelEidt: 'Model Edit',
    modelAdd: 'Model Add',
    stepName: 'Step name',
    canEdit: 'Can edit',
    minValue: 'Range minimum',
    maxValue: 'Range maximum',
    defaultValue: 'Default value',
    stepValue: 'Incremental step',
    filedType: 'Column data type',
    sendType: 'Data unit conversion type',
    Hidden: 'Whether to hide when making',
    Options: 'Options',
    drinkModelEdit: 'Machine drink model',
    notEditDrinkModel: 'There are no models with corresponding drinks!',
    pleaseEnterModelName: 'Please enter a step name',
    pleaseEnterMakeType: 'Please enter the production type',
    pleaseEnterMakeChildType: 'Please enter a crafting subtype',
    addModelStep: 'New step field',
    editModelStep: 'Edit step field',
    radius: 'Radius',
    filedLabel: 'Column name identifier:',
    minValueLabel: 'Range minimum:',
    maxValueLabel: 'Range maximum:',
    valueLabel: 'Default value:',
    offsetLabel: 'Offset value:',
    scaleLabel: 'Data magnification:',
    stepLabel: 'Incremental step:',
    fieldTypeLabel: 'Column data type:',
    itemTypeLabel: 'Column display type:',
    hiddenLabel: 'Whether to hide when making:',
    optionsLabel: 'Options:',
    filedLabelTip: 'Please enter a field name identifier',
    minValueLabelTip: 'Please enter the minimum range value',
    maxValueLabelTip: 'Please enter the maximum range',
    stepLabelTip: 'Please enter an incremental step',
    valueLabelTip: 'Please enter the default value',
    scaleLabelTip: 'Please enter data magnification',
    fieldTypeLabelTip: 'Please enter the field data type',
    itemTypeLabelTip: 'Please enter the field display type',
    bindMolde: 'bind',
    fieldOptionLabel: 'label',
    fieldOptionValue: 'value',
    addOptionItem: 'add options',
    pleaseEnterLabel: 'Please enter label',
    pleaseEnterValue: 'Please enter value',
    pleaseEnterOptionData: 'Please enter extensibility',
    isShowMaterial: 'Whether to display powder',
    type: 'Extended data type',
    options: 'options',
    bindModel: 'Binding model',
    nextVersion: 'Next Version',
    productVersionSelect: 'productVersionSelect',
    pleaseSelectNextVersion: 'Please Select Next Version：',



  },

  material: {
    yuan_liao_fen_lei: 'raw material classification',
    zi_ding_yi_xin_zeng: 'custom addition',
    ji_xing_yuan_liao: 'model material',
    yuan_liao_bu_chong:'material add',
    xuan_ze_fen_lei_label: 'select classification:',
    xuan_ze_fen_lei: 'select classification',
    ming_cheng_label: 'name:',
    qing_shu_ru_ming_cheng: 'please enter name',
    ming_cheng_ying_wen_label: 'name (English):',
    qing_shu_ru_ming_cheng_ying_wen: 'please enter name (English)',
    qing_xuan_ze_yuan_liao_fen_lei: 'please select raw material classification',
    xin_zeng_yuan_liao: 'add material',
    xiu_gai_yuan_liao: 'edit material',
    shan_chu_fen_lei_cheng_gong: 'delete classification success!',
    xin_zeng_fen_lei_cheng_gong: 'add classification success!',
    geng_xin_fen_lei_cheng_gong: 'update classification success!',
    ji_xing_ming_cheng: 'model name',
    xiang_guan_yuan_liao: 'related materials',
    tian_ru_yuan_liao: 'fill in material',
    xuan_ze_yuan_liao_label: 'select material:',
    xuan_ze_yuan_liao: 'select material',
    leftPowderBox: 'left powder box',
    middlePowderBox: 'middle powder box',
    rightPowderBox: 'right powder box',
    qing_xuan_ze_tian_ru_de_yuan_liao: 'please select filled material',
    tian_ru_yuan_liao_cheng_gong: 'fill in material success',
    pleaseUploadFixedPic: 'Please upload a picture of the corresponding size!',
    bu_chong_lei_xing:'Add Type',
    bu_chong_shi_jian:'Add Time',
    bu_chong_shu_liang:'Add Amount',
    she_bei_ming_cheng:'Device Name',
    qing_xuan_ze_bu_chong_lei_xing:'Please Select Add Type',
    qing_shu_ru_she_bei_ming_cheng:'Please Enter Device Name',
    qing_shu_ru_bu_chong_shu_liang:'Please Enter Add Amount',



  },

  device: {
    suo_shu_chan_pin: 'Product',
    qing_xuan_qu_dui_ying_chan_pin: 'Please select the corresponding product',
    suo_shu_ke_hu: 'Customer',
    qing_xuan_qu_dui_ying_de_ke_hu: 'Please select the corresponding customer',
    qing_xuan_ze_she_bei_zhuang_tai: 'Please select the device status',
    ming_cheng_sou_suo: 'Name search',
    qing_shu_ru_she_bei_ming_chen: 'Please enter the device name',
    bian_hao_sou_suo: 'Number search',
    qing_shu_ru_she_bei_bian_hao: 'Please enter the device number',
    she_bei_bian_hao: 'Number',
    she_bei_ming_cheng: 'Name',
    imei: 'IMEI',
    qing_shu_ru_she_bei_imei: 'Please enter the device IMEI',
    chan_pin_ming_cheng: 'Product name',
    she_bei_zhuang_tai: 'Device status',
    she_bei_wei_zhi: 'Device location',
    zan_wei_she_zhi_de_li_wei_zhi: 'Location not set',
    qi_yong_zhuang_tai: 'Activation status',
    qi_yong_zhuang_tai_label: 'Activation status:',
    she_bei_xiang_qing: 'Device details',
    yin_pin_pei_zhi: 'Beverage configuration',
    qi_yong: 'Enable',
    ting_yong: 'Disable',
    li_xian: 'Offline',
    zai_xian: 'Online',
    quan_bu: 'All',
    qing_wan_shan_she_bei_ming_cheng: 'Please complete the device name',
    qing_she_ding_she_bei_suo_zai_wei_zhi: 'Please set the device location',
    que_ren_ting_yong_gai_she_bei: 'Confirm to disable this device?',
    que_ren_qi_yong_gai_she_bei: 'Confirm to enable this device?',
    ming_cheng_label: 'Name:',
    chan_pin: 'Product',
    bian_ji_xin_xi: 'Edit device',
    she_bei_la_qu: 'Device retrieval',
    she_bei_la_qu_cheng_gong: 'Device retrieval successful!',
    bindTime: 'Binding time',
    runStatusZero: 'Power off',
    runStatusOne: 'To be run',
    runStatusTwo: 'Be running',
    runStatusThree: 'Exception pending',
    runStatusFour: 'Failure shutdown',
    runStatus: 'RunStatus',
    runStatusOfflineTip: 'The device is offline and cannot view the fault cause!',
    bindSnAndImei: 'rebind',


    detail: {
      ji_ben_xin_xi: 'Basic information',
      she_bei_ming_cheng: 'Device name',
      she_bei_xing_hao: 'Product',
      she_bei_xu_lie_hao: 'Serial number',
      she_bei_ban_ben_hao: 'Version',
      she_bei_mo_kuai: 'IMEI',
      SIM_ka_hao: 'SIM',
      she_bei_zhuang_tai: 'Status',
      suo_zai_di_qu: 'Local area',
      xiang_xi_di_zhi: 'Full address',
      qing_shu_ru_xiang_xi_di_zhi: 'Please enter the full address',
      yun_ying_she_zhi: 'Operation setting',
      ri_chang_qing_jie: 'Daily cleaning',
      zi_dong_kai_guan_ji: 'Automatic switch',
      yi_chang_lie_biao: 'Exception list',
      wei_hu_ming_xi: 'Maintenance details',
      yun_xing_ji_lu: 'Running record',
      ji_qi_she_zhi: 'Machine setup',
      she_bei_su_cai: 'Equipment material',
      province: 'province',
      city: 'city',
      addressLatAndLng: 'Latitude and longitude information',
      versionTip: 'Detect a new version, click Force Update!',
      remoteClean: 'remote clean',

    },


    clean: {
      qing_shu_ru_ming_cheng: 'please enter the name',
      xin_zeng_qing_jie: 'add cleaning',
      bian_ji_qing_jie: 'edit cleaning',
      niu_nai_shen_du_qing_xi: 'milk system deep cleaning',
      chong_zhu_xi_tong_shen_du_qing_xi: 'brewing system deep cleaning',
      fen_liao_xi_tong_shen_du_qing_xi: 'powder system deep cleaning',
      chu_fa_shi_jian_label: 'trigger time:',
      qing_xuan_ze_chu_fa_shi_jian: 'please select the trigger time',
      shi_jian_she_zhi: 'time setting',
      geng_xin_she_zhi: 'update settings',
      monday: 'monday',
      tuesday: 'tuesday',
      wednesday: 'wednesday',
      thursday: 'thursday',
      friday: 'friday',
      saturday: 'saturday',
      sunday: 'sunday',
      shan_chu_wei_hu_xin_xi_dui_ji_you_she_bei_bu_hui_chan_sheng_ying_xiang: 'deleting maintenance information will not affect existing equipment, continue to delete?',
      mei_ri_bi_bei: '(daily essential)',
      mei_zhou_zhi_shao_yi_ci: '(at least once a week)'


    },


    operate: {
      jin_ru_ping_bao_shi_jian: 'enter screensaver time',
      qing_shu_ru_jin_ru_ping_bao_shi_jian: 'please enter the screensaver time',
      tui_chu_ping_bao_shi_jian: 'exit screensaver time',
      qing_shu_ru_tui_chu_ping_bao_shi_jian: 'please enter the exit screensaver time',
      ping_bao_jian_ge_shi_jian: 'screensaver interval time',
      qing_shu_ru_ping_bao_jian_ge_shi_jian: 'please enter the screensaver interval time',
      logo_change: 'logo change',
      kai_ji_tu_pian_geng_huan: 'startup image change',
      zhi_zuo_tu_pian_geng_huan: 'production image change',
      ping_bao_tu_pian_geng_huan: 'screensaver image change',
      su_cai_ku_dao_ru: 'import from media library',
      ben_di_shang_chuan: 'local upload',
      xin_zeng_tu_pian: 'add image',
      geng_huan_tu_pian: 'change image',
      xin_zeng_tu_pian_cheng_gong: 'image added successfully!',
      geng_xin_tu_pian_cheng_gong: 'image updated successfully!',
      ti_shi_logo: 'hint: click on the image to change. image size: 250x48 pixels, image format: png',
      ti_shi_start: 'hint: click on the image to change. image size: 1024x600 pixels, image format: bmp',
      ti_shi_make_pic: 'hint: click on the image to change. image size: 620x540 pixels, image format: png',
      ti_shi_screen: 'hint: click on the image to change. image size: 1024x600 pixels, image format: png'
    },

    switch: {
      xin_zeng_kai_guan_ji: 'add power schedule',
      yun_xing_dong_zuo: 'operation',
      chu_fa_shi_jian: 'trigger time',
      zhou_qi: 'frequency',
      qi_yong_zhuang_tai: 'enabled status',
      chu_fa_shi_jian_label: 'trigger time:',
      zhou_qi_label: 'frequency:',
      qing_xuan_ze_chu_fa_shi_jian: 'please select the trigger time',
      qing_xuan_ze_zhou_qi: 'please select the frequency',
      zi_dong_guan_ji: 'automatic shutdown',
      zi_dong_kai_ji: 'automatic startup',
      bian_ji_kai_guan_ji: 'edit power schedule',
      geng_xin_kai_guan_ji_xin_xi: 'power schedule information updated successfully!',
      xin_zeng_kai_guan_ji_cheng_gong: 'power schedule added successfully!'

    },

    exception: {
      yi_chang_xiao_xi: 'Exception message',
      yi_chang_shi_jian: 'Abnormal time',
      yi_chang_ming_cheng: 'abnormal name',
      jie_jue_fang_shi: 'Handle Way',
      yi_chang_ming_cheng_label: 'abnormal name:',
      jie_jue_fang_shi_label: 'Handle Way:',
      qing_xuan_qu_yi_chang_ming_cheng: 'please select the abnormal name',
      yi_chang_lei_xing: 'abnormal type',
      qing_xuan_qu_yi_chang_lei_xing: 'please select the abnormal type',
      chi_xu_shi_jian: 'duration',
      shi_jian_fan_wei: 'time range',
      zhi: 'to',
      kai_shi_shi_jian: 'start time',
      jie_shu_shi_jian: 'end time',
      yi_chang_bian_ma: 'abnormal code',
      yi_chang: 'abnormal',
      gu_zhang: 'fault',
      xiao_yu: 'less than',
      deng_yu: 'equal to',
      da_yu: 'greater than',
      s: 's',
      min: 'min',
      hour: 'h',
      cmd: {
        5020000: 'The water storage tray is not installed properly',
        5020001: 'The waste bin is full',
        5020002: 'The water tank is empty',
        5020003: 'The water storage tray is full',
        5020004: 'The bottled water is empty',
        5020005: 'The waste bucket is full',
        5020006: 'The brewing unit is not installed properly',
        5020007: 'The door is not closed properly',
        5020008: 'The left bean container is not installed properly',
        5020009: 'The right bean container is not installed properly',
        5020010: 'The bean container 3 is not installed properly',
        5020011: 'The left bean container is empty',
        5020012: 'The right bean container is empty',
        5020013: 'The bean container is empty',
        5020014: 'The left powder container is not installed properly',
        5020015: 'The right powder container is not installed properly',
        5020016: 'The middle powder container is not installed properly',
        5020017: 'The left powder container is empty',
        5020018: 'The right powder container is empty',
        5020019: 'The middle powder container is empty',
        5020020: 'The grinder needs calibration',
        5020021: 'The coffee machine needs descaling',
        5020022: 'The brewing system needs cleaning',
        5020023: 'The brewing unit needs cleaning',
        5020024: 'The milk system needs cleaning',
        5020025: 'The powder system needs cleaning',
        5020026: 'The milk temperature is too low',
        5020027: 'The milk temperature is too high',
        5020028: 'Insufficient milk',
        5020029: 'The milk container is not installed',
        5020030: 'Low water level in the system',
        5020031: 'High system pressure',
        5020032: 'Brewing unit self-test',
        5020033: 'Diverter valve self-test',
        5020034: 'High ambient temperature',
        5020035: 'Low ambient temperature',
        5020036: 'High coffee boiler temperature',
        5020037: 'High steam boiler temperature',
        5020038: 'High hot water boiler temperature',
        5020039: 'Low coffee boiler temperature',
        5020040: 'Low steam boiler temperature',
        5020041: 'Low hot water boiler temperature',
        5020042: 'System refill timeout',
        5020044: 'Steam rod NTC anomaly',
        5020045: 'Filter needs replacement',
        5020046: 'Milk system needs flushing',
        5020047: 'Powder system needs flushing',
        5020048: 'High powder boiler temperature',
        5020049: 'Low powder boiler temperature',
        5020050: 'The waste bin is not installed properly',
        5020051: 'Bean container 4 is not installed properly',
        5020052: 'Bean container 4 is empty',
        5020056: 'Powder machine door opened',
        5020058: 'Device needs one-click maintenance',
        5030001: 'HMI communication anomaly',
        5030002: 'Coffee boiler heating timeout',
        5030003: 'Steam boiler heating timeout',
        5030004: 'Hot water boiler heating timeout',
        5030005: 'Coffee boiler NTC anomaly',
        5030006: 'Steam boiler NTC anomaly',
        5030007: 'Hot water boiler NTC failure',
        5030008: 'Coffee boiler water supply failure',
        5030009: 'Steam boiler water supply failure',
        5030010: 'Hot water boiler water supply failure',
        5030011: 'Brewing system anomaly',
        5030012: 'Diverter valve system anomaly',
        5030013: 'Bean grinder 1 is abnormal',
        5030014: 'Bean grinder 2 is abnormal',
        5030015: 'Bean grinder 3 is abnormal',
        5030016: 'The communication of the refrigerator system is abnormal',
        5030017: 'Abnormal replenishment time of steam boiler',
        5030018: 'The powder boiler heats over time',
        5030019: 'Abnormal NTC of powder boiler',
        5030020: 'Powder boiler water supply failure',
        5030021: 'The powder system communication is abnormal',
        5030022: 'Bean grinder 4 is abnormal',
        1020000: 'Device network disconnection',
        5030023: 'P30 is disconnected',
        5020057: 'Drink cover door not closed',
      }
    },

    maintain: {
      wei_hu_lei_xing: 'maintenance type',
      qing_xuan_qu_wei_hu_lei_xing: 'please select the maintenance type',
      shi_jian_fan_wei: 'time range',
      zhi: 'to',
      kai_shi_shi_jian: 'start time',
      jie_shu_shi_jian: 'end time',
      qing_xi_he_ji: 'cleaning total',
      chong_zhu_xi_tong_shen_du_qing_xi: 'brewing system deep cleaning',
      niu_nai_xi_tong_shen_du_qing_xi: 'milk system deep cleaning',
      chu_gou: 'descale',
      yi_jian_bao_yang: 'one-click maintenance',
      qing_kong_shui_lu: 'flush water lines',
      she_bei_ming_cheng: 'device name',
      chan_pin_xing_hao: 'product model',
      she_bei_sn: 'device SN',
      wei_hu_bian_ma: 'maintenance code',
      cmd: {
        5010007: 'Descale',
        5010006: 'Deep Clean Milk System',
        5010002: 'Quick Rinse Milk System',
        5010003: 'Quick Rinse Powder System',
        5010005: 'Deep Clean Coffee System',
        5010008: 'Empty Water Circuit',
        5010022: 'One-Click Cleaning',
        5010035: 'Deep Clean Powder System',
        5000011: 'Quick Clean Brewing System',
        5010012: 'Grinder Calibration',
        5010013: 'Powder Calibration',
      }
    },

    record: {
      shi_jian_lei_xing: 'event type',
      qing_xuan_qu_shi_jian_lei_xing: 'select an event type',

    },
    material: {
      yin_pin_su_cai: 'beverage materials',
      ping_bao_su_cai: 'screensaver materials',
      zhi_zuo_lun_bo_tu: 'create slideshow',
      xin_zeng_su_cai: 'add material',
      xin_zeng_su_cai_cheng_gong: 'material added successfully!',
      yi_xuan_zhong: 'selected',
      ge_yin_pin_tu_pian: 'materials',
      ruo_su_cai_bei_zheng_chang_shi_yong: 'If the material is in normal use, it cannot be deleted. Do you want to continue deleting?',
      pi_liang_xia_zai: 'batch download',
      qing_xuan_ze_xia_zai_su_cai: 'Please select materials to download!'

    },
    setting: {
      s: 'second',
      cup: 'cup',
      ml: 'milliliter',
      immediate: 'immediate',
      fiveMinLater: 'five minutes later',
      tenMinLater: 'ten minutes later',
      fifteenMinLater: 'fifteen minutes later',
      twentyMinLater: 'twenty minutes later',
      twoHourLater: 'two hours later',
      lightBlue: 'light blue',
      lightGreen: 'light green',
      lightYellow: 'light yellow',
      lightCyan: 'light cyan',
      lightPurple: 'light purple',
      lightWhite: 'light white',
      lightCycle: 'light cycle',
      makeModeRefuseSet: 'do not adjust quantity before making',
      makeModeCanSet: 'adjustable quantity before making',
      paySetting: 'payment settings',
      matainSet: 'maintenance settings',
      diySetting: 'personalization settings',
      functionSw: 'function switch',
      iotSw: 'IOT switch',
      paySw: 'payment switch',
      scanPaySw: 'scan',
      mdbSw: 'MDB',
      coffeeQuickCleanSw: 'coffee quick clean switch',
      coffeeQuickCleanTs: 'coffee scheduled quick clean time',
      deepCoffeeCleanLimit: 'coffee deep clean cup limit',
      powerQuickCleanTs: 'powder quick clean time',
      descale: 'descaling switch',
      descaleCupLimit: 'descaling cup limit setting',
      milkPumpTs: 'milk pump rinse time',
      milkQuickCleanTs: 'milk quick clean time',
      deepMilkCleanLimit: 'milk deep clean cup limit',
      trashSw: 'waste full switch',
      beanBoxFew: 'insufficient beans switch',
      beanBox2Few: 'right bean box insufficient beans switch',
      leftPowerFew: 'left powder insufficient switch',
      midPowerFew: 'middle powder insufficient switch',
      rightPowerFew: 'right powder insufficient switch',
      steamBoilerSwitch: 'steam boiler switch',
      hotWaterSw: 'hot water switch',
      hotWaterVol: 'hot water value setting',
      drinkModeSelect: 'drink display mode selection',
      makeModeSelect: 'make mode selection',
      screenBrigressVal: 'screen brightness setting',
      decorateLightSw: 'device light switch',
      lightColor: 'light color',
      lightCycleSec: 'light cycle interval',
      businessSw: 'operation setting synchronization switch',
      dailyCleanSw: 'daily cleaning synchronization switch',
      autoPowerSw: 'automatic on/off synchronization switch',
      drinkSw: 'drink synchronization switch',
      beanBox1Few: 'left bean box insufficient beans switch',
      closeFullCountTip: 'Closing this option may cause brewing system malfunctions, which are not covered by the warranty. It is recommended to enable this function.',
      closeBeanFewTip: 'Closing this option will not prompt for insufficient beans in the bean box. It is recommended to enable this function.',
      closeLeftPowerTip: 'Closing this option will not prompt for insufficient powder in the left powder box. It is recommended to enable this function.',
      closeRightPowerTip: 'Closing this option will not prompt for insufficient powder in the right powder box. It is recommended to enable this function.',
      closeMidPowerTip: 'Closing this option will not prompt for insufficient powder in the middle powder box. It is recommended to enable this function.',
      closeDescaleTip: 'Closing this option may cause coffee machine system malfunctions, which are not covered by the warranty. It is recommended to enable this function.',
      closeMilkCleanTip: 'Closing this option may cause milk system malfunctions, which are not covered by the warranty. It is recommended to enable this function.',
      no: 'no',
      drinkPowderSw: 'powder switch',
      drinkSortSw: 'drink classification switch',
      drinkLock: 'drink status synchronization lock',
      ecoState: 'ECO STATE',
      globalConfigure: 'Global Configure',
      needConfigure: 'Need Configure',
      groupConfigure: 'Group Configure',
      customerConfigure: 'Customer Configure',
      configureSync: 'Configure Sync',
      isConfirmGlobalConfigure: 'Global Universal is enabled to synchronize all machine Settings. Do you want to continue?'

    },
    drink: {
      nameTitle: 'drink category',
      selfCreateDrink: 'create custom',
      deviceImport: 'device import',
      tableNo: 'number',
      tableDrinkInfo: 'drink information',
      tableDrinkBrand: 'drink category',
      tableDrinkCreatedAt: 'created at',
      tableDrinkHidden: 'display status',
      tableDrinkSort: 'sort (smaller is closer to the front)',
      drinkSortTip: 'drink category (only four allowed)',
      hiddenTxt: 'hidden or not',
      hidden: 'hidden',
      display: 'display',
      editBtn: 'edit information',
      editCupBtn: 'edit cup type',
      searchDrinkNo: 'number',
      searchDrinkName: 'name',
      searchDrinkHidden: 'status',
      searchDrinkBrand: 'category',
      createDrinkSort: 'create category',
      editDrinkSort: 'edit category',
      name: 'category name',
      nameTip: 'please enter category name',
      sort: 'sort',
      sortTip: 'please enter sort number',
      hiddenLabel: 'hide category',
      drinkImg: 'drink image:',
      drinkNo: 'drink number:',
      drinkNoTip: 'please enter drink number',
      drinkName: 'drink name:',
      drinkNameTip: 'please enter drink name',
      drinkEngName: 'English name:',
      drinkEngNameTip: 'please enter drink English name',
      drinkSort: 'sort position:',
      drinkSortTipLocation: 'please enter sort position',
      drinkBrand: 'drink category:',
      drinkBrandTip: 'please select drink category',
      create: 'create drink',
      edit: 'edit drink',
      all: 'all',
      show: 'show drinks',
      hiddenDrink: 'hidden drinks',
      clearBrand: 'all',
      step: 'step',
      selectCup: 'cup type',
      cup0: 'small cup',
      cup1: 'medium cup',
      cup2: 'large cup',
      choicePowder: 'please select powder',
      fromDevice: 'device addition',
      fromCustomer: 'customer synchronization',
      from: 'drink source',
      price: 'price',
      inputPrice: 'please enter price',
      recycle: 'recycle times',
      inputRecycle: 'please enter production recycle times',
      receptionOrder: 'production order',
      deleteStep: 'delete step',
      powderType: 'set powder type',
      choicePowderType: 'please select powder type',
      atLeastOneCup: 'at least one cup type is required',
      xuan_qu_la_qu_she_bei: 'select fetch device',
      xuan_qu_she_bei_label: 'select device:',
      needReception: 'please configure recipe',
      receptionTip: 'Tip: Drag the corresponding color block to fill in the step order above, which is the production order of the drink; filling in A and B means simultaneous production.',
      percent: 'Cup percentage',
      selectPercent: 'Please enter cup percentage',
      percentTip: 'Tip:The value ranges from 80 to 120',
      acceptModel: 'Applicable model',
      commonModel: 'Universal model',
      selectCupTip: 'This cup has been hidden, please turn on the hide switch!',
      hiddenCup: 'Cup hiding',
      changeProductTip: 'Parameters have been modified. Do you want to stop saving and continue switching models?',




    },
    remote: {
      boilingQuickClean: 'boilingQuickClean',
      powderQucikClean: 'powderQucikClean',
      milkQuickClean: 'powderQucikClean',
      warning: 'warning',
      warningTip: 'During the flushing process, there may be steam ejecting at the coffee outlet. Do not put your hand on the coffee outlet to avoid burns!',

    }
  },
  //菜单
  org: {
    manager: {
      router: 'Distributor',
      list: 'List Information',
      recycle: 'Recycle Bin',
      paySetting: 'Payment Settings',
      machine: 'Device Binding'
    },
    basic: {
      router: 'System Settings',
      setting: 'Basic Information',
      account: 'Member Management',
      role: 'Permission Assignment',
      pay: 'Payment Settings',
      setting: 'machine setting',
      audit: 'audit',
      dashboard: 'dashboard setting',

    }
  },
  equipment: {
    device: {
      router: 'Device',
      list: 'Device List',
      file: 'Operational Materials',
      daily: 'Daily Cleaning',
      task: 'Automatic Power On/Off',
      error: 'Exception List',
      maintain: 'Maintenance Details',
      detail: 'Device Details',
      drinks: 'Device Drinks',
      setting: 'Machine Setting',
      department: 'Device department',

    },
    machine: {
      router: 'Machine Type',
      products: 'Machine Type List',
      keys: 'Platform Keys',
      drinks: 'Drink Models',
      version: 'Machine Type Version',
      model: 'Drink Model',
      step: 'Model Step',
      field: 'Step field',
      fieldRouter: 'Step field',


    },
    drink: {
      router: 'Drink Library',
      material: 'Ingredient Management',
      list: 'Drink List',
      cupEdit: "Cup Type Editing",
      group: 'Drink Group',
    },
    order: {
      router: 'Production List',
      list: 'Order List'
    },
    card: {
      router: 'IC Card Manage',
      list: 'IC Card List',
      history: 'IC Card Detail',
      register:'Register IC Card',
    },
    customer: {
      router: 'Beverage management',
      list: 'Beverage customer',
      paySetting: 'Payment Settings',
      drinkSetting: 'Drink Configuration',
    },
    org: {
      machineSetting: 'Machine Setting',
    },
    errors: 'Exception Library',
    maintain: 'Maintenance and Upkeep',
  }
  ,
  autoPosition: 'auto position',
  coffee: 'coffee',
  powderBox: 'powder',
  hotWater: 'hot water',
  milk: 'milk',
  foam: 'foam',
  step: 'step',
  recycleTimes: 'recycle times',
  grindVal: 'grind amount (g)',
  preCoolVal: 'pre-brewing time (s)',
  coffeeVal: 'coffee amount',
  extractMode: 'extraction mode',
  temperatureGarden: 'temperature level',
  hotWaterVol: 'hot water amount',
  milkTemperature: 'milk temperature',
  milkVol: 'milk amount (s)',
  steamVol: 'foam amount (s)',
  extractModeNormal: 'normal',
  extractModeOne: 'strong',
  extractModeTwo: 'deep',
  temperatureGardenLow: 'low temperature',
  temperatureGardenMiddle: 'medium temperature',
  temperatureGardenHigh: 'high temperature',
  drinkNameEngNameTxt: 'drink name / English display',
  startPower: 'front water outlet',
  waterTemperature: 'water temperature',
  stirWater: 'total water amount',
  powerWeight: 'powder weight (g)',
  powerMode: 'powder dispensing speed (%)',
  afterWater: 'rear water outlet',
  grindBeanRight: 'right bean hopper operation',
  grindBeanAHead: 'grinding beans simultaneously',
  rightGrinder: 'right grinder',
  grindBean: 'bean hopper selection',
  grindBeanLeft: 'left bean hopper operation',
  powerVal: 'powder amount (g)',
  waterVol: 'cold water amount',
  delayVol: 'waiting time (s)',
  pressureMode: 'pressure mode',
  hasPressure: 'with pressure',
  noPressure: 'without pressure',
  boiledMilk: 'hot milk',
  coolMilk: 'cold milk',
  boiledFoam: 'hot foam',
  coolFoam: 'cold foam',
  drinkLibraryImport: 'drink library import',
  cover: 'lid',
  leftPowerBox: 'left powder',
  middlePowerBox: 'middle powder',
  rightPowerBox: 'right powder',
  water: 'water',
  delay: 'delay',
  priceTip: 'Tip: Keep up to two decimal places',
  drinkImport: 'Drink Import',
  importSuccess: 'Import successful!',
  hotSell: 'Hot Selling',
  oneKeyImport: 'One-Key Import',
  selectedImport: 'Selected Import',
  notifyDevicePullSuccess: 'Notification device update successful!',
  notifyDevicePull: 'Synchronization',
  clearDeviceDrinks: 'Clear Drinks',
  clearCustomerDrinks: 'Clear Drinks',
  clearGroupDrinks: 'One-click clear',
  OneKeySyncDevice: 'Synchronize devices with one click',
  chooseCompany: 'Choose Company',
  uploadOnlyType: 'Only jpg, png, bmp formats are supported!',
  afterTampingPressure: 'Post-tamping pressure',
  preCookWater: 'Pre-brewing water amount',
  tampingPress: 'Tamping force',
  watchwxApplyStatus: 'Check Application Progress',
  watchwxApplyStatusTips: 'Please use WeChat scan by Super Administrator to complete the signing confirmation',
  superRoleCanNotDelete: 'Super Administrator account cannot be deleted!',
  delHistroyOrder: 'Delete Historical Orders',
  isForceUpdate: 'Forced update',
  import: 'Import',
  downloadTemplate: 'Download Template',
  confirmImport: 'Confirm Import',
  pleaseUseTemplate: 'Please use the template!',
  getEnterpriseList: '1: Get distributor information',
  bindMachine: '2: Bind Machine',
  deliveryListLabel: 'Distributor List:',
  pleaseChooseDelivery: 'Please choose a distributor',
  deliveryNameLabel: 'Distributor Name:',
  deliveryUuidLabel: 'Distributor UUID',
  deviceQrCode: 'Device QR Code:',
  pleaseScanTrueCode: 'Please scan the correct barcode!',
  scanImeiNotFoundTip: 'The current IMEI cannot find device information. Please confirm if the current IMEI is correct!',
  scanNoneMsgTip: 'Please scan the barcode to get device information!',
  scanNotFoundDeviceTip: 'This device information does not exist in the system. Please contact the administrator!',
  scanTip: 'Get device information and assign it to the corresponding distributor based on the scanned barcode',
  longTime: 'Long-term',
  pleaseWriteIdCardInfo: 'Please fill in the end date of the ID card',
  waitWXCheck: 'Waiting for WeChat review',
  enterpriseUuidLabel: 'Distributor UUID:',
  pleaseWriteEnterpriseUuid: 'Please enter the second-generation distributor UUID',
  getEnterpriseUuidMethod: 'Method to obtain second-generation distributor UUID:',
  payNameLabel: 'Payment Name:',
  pleaseWritePayName: 'Please enter the payment name',
  bindV2PaySuccess: 'Bind second-generation payment successfully!',
  exampleUrl: 'Example:',
  mixSpeed: 'Mixed data',
  ae384efa8401000: 'Dealer Creation',
  ae384efaa401000: 'Dealer Verification',
  ae384efaf401000: 'Dealer Viewing',
  ae384efb4001000: 'Modify Company Information',
  ae384efb6001000: 'Member Deletion',
  ae384efbd001000: 'Customer Creation',
  ae384efbf001000: 'Customer Viewing',
  ae384efc1801000: 'Customer Management',
  ae384efc3401000: 'Customer Deletion',
  ae384efc6c01000: 'Dealer Equipment Management',
  ae384efc8001000: 'Equipment Viewing',
  ae384efca401000: 'Equipment Information Update',
  ae384efcc801000: 'Equipment Beverage Management',
  ae384efcf001000: 'Beverage Model Creation',
  ae384efd1001000: 'Beverage Model Management',
  ae384efd3401000: 'Beverage Model Deletion',
  ae384efd6801000: 'Production List Viewing',
  ae384efd8401000: 'Order Refund',
  ae384efda801000: 'Model Creation',
  ae384efdc801000: 'Model Viewing',
  ae384efdf001000: 'Model Version Management',
  ae384efe2401000: 'Model Beverage Model',
  ae384efe4001000: 'Member Permissions Viewing',
  ae384efe6001000: 'Member Permissions Management',
  ae384efe8401000: 'Member Permissions Deletion',
  ae384efea401000: 'Member Viewing',
  ae384efed001000: 'Member Addition',
  ae384efef801000: 'Material List',
  ae3a3c910401000: 'Enterprise Module Activation',
  ae784feb3c01000: 'Dealer Member Management',
  aec63047dc01000: 'Model Material Management',
  aedc9e30a001000: 'Daily Cleaning Viewing',
  aedc9e30d401000: 'Daily Cleaning Management',
  aedc9e30d801000: 'Daily Cleaning Deletion',
  aee126563001000: 'Cleaning Synchronize Equipment',
  aef933609801000: 'Equipment Daily Cleaning Settings',
  aef933611801000: 'Equipment Automatic Power On/Off Settings',
  af0959ef8c01000: 'Automatic Power On/Off Management',
  af0959f02001000: 'Synchronize Equipment',
  af0959f08801000: 'Delete Information',
  af0959f14001000: 'Exception Viewing',
  af0959f18401000: 'Exception Export',
  af0968d16c01000: 'Maintenance Export',
  afad5317d001000: 'Machine Settings',
  afedc198a801000: 'View Beverages',
  afedc828e001000: 'Update Beverage',
  afedc9383c01000: 'Delete Beverage',
  b0a5ccbef001000: 'Import Beverages',
  b2a7f87e0801000: 'Enterprise Payment Configuration',
  b2a7f87e0802000: 'Production Line Device Binding',

}
