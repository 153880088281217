export default {
    successTip: '保存成功',
    warningTip: '警告',
    sureBtn: '确认',
    cancelBtn: '取消',
    editBtn: '编辑',
    delete: '删除',
    opr: '操作',
    que_ding: '确定',
    qu_xiao: '取消',
    ti_shi: '提示',
    fan_hui: '返回',
    cao_zuo: '操作',
    shi_fou_ji_xu_shan_chu: '是否继续删除?',
    welcome_to_you: '欢迎你',
    company_number: '公司编号',
    safe_quit: '安全退出',
    shan_chu_ti_shi: '删除提示',
    forget_password_tip: '忘记密码请联系管理员进行修改',
    refresh: '刷新',
    refreshSuccess: '刷新成功!',
    empty_info: '没有对应的信息!',
    errorHandleWay:'异常解决列表',
    yes:'是',
    no:'否',
    deviceErrorList:'设备异常',
    continueTip:'确认提示',
    drinkPicUploadTip:'饮品图片上传仅支持png格式!',

    dashboard:{
        router:'大屏',
        coffeeConsumeToday:'咖啡豆消耗情况',
        dataOperate:'数据运营',
        coffeeConsumePercent:'咖啡消耗占比',
        deviceRunStatus:'设备运行状态',
        eachFloorConsumeCups:'各楼层咖啡消耗杯数',
        blackMilkCoffeePercent:'黑咖奶咖占比杯数',
        drinkTop:'饮品榜单',
        machineCupTop:'咖啡机出杯量榜单',
        onlineDevice:'在线设备',
        offlineDevice:'离线设备',
        saveSetting:'保存设置',
        preview:'预览',
        onlyUploadMp4:'只能上传mp4格式的视频',
        clickUpload:'点击上传视频',
        floor:'楼层/序号',
        location:'地点',
        status:'状态',
        show:'显示',
        redLight:'红灯',
        greenLight:'绿灯',
        yellowLight:'黄灯',

    },


    menus: {
        login: '登录'
    },
    language: {
        China: '中文',
        English: '英文',
    },
    message: {
        Home: '首页',
        SystemManage: '系统管理',
        RouterManage: '路由管理'
    },
    upload: {
        fileAlreadyUpload: '文件已上传',
        fileTip: '将文件拖到此处，或',
        clickUpload: '点击上传'
    },
    login: {
        chooseLanguage: '选择语言',
        language: '中文',
        login_entry: '系 统 登 录',
        deng_lu: '登录',
        huan_ying_shi_yong: '欢迎使用',
        wang_ji_mi_ma: '忘记密码',
        TB_tip: '技术助力商业发展',
        qing_shu_ru_shou_ji_hao_ma: '请输入手机号码',
        qing_shu_ru_deng_lu_mi_ma: '请输入登录密码',
        shou_ji_hao_ma: '手机号码',
        mi_ma: '密码',
        ji_zhu_mi_ma: '记住密码',
        passwordLogin: '密码登录',
        checkLogin: '验证码登录',
    },
    customer: {
        addCustomer: '新增客户',
        pleaseEnterName: '请输入客户名称',
        xu_hao: '序号',
        bian_hao: '编号',
        ming_cheng: '名称',
        ke_hu_ming_cheng: '客户名称',
        qing_shu_ru_ke_hu_ming_cheng: '请输入客户名称',
        bian_ji_ke_hu: '编辑客户',
        geng_xin_ke_hu_xin_xi_cheng_gong: '更新客户信息成功!',
        xin_zeng_ke_hu: '新增客户',
        xin_zeng_ke_hu_cheng_gong: '新增客户成功!',
        yin_pin_pei_zhi: '饮品配置',
        customerSwtich:'客户开关',
        customerBalance:'客户余额',
        customerSwtichLabel:'客户开关：',
        customerBalanceLabel:'客户余额：',
        distributeBalance:'分配余额',
        distributeBalanceTip:'分配余额前，请打开客户开关!',

    },
    group: {
        addCustomer: '新增分组',
        pleaseEnterName: '请输入分组名称',
        xu_hao: '序号',
        bian_hao: '编号',
        ming_cheng: '名称',
        ke_hu_ming_cheng: '分组名称',
        qing_shu_ru_ke_hu_ming_cheng: '请输入分组名称',
        bian_ji_ke_hu: '编辑分组',
        geng_xin_ke_hu_xin_xi_cheng_gong: '更新分组信息成功!',
        xin_zeng_ke_hu: '新增分组',
        xin_zeng_ke_hu_cheng_gong: '新增分组成功!',
        yin_pin_pei_zhi: '饮品配置',
        customerSwtich:'客户开关',
        customerBalance:'客户余额',
        customerSwtichLabel:'客户开关：',
        customerBalanceLabel:'客户余额：',
        distributeBalance:'分配余额',
        distributeBalanceTip:'分配余额前，请打开客户开关!',
        updatedAt:'最近更新时间',
        addStepOne:'1：基本信息',
        addStepTwo:'2：饮品初始化',
        addStepThree:'3：同步设备',
        chooseProductType:'所属产品：',
        enterGroupName:'分组名称：',
        importDrinks:'导入饮品：',
        drinkSetting:'饮品设置：',
        drinks:'饮品',
        powder:'粉料',
        powderSetting:'粉料设置:',
        powderType:'粉料类型:',
        importCustomerDevice:'导入客户设备',
        selectDevice:'选取设备',
        isSelectedDevceis:'已选设备',
        drinkImage:'饮品图片',
        version:'版本',
        isDefault:'是否默认',
        isVisible:'是否可见',
        updateAt:'更新时间',
        createAt:'创建时间',
        defaultOne:'默认',
        defaultTwo:'非默认',
        isVisibleOne:'可见',
        isVisibleTwo:'不可见',
        saveCopy:'保存副本',
        saveCopySuccess:'保存副本成功!',
        downloadParam:'下载参数',


    },
    department: {
        addCustomer: '新增分组',
        pleaseEnterName: '请输入分组名称',
        xu_hao: '序号',
        bian_hao: '编号',
        ming_cheng: '名称',
        ke_hu_ming_cheng: '分组名称',
        qing_shu_ru_ke_hu_ming_cheng: '请输入分组名称',
        bian_ji_ke_hu: '编辑分组',
        geng_xin_ke_hu_xin_xi_cheng_gong: '更新分组信息成功!',
        xin_zeng_ke_hu: '新增分组',
        xin_zeng_ke_hu_cheng_gong: '新增分组成功!',
        yin_pin_pei_zhi: '饮品配置',
        customerSwtich:'客户开关',
        customerBalance:'客户余额',
        customerSwtichLabel:'客户开关：',
        customerBalanceLabel:'客户余额：',
        distributeBalance:'分配余额',
        distributeBalanceTip:'分配余额前，请打开客户开关!',
        updatedAt:'最近更新时间',
        addStepOne:'1：基本信息',
        addStepTwo:'2：饮品初始化',
        addStepThree:'3：同步设备',
        chooseProductType:'所属产品：',
        enterGroupName:'分组名称：',
        importDrinks:'导入饮品：',
        drinkSetting:'饮品设置：',
        drinks:'饮品',
        powder:'粉料',
        powderSetting:'粉料设置:',
        powderType:'粉料类型:',
        importCustomerDevice:'导入客户设备',
        selectDevice:'选取设备',
        isSelectedDevceis:'已选设备',
        drinkImage:'饮品图片',
        version:'版本',
        isDefault:'是否默认',
        isVisible:'是否可见',
        updateAt:'更新时间',
        createAt:'创建时间',
        defaultOne:'默认',
        defaultTwo:'非默认',
        isVisibleOne:'可见',
        isVisibleTwo:'不可见',
        saveCopy:'保存副本',
        saveCopySuccess:'保存副本成功!',
        downloadParam:'下载参数',
        parentDepartment:'上级分组',
        watchProve:'查看成员',


    },
    audit:{
        operateName:'操作人',
        operateTime:'操作时间',
        operateResult:'操作结果',
        operateType:'操作类型',
        operateModule:'操作模块',
        success:'成功',
        fail:'失败',
        pleaseEnterOperateName:'请输入操作人名称',
        pleaseSelectOperateType:'请选择操作类型',
        pleaseSelectOperateResult:'请选择操作结果',
        bind:'绑定',
        unbind:'解绑',
        create:'创建',
        update:'更新',
        delete:'删除',
        open:'打开',
        clear:'清空',
        fullUpdate:'全量更新',
        recoverDefault:'恢复默认',
        sync:'同步',
        callback:'回调',
        refund:'退款',
        pay:'支付',
        import:'导入',
        save:'保存',
        im:'实时通讯',
        register:'注册',
        replaceOperate:'代运营',
        login:'登录',
        notify:'通知',
        audit:'审核',
        query:'查询',
    },
    delivery: {
        xin_zeng_jing_xiao_shang: '新增经销商',
        qing_shu_ru_jing_xiao_shang_ming_cheng: '请输入经销商名称',
        jing_xiao_shang_bian_hao: '经销商编号',
        jing_xiao_shang_ming_cheng: '经销商名称',
        zui_da_ren_shu: '最大人数',
        zhang_hu_yu_e_yuan: '账户余额(元)',
        zhu_ce_shi_jian: '注册时间',
        cheng_yuan_hui_shou_zhan: '成员回收站',
        jing_xiao_shang_ming_cheng_label: '经销商名称:',
        deng_lu_shou_ji_hao: '登录手机号',
        deng_lu_mi_ma: '登录密码',
        qing_shu_ru_deng_lu_shou_ji_hao: '请输入登录手机号',
        qing_xuan_ze_jue_se: '请选择角色',
        jue_se:'角色',

        qing_shu_ru_deng_lu_mi_ma: '请输入登陆密码',
        dai_wei_yun_ying: '代为运营',
        qi_ye_ming_cheng_label: '企业名称:',
        xiang_qing: '编辑',
        shang_hu_zhi_fu_gong_neng: '商户支付功能:',
        open: '开',
        close: '关',
        forcePushVersiontToDevice: '确认绑定此版本?',

    },

    role: {
        gen_ju_yuan_gong_zhi_neng_xuan_ze_jue_se: '根据员工的职能选择角色，然后新增账号。可以自定义角色， 进行权限配置。',
        pei_zhi_quan_xian: '配置权限',
        gai_jue_se_mu_qian_yi_pei_zhi: '该角色目前已配置',
        ge_zhang_hao: '个账号。',
        cha_kan_quan_xian: '查看权限',
        xin_zeng_zhang_hao: '新增账号',
        guan_li_zhang_hao: '管理账号',
        jue_se_quan_xian_pei_zhi: '角色权限配置',
        jue_se_ming_cheng: '角色名称',
        shan_chu_gai_jue_se: '删除该角色',
        xuan_ze_quan_xian: '选择权限',
        zi_ding_yi_xin_zeng: '自定义新增',
        pei_zhi_zi_ding_yi_jue_se: '配置自定义角色，并在该角色下配置员工账号，灵活 管理商户平台权限。',
        xiu_gai_jue_se: '修改角色',
        xin_zeng_jue_se: '新增角色',
        cha_kan_zhang_hao: '查看账号',
        qing_shu_ru_jiao_se_ming_cheng: '请输入角色名称',
    },
    user: {
        xin_zeng_yuan_gong: '新增员工',
        xu_hao: '序号',
        bian_hao: '编号',
        ming_cheng: '名称',
        shou_ji_hao: '手机号',
        zhuang_tai: '状态',
        yuan_gong_tu_xiang: '员工图像',
        chuang_jian_shi_jian: '创建时间',
        yuan_gong_ming_cheng_label: '员工名称:',
        qing_shu_ru_yuan_gong_ming_cheng: '请输入员工名称',
        deng_lu_mi_ma_label: '登录密码:',
        qing_shu_ru_deng_lu_mi_ma: '请输入登陆密码',
        deng_lu_shou_ji_label: '登录手机:',
        qing_shu_ru_deng_lu_shou_ji: '请输入登录手机',
        yong_hu_zhuang_tai_label: '用户状态:',
        bian_ji_yuan_gong: '编辑员工',
        shen_qing_zhong: '申请中',
        shen_qing_ju_jue: '申请拒绝',
        zheng_chang_shi_yong: '正常使用',
        dong_jie: '冻结',
        yuan_gong_tu_pian_label: '员工图片:',
        shen_he_zhong: '审核中',
        shen_he_ju_jue: '审核拒绝',
        jin_yong: '禁用',
        geng_xin_yuan_gong_xin_xi: '更新员工信息!',
        xin_zneg_yuan_gong_cheng_gong: '新增员工成功!',
        hui_shou_zhan_lei_xing: '回收站类型',
        qing_xuan_ze_hui_shou_zhan_lei_xing: '请选择回收站类型',
        ming_cheng_label: '名称:',
        qing_shu_ru_ming_chneg: '请输入名称',
        hui_fu_shi_yong: '恢复使用',
        quan_ti_cheng_yuan: '全体成员',
        hui_shou_zhan_cheng_yuan: '回收站成员',
        zheng_chang_cheng_yuan: '正常成员',
        shi_fou_hui_fu_shi_yong: '是否恢复使用',
        hui_fu_shi_yong_cheng_gong: '恢复使用成功!',


    },

    card:{
        xu_hao:'序号',
        bian_hao:'编号',
        cardNo:'卡号',
        scanCardNo:'扫描卡号：',
        balance:'余额',
        createTime:'创建时间',
        registerCard:'注册卡号',
        delete:'注销',
        recharge:'充值',
        rechargeValue:'充值金额：',
        rechargeTime:'充值次数：',
        rechargeValueGreaterThanZero:'充值金额必须大于0!',
        rechargeSuccess:'充值成功!',
        scanTip:'注册IC卡:将未注册IC卡贴近刷卡设备进行注册!',
        cardNoNull:'卡号为空，请扫描IC卡获取卡号!',
        styleMustSelect:'请选择支付方式!',
        registerSucessful:'注册成功!',
        pleaseEnterCardNo:'请输入卡号',
        history:'明细',
        consume:'消费',
        historyType:'类型',
        price:'金额',
        paySylte:'计费方式',
        paySylteLabel:'计费方式：',

        caculateTime:'计次',
        caculateMoney:'计费',


    },

    system: {
        tiao_jian_sou_suo: '条件搜索',
        bian_ji: '编辑',
        shan_chu: '删除',
        cao_zuo: '操作',
        bang_ding_she_bei: '绑定设备',
        cha_kan_she_bei: '查看设备',
        paySetting: '支付设置',
        que_ding: '确定',
        qu_xiao: '取消',
        bao_cun: '保存',
        jie_chu_bang_ding: '解除绑定',
        qing_tian_xie_dui_ying_xin_xi: '请填写对应信息',
        qing_xuan_ze_xia_la_cai_dan_zhong_de_yin_hang: '请选择下拉菜单中的银行信息!',
        shan_chu_cheng_gong: '删除成功!',
        shi_fou_ji_xu_shan_chu: '是否继续删除?',
        ti_shi: '提示',
        shan_chu_ti_shi: '删除提示',
        bang_ding_she_bei_cheng_gong: '绑定设备成功!',
        tong_bu_she_bei_cheng_gong: '同步设备成功!',
        shi_fou_jie_bang_suo_xuan_she_bei: '是否解绑所选设备',
        qing_xuan_qu_xu_yao_bang_ding_de_she_bei: '请选取需要绑定的设备',
        qing_xuan_qu_xu_yao_jie_bang_de_she_bei: '请选取需要解绑的设备',
        jie_bang_she_bei_cheng_gong: '解绑设备成功!',
        zhi_fu_bao_zhi_fu: '支付宝支付',
        wei_xin_zhi_fu: '微信支付',
        bao_cun_she_zhi: '保存设置',
        geng_xin_cheng_gong: '更新成功',
        xin_zeng_cheng_gong: '新增成功',
        fen_pei_she_bei: '分配设备',
        zhi_fu_she_zhi: '支付设置',
        ti_jiao: '提交',
        tian_jia_cheng_gong: '添加成功!',
        ming_cheng: '名称',
        qing_shu_ru_ming_cheng: '请输入名称',
        jian_jie: '简介',
        qing_shu_ru_jian_jie: '请输入简介',
        complete_info: '请填写完整信息',
        jin_tian: '今天',
        zuo_tian: '昨天',
        zui_jin_yi_zhou: '最近一周',
        zui_jin_yi_yue: '最近一月',
        zui_jin_san_yue: '最近三月',
        fan_hui: '返回',
        dao_chu: '导出',
        dao_chu_cheng_gong: '导出成功!',
        shi_jian_fan_wei: '时间范围',
        zhi: '至',
        kai_shi_shi_jian: '开始时间',
        jie_shu_shi_jian: '结束时间',
        chuang_jian_shi_jian: '创建时间',
        xiu_gai_shi_jian: '修改时间',
        tong_bu_she_bei: '同步设备',
        tong_bu_she_bei_cheng_gong: '同步设备成功!',
        chuang_jian_cheng_gong: '创建成功!',
        xia_zai: '下载',
        quan_xuan: '全选',
        que_ren: '确认',
        chan_pin: '产品',
        xu_hao: '序号',
        bian_hao: '编号',
        jie_bang: '解绑',
        shi_fou_ji_xu_jie_bang: '是否继续解绑',
        jie_bang_cheng_gong: '解绑成功!',
        qing_xuan_qu_xu_yao_bang_ding_de_zhang_hao: '请选取需要绑定的账号',
        xin_zeng_zhang_hao_cheng_gong: '新增账号成功!',
        tui_chu_deng_lu: '退出登录',
        geng_xin: '更新',
        shang_chuan_cheng_gong: '上传成功!',
        shang_chuan_shi_bai: '上传失败!',
        bao_cun_cheng_gong: '保存成功!',
        cai_dan_shou_qi: '菜单收起',
        ci_gong_neng_wei_kai_fang: '此功能未开放!',
        bang_ding: '绑定',
        bang_ding_cheng_yuan: '绑定成员',
        bang_ding_cheng_gong: '绑定成功!',
        deviceExport: '设备导出',
        payEntranceTile:'1:充值入口',
        scanPay:'2:扫码支付',
        surplusAvailableLabel:'剩余可用:',
        numOfMchineLabel:'充值机器数:',
        rechargeDaysForSingleMachineLabel:'单台机器充值天数:',
        payEntranceTip:'充值天数或机器数不可为0',
        payCodeLabel:'支付二维码：',
        payNumLabel:'支付金额：',
        pass:'跳过',
        historyVersion:'变更历史',
        logoUrl:'Logo图片:',
        videoUrl:'大屏视频:',
        picUrl:'大屏图片:',


        log:{
            changeType:'变动类型',
            changeTypeOne:'系统扣除',
            changeTypeTwo:'分配客户',
            changeTypeThree:'客户返还',
            changeTypeFour:'充值',
            changeTypeFive:'系统派送',
            changeTypeLabel:'变动类型：',
            chargeAmount:'变动数量',
            chargeTime:'变动时间',
            chargeBeforeAmount:'变动前数量',
            chargeAfterAmount:'变动后数量',
            chargeRemark:'备注说明',
            pleaseChoosechangeType:'请选择变动类型',

        },

        balance:{
            deviceSn:'设备编号',
            deviceName:'设备名称',
            retainDay:'剩余天数',
            expireTime:'预计到期时间',
        },

        dict:{
            index:'字典编号',
            name:'字典名称',
            type:'字典类型',
            status:'状态',
            remark:'备注',
            createAt:'创建时间',
        
            data:{
                addData:'新增数据',
            }
        },

        wxpay: {
            wei_xin_pu_tong_shang_hu: '微信普通商户',
            wei_xin_zi_shang_hu: '微信子商户',
            gong_zhong_hao: '公众号',
            qing_shu_ru_gong_zhong_hao: '请输入公众号',
            shang_hu: '商户',
            shang_hu_hao: '商户号',
            qing_shu_ru_shang_hu_hao: '请输入商户号',
            qing_shu_ru_shang_hu: '请输入商户',
            shou_quan_zheng_shu: '授权证书',
            qing_dao_ru_shou_quan_zheng_shu: '请导入授权证书，如果不进行退款则不需要',
            shou_quan_mi_yao: '授权密钥',
            qing_dao_ru_shou_quan_mi_yao: '请导入授权密钥，如果不进行退款则不需要',
            shen_qing_bian_hao: '申请编号',
            zhang_hu_ming_cheng: '账户名称',
            shen_qing_zhuang_tai: '申请状态',
            chuang_jian_shi_jian: '创建时间',
            shen_qing_chuang_jian: '申请创建',
            xiu_gai_shen_qing_jin_du: '检测申请进度',
            xiu_gai_shen_qing_xin_xi: '修改申请信息',
            shan_chu: '删除',
            wei_shen_qing: '未申请',
            shen_qing_zhong: '申请中',
            shen_qing_cheng_gong: '申请成功',
            shen_qing_shi_bai: '申请失败',
            qi_ye_ji_ben_xin_xi: '企业基本信息',
            yin_hang_kai_hu_hang_xin_xi: '银行开户行信息',
            fa_ren_xin_xi: '法人信息',
            men_dian_di_zhi: '门店地址',
            lian_xi_ren_xin_xi: '联系人信息',
            qi_ye_ming_cheng_label: '企业名称:',
            qing_shu_ru_qi_ye_ming_cheng: '请输入企业名称',
            qualificationType: '主营业务',
            qi_ye_xing_zhi: '经营性质',
            qing_xuan_ze_jing_ying_xing_zhi: '请选择经营性质',
            ge_ren_shang_hu: '个人商户',
            qi_ye_shang_hu: '企业商户',
            licenseNumber: '注册号/统一社会信用代码',
            licenseCopyUrl: '营业执照照片',
            qing_shu_ru_tong_yi_xin_yong_dai_ma: '请输入统一信用代码',
            exampleImgLabel: '示例图:',
            uploadTips: '必须为彩色图片且小于500k，文件格式为jpg',
            qualifications: '食品许可证',
            next: '下一步',
            pre: '上一步',
            shen_fen_zheng_xing_ming: '身份证姓名',
            qing_shu_ru_shen_fen_zheng_xing_ming: '请输入身份证姓名',
            shen_fen_zheng_hao_ma: '身份证号码',
            qing_shu_ru_shen_fen_zheng_18_wei_hao_ma: '请输入身份证18位号码',
            shen_fen_zheng_you_xiao_qi: '身份证有效期',
            xuan_ze_ri_qi: '选择日期',
            yong_jiu: '永久',
            shen_fen_zheng_ren_xiang_mian_zhao_pian: '身份证人像面照片',
            shen_fen_zheng_guo_hui_zhao_pian: '身份证国徽面照片',
            uploadTips_2M: '必须为彩色图片且小于2M，文件格式为jpg',
            shang_pu_jian_cheng: '商铺简称',
            shang_pu_qu_yu: '商铺区域',
            shang_pu_xiang_xi_di_zhi: '商铺详细地址',
            dian_pu_men_tou_zhao: '店铺门头照',
            dian_pu_nei_bu_zhao: '店铺内部照',
            qing_shu_ru_shang_pu_jian_cheng: '请输入商铺简称',
            qing_shu_ru_shang_pu_xiang_xi_di_zhi: '请输入商铺详细地址',
            shang_pu_jian_cheng: '商户简称',
            qing_shu_ru_shang_hu_jian_cheng: '请输入商户简称，用于支付展示',
            ke_fu_dian_hua: '客服电话',
            qing_shu_ru_ke_fu_dian_hua: '请输入客服电话',
            guan_li_yuan_xing_ming: '管理员姓名',
            qing_shu_ru_guan_li_yuan_xing_ming: '请输入管理员姓名',
            guan_li_yuan_shen_fen_zheng: '管理员身份证',
            qing_shu_ru_guan_li_yuan_shen_fen_zheng: '请输入管理员身份证号',
            guan_li_yuan_shou_ji_hao: '管理员手机号',
            qing_shu_ru_guan_li_yuan_shou_ji_hao: '请输入管理员手机号',
            guan_li_yuan_you_xiang: '管理员邮箱',
            qing_shu_ru_guan_li_yuan_you_xiang: '请输入管理员邮箱，不填则采用系统默认邮箱',
            ti_jiao_shen_he: '提交申请',
            qualification_type_one: '餐饮',
            qualification_type_two: '食品生鲜',
            qualification_type_three: '零售',
            bank_type_one: '工商银行',
            bank_type_two: '交通银行',
            bank_type_three: '招商银行',
            bank_type_four: '民生银行',
            bank_type_five: '中信银行',
            bank_type_six: '浦发银行',
            bank_type_seven: '兴业银行',
            bank_type_eight: '光大银行',
            bank_type_nine: '广发银行',
            bank_type_ten: '平安银行',
            bank_type_eleven: '北京银行',
            bank_type_twelve: '华夏银行',
            bank_type_thirteen: '农业银行',
            bank_type_fourteen: '建设银行',
            bank_type_fifteen: '邮政储蓄银行',
            bank_type_sixteen: '中国银行',
            bank_type_seventeen: '宁波银行',
            bank_type_eighteen: '其他银行',
            chang_du_bu_shao_yu_three: '长度不少于3个字符',
            chang_du_bu_shao_yu_two: '长度不少于2个字符',
            chang_du_bu_shao_yu_one: '长度不少于1个字符',
            qing_xuan_qu_zhu_ying_ye_wu: '请选取主营业务',
            qing_shu_ru_zhu_ce_hao: '请输入注册号/统一社会信用代码',
            qing_shang_chuan_ying_ye_zhi_zhao: '请上传营业执照',
            qing_tian_xie_kai_hu_ming_cheng: '请填写开户名称',
            qing_xuan_qu_yin_hang: '请选取银行',
            qing_xuan_qu_yin_hang_qu_yu: '请选取银行区域',
            qing_tian_xie_zhi_hang_ming_cheng: '请选取支行名称',
            qing_tian_xie_yin_hang_zhang_hao: '请填写银行账号',
            qing_tian_xie_shang_pu_jian_cheng: '请填写商铺简称',
            qing_tian_xie_shang_pu_xiang_xi_di_zhi: '请填写商铺详细地址',
            qing_xuan_qu_shang_pu_qu_yu: '请选取商铺区域',
            qing_shang_chuan_men_tou_zhao: '请上传门头照',
            qing_shang_chuan_dian_nei_zhao: '请上传店内照',
            qing_tian_xie_shang_hu_jian_cheng: '请填写商户简称',
            qing_tian_xie_ke_fu_dian_hua: '请填写客服电话',
            qing_shu_ru_shou_ji_hao: '请输入手机号',
            shou_ji_hao_ge_shi_bu_dui: '手机号格式不对',
            qing_tian_xie_kai_hu_ming_cheng: '请填写开户名称',
            qing_tian_xie_zheng_jian_hao_ma: '请填写证件号码',
            zheng_jian_hao_ma_ge_shi_you_wu: '证件号码格式有误！',
            qing_tian_xie_fa_ren_ming_cheng: '请填写法人名称',
            qing_tian_xie_shen_fen_zheng_you_xiao_qi: '请填写身份证有效期',
            qing_shang_chuan_shen_fen_zheng_ren_xiang_mian_zhao_pian: '请上传身份证人像面照片',
            qing_shang_chuan_shen_fen_zheng_guo_hui_mian_zhao_pian: '请上传身份证人像面照片',
            kai_hu_ming_cheng: '开户名称',
            qing_shu_ru_gong_si_ge_ren_kai_hu_ming_cheng: '请输入公司/个人开户名称',
            kai_hu_yin_hang: '开户银行',
            kai_hu_yin_hang_sheng_shi: '开户银行省市',
            kai_hu_zhi_hang_quan_cheng: '开户支行全称',
            qing_shu_ru_kai_hu_zhi_hang_quan_cheng: '输入开户支行全称，如中国工商银行股份有限公司北京市分行营业部',
            yin_hang_zhang_hao: '银行账号',
            qing_shu_ru_yin_hang_zhang_hao: '请输入银行账号',
            APPLYMENT_STATE_FINISHED:'商户入驻申请已完成。',
            APPLYMENT_STATE_CANCELED:'申请单已被撤销。',
            APPLYMENT_STATE_SIGNING:'系统开通相关权限中，请耐心等待。',
            APPLYMENT_STATE_TO_BE_SIGNED:'请超级管理员使用微信打开返回的“签约链接”，根据页面指引完成签约。',
            APPLYMENT_STATE_TO_BE_CONFIRMED:'请超级管理员使用微信打开返回的“签约链接”，根据页面指引完成账户验证。',
            APPLYMENT_STATE_REJECTED:'请按照驳回原因修改申请资料，超级管理员用微信打开“签约链接”，完成绑定微信号，后续申请单进度将通过微信公众号通知超级管理员。',
            APPLYMENT_STATE_AUDITING:'申请单正在审核中，超级管理员用微信打开“签约链接”，完成绑定微信号后，申请单进度将通过微信公众号通知超级管理员，引导完成后续步骤。',
            APPLYMENT_STATE_EDITTING:'提交申请发生错误导致，请尝试重新提交。',









        },

        alipay: {
            ce_shi_zhi_fu: '测试支付',
            ce_shi_zhi_fu_label: '测试支付:',
            zhi_fu_bian_hao_label: '支付编号:',
            zhi_fu_zhuang_tai_label: '支付状态:',
            dao_qi_shi_jian_label: '到期时间:',
            zhi_fu_shou_quan_ma: '支付授权码',
            createAuthorizedQrCode: '创建授权二维码',
            produceAuthorizedQrCode: '产生授权二维码',
            dang_mian_fu_shou_quan_ma_label: '当面付授权码',
            deng_dai_shou_quan: '等待授权',
            yi_shou_quan: '已授权',
            shen_qing_zhong: '申请中',
            wu_xiao_er_wei_ma: '无效二维码',
            zan_wu_bian_hao: '暂无编号',
            shan_chu_zhi_fu: '删除支付',
            bang_ding_er_dai_zhi_fu: '绑定二代支付',


        }


    },

    order: {
        ding_dan_bian_hao: '订单编号',
        qing_shu_ru_ding_dan_bian_hao: '请输入订单编号',
        ming_cheng_sou_suo: '名称搜索',
        qing_shu_ru_she_bei_ming_cheng: '请输入设备名称',
        bian_hao_sou_suo: '编号搜索',
        qing_shu_ru_she_bei_bian_hao: '请输入设备编号',
        qing_xuan_ze_zhi_fu_fang_shi: '请选择支付方式',
        qing_xuan_ze_zhi_zuo_lei_xing: '请选择制作类型',
        zhi_zuo_zhuang_tai: '制作状态',
        zhi_zuo_shi_jian: '制作时间',
        jie_shu_ri_qi: '结束日期',
        zhi: '至',
        kai_shi_ri_qi: '开始日期',
        order_number: '系统编号',
        pay_number: '支付编号',
        she_bei_ming_cheng: '设备名称',
        she_bei_bian_hao: '设备编号',
        yin_pin_ming_cheng: '饮品名称',
        yin_pin_bei_liang: '饮品杯量',
        ding_dan_zhuang_tai: '订单状态',
        zhi_fu_jin_e: '支付金额',
        zhi_fu_fang_shi: '支付方式',
        zhi_zuo_lei_xing: '制作类型',
        tui_kuan_zhuang_tai: '退款状态',
        ding_dan_shi_jian: '订单时间',
        ding_dan_tui_kuan: '订单退款',
        suo_you_zhi_fu: '所有支付',
        wu_zhi_fu: '无支付',
        zhi_fu_bao: '支付宝',
        wei_xin_zhi_fu: '微信支付',
        su_zhou_nong_hang: '苏州农行',
        suo_you_ding_dan: '所有订单',
        ce_shi_ding_dan: '测试订单',
        wu_zhi_fu_ding_dan: '无支付订单',
        zhi_fu_ding_dan: '支付订单',
        all: '全部',
        make_success: '制作成功',
        make_fail: '制作失败',
        chu_shi_zhuang_tai: '初始状态',
        yi_zhi_fu: '已支付',
        yi_tui_kuan: '已退款',
        zhi_zuo_zhong: '制作中',
        cheng_gong: '成功',
        zhi_zuo_shi_bai: '制作失败',
        xiao_bei: '小杯',
        zhong_bei: '中杯',
        da_bei: '大杯',
        tui_kuan_cheng_gong: '退款成功',
        ci_cao_zuo_jiang_hui_tui_huan_ding_dan_jin_e: '此操作将会退还订单金额, 是否继续?',
        bei_xing_bai_fen_bi:'杯型百分比(%)',
        exportExcelTip:'订单导出时间最大可选范围为半年!',
        tempratureLabel:'萃取温度:',
        extractionTimeLabel:'萃取时间:',
        drinkInfoTitle:'饮品详情',
        isCancel:'是否取消制作:',
        steamVol:'蒸汽值:',
        waterVol:'咖啡量:',
        pieNum:'渣饼数:',
        powderWeight:'咖啡粉总克重:',
        makeStartAt:'制作开始时间:',
        makeEndAt:'制作结束时间:',

    },

    product: {
        xin_zeng_chan_pin: '新增产品',
        qing_shu_ru_chan_pin_ming_cheng: '请输入机型名称',
        bian_hao: '编号',
        ji_xing_ming_cheng: '机型名称',
        chan_pin_ming_cheng: '产品名称',
        chan_pin_ming_cheng_label: '产品名称',
        chan_pin_ying_wen_ming_cheng: '产品英文名称',
        chan_pin_bei_zhu: '产品备注',
        qing_shu_ru_chan_pin_ying_wen_ming_cheng: '请输入产品英文名称',
        qing_shu_ru_chan_pin_bei_zhu: '请输入产品备注',
        chan_pin_bian_hao_label: '产品编号:',
        shang_chuan_xin_ban_ben: '上传新版本',
        mo_ren_fa_bu_ke_jiang_wu_ban_ben_she_bei_bang_ding_mo_ren_ban_ben: '默认发布可将无版本设备绑定默认版本',
        ban_ben_lie_biao: '版本列表',
        ban_ben_hao: '版本号',
        mo_ren: '默认',
        ruan_jian_ban_ben_hao: '软件版本号',
        miao_shu_xin_xi: '描述信息',
        chuang_jian_shi_jian: '创建时间',
        she_wei_mo_ren: '设为默认',
        shan_chu_ban_ben: '删除版本',
        she_bei_quan_liang_geng_xin: '设备全量更新',
        shang_chuan_ban_ben: '上传版本',
        ban_ben_xin_xi: '版本信息',
        ban_ben_shuo_ming: '版本说明',
        qing_xuan_qu_chan_pin: '请选取产品',
        wen_jian_qian_ming_cuo_wu: '文件签名错误',
        qing_shang_chuan_wen_jian: '请上传文件',
        qing_tian_xie_miao_shu: '请填写描述',
        shang_chuan_cheng_gong: '上传成功!',
        she_ding_cheng_gong: '设定成功!',
        she_bei_quan_liang_geng_xin_cheng_gong: '设备全量更新成功!',
        zhi_yun_xu_mo_ren_ban_ben_quan_liang_geng_xin: '只允许默认版本全量更新!',
        mo_ren_ban_ben_bu_yun_xu_shan_chu: '默认版本不允许删除',
        ci_cao_zuo_jiang_shan_chu_zhi_ding_ban_ben: '此操作将删除指定版本, 是否继续?',
        qing_xuan_qu_she_bei: '请选取设备',
        fen_pei_cheng_gong: '分配成功!',
        qing_shu_ru_chan_pin_can_shu: '请输入产品参数',
        drinkModelSetting:'饮品模型配置',
        name:'名称',
        color:'颜色',
        makeType:'制作类型',
        childMakeType:'制作子类型',
        canTransferMaterial:'是否转换物料',
        stepEdit:'步骤域编辑',
        modelStep:'模型步骤',
        addModelItem:'新增模型',
        nameLabel:'名称：',
        colorLabel:'颜色：',
        makeTypeLabel:'制作类型：',
        childMakeTypeLabel:'制作子类型：',
        canTransferMaterialLabel:'是否转换物料：',
        modelEidt:'模型编辑',
        modelAdd:'模型新增',
        stepName:'栏目名称识别符',
        canEdit:'能否编辑',
        minValue:'范围最小值',
        maxValue:'范围最大值',
        defaultValue:'默认值',
        stepValue:'递增步长',
        filedType:'栏目数据类型',
        sendType:'数据单位转换类型',
        Hidden:'制作时是否隐藏',
        Options:'可选项',
        drinkModelEdit:'机型饮品模型',
        notEditDrinkModel:'没有配置对应的饮品的模型!',
        pleaseEnterModelName:'请输入步骤名称',
        pleaseEnterMakeType:'请输入制作类型',
        pleaseEnterchildMakeType:'请输入制作子类型',
        addModelStep:'新增步骤域',
        editModelStep:'编辑步骤域',
        radius:'范围',
        filedLabel:'栏目名称识别符:',
        minValueLabel:'范围最小值:',
        maxValueLabel:'范围最大值:',
        valueLabel:'默认值:',
        offsetLabel:'偏移值:',
        scaleLabel:'数据放大倍数:',
        stepLabel:'递增步长:',
        fieldTypeLabel:'栏目数据类型:',
        itemTypeLabel:'栏目显示类型:',
        hiddenLabel:'制作时是否隐藏:',
        optionsLabel:'可选项:',
        filedLabelTip:'请输入栏目名称识别符',
        minValueLabelTip:'请输入范围最小值',
        maxValueLabelTip:'请输入范围最大值',
        stepLabelTip:'请输入递增步长',
        valueLabelTip:'请输入默认值',
        scaleLabelTip:'请输入数据放大倍数',
        fieldTypeLabelTip:'请输入栏目数据类型',
        itemTypeLabelTip:'请输入栏目显示类型',
        bindMolde:'绑定',
        fieldOptionLabel:'标签',
        fieldOptionValue:'值',
        addOptionItem:'新增可选项',
        pleaseEnterLabel:'请输入标签',
        pleaseEnterValue:'请输入值',
        isShowMaterialLabel:'是否显示粉料:',
        show:'显示',
        notShow:'不显示',
        typeLabel:'拓展数据类型:',
        replaceVlaue:'替换对应可选值',
        directDisplay:'直接显示',
        pleaseEnterOptionData:'请输入拓展性',
        isShowMaterial:'是否显示粉料',
        type:'拓展数据类型',
        options:'可选项',
        bindModel:'绑定模型',
        nextVersion:'下一版本',
        productVersionSelect:'版本库导入',
        pleaseSelectNextVersion:'请选择下一版本:',
        maintainSetting:'绑定清洗配置',


    },

    material: {
        yuan_liao_fen_lei: '原料分类',
        zi_ding_yi_xin_zeng: '自定义新增',
        ji_xing_yuan_liao: '机型原料',
        yuan_liao_bu_chong:"原料补充",
        xuan_ze_fen_lei_label: '选择分类:',
        xuan_ze_fen_lei: '选择分类',
        ming_cheng_label: '名称:',
        qing_shu_ru_ming_cheng: '请输入名称',
        ming_cheng_ying_wen_label: '名称(英文):',
        qing_shu_ru_ming_cheng_ying_wen: '请输入名称(英文)',
        qing_xuan_ze_yuan_liao_fen_lei: '请选择原料分类',
        xin_zeng_yuan_liao: '新增原料',
        xiu_gai_yuan_liao: '修改原料',
        shan_chu_fen_lei_cheng_gong: '删除分类成功!',
        xin_zeng_fen_lei_cheng_gong: '新增分类成功!',
        geng_xin_fen_lei_cheng_gong: '更新分类成功!',

        ji_xing_ming_cheng: '机型名称',
        xiang_guan_yuan_liao: '相关原料',
        tian_ru_yuan_liao: '填入原料',
        xuan_ze_yuan_liao_label: '选择原料:',
        xuan_ze_yuan_liao: '选择原料',
        leftPowderBox: '左粉盒',
        middlePowderBox: '中粉盒',
        rightPowderBox: '右粉盒',
        qing_xuan_ze_tian_ru_de_yuan_liao: '请选择填入的原料',
        tian_ru_yuan_liao_cheng_gong: '填入原料成功',
        bu_chong_lei_xing:'补充类型',
        bu_chong_shi_jian:'补充时间',
        bu_chong_shu_liang:'补充数量',
        she_bei_ming_cheng:'设备名称',
        qing_xuan_ze_bu_chong_lei_xing:'请选择补充类型',
        qing_shu_ru_she_bei_ming_cheng:'请输入设备名称',
        qing_shu_ru_bu_chong_shu_liang:'请输入补充数量',


    },

    device: {
        suo_shu_chan_pin: '所属产品',
        qing_xuan_qu_dui_ying_chan_pin: '请选取对应的产品',
        suo_shu_ke_hu: '所属客户',
        qing_xuan_qu_dui_ying_de_ke_hu: '请选取对应的客户',
        qing_xuan_ze_she_bei_zhuang_tai: '请选择设备状态',
        ming_cheng_sou_suo: '名称搜索',
        qing_shu_ru_she_bei_ming_chen: '请输入设备名称',
        bian_hao_sou_suo: '编号搜索',
        qing_shu_ru_she_bei_bian_hao: '请输入设备编号',
        she_bei_bian_hao: '设备编号',
        she_bei_ming_cheng: '设备名称',
        imei: 'IMEI',
        qing_shu_ru_she_bei_imei: '请输入设备IMEI',
        chan_pin_ming_cheng: '产品名称',
        she_bei_zhuang_tai: '设备状态',
        she_bei_wei_zhi: '设备位置',
        zan_wei_she_zhi_de_li_wei_zhi: '暂未设置地理位置',
        qi_yong_zhuang_tai: '启用状态',
        qi_yong_zhuang_tai_label: '启用状态:',
        she_bei_xiang_qing: '设备详情',
        yin_pin_pei_zhi: '饮品配置',
        qi_yong: '启用',
        ting_yong: '停用',
        li_xian: '离线',
        zai_xian: '在线',
        quan_bu: '全部',
        qing_wan_shan_she_bei_ming_cheng: '请完善设备名称',
        qing_she_ding_she_bei_suo_zai_wei_zhi: '请设定设备所在位置',
        que_ren_ting_yong_gai_she_bei: '确认停用该设备?',
        que_ren_qi_yong_gai_she_bei: '确认启用该设备?',
        ming_cheng_label: '名称:',
        chan_pin: '产品',
        bian_ji_xin_xi: '设备编辑',
        she_bei_la_qu: '设备拉取',
        she_bei_la_qu_cheng_gong: '设备拉取成功!',
        bindTime: '绑定时间',
        runStatusZero:'关机',
        runStatusOne:'待运行',
        runStatusTwo:'运行中',
        runStatusThree:'异常待处理',
        runStatusFour:'故障停机',
        runStatus:'运行状态',
        runStatusOfflineTip:'设备离线，无法查看故障原因!',
        runStatusLabel:'运行状态:',
        bindSnAndImei:'重新绑定',


        detail: {
            ji_ben_xin_xi: '基本信息',
            she_bei_ming_cheng: '设备名称',
            she_bei_xing_hao: '设备型号',
            she_bei_xu_lie_hao: '设备序列号',
            she_bei_ban_ben_hao: '设备版本号',
            she_bei_mo_kuai: '设备模块',
            SIM_ka_hao: 'SIM卡号',
            she_bei_zhuang_tai: '设备状态',
            suo_zai_di_qu: '所在地区',
            xiang_xi_di_zhi: '详细地址',
            qing_shu_ru_xiang_xi_di_zhi: '请输入详细地址',
            yun_ying_she_zhi: '运营设置',
            ri_chang_qing_jie: '日常清洁',
            zi_dong_kai_guan_ji: '自动开关机',
            yi_chang_lie_biao: '异常列表',
            wei_hu_ming_xi: '维护明细',
            yun_xing_ji_lu: '运行记录',
            ji_qi_she_zhi: '机器设置',
            she_bei_su_cai: '设备素材',
            province: '省份',
            city: '城市',
            addressLatAndLng:'经纬度信息',
            versionTip:'检测有新版本,点击强制更新!',
            remoteClean:'远程清洗',

        },


        clean: {
            qing_shu_ru_ming_cheng: '请输入名称',
            xin_zeng_qing_jie: '新增清洁',
            bian_ji_qing_jie: '编辑清洁',
            niu_nai_shen_du_qing_xi: '牛奶系统深度清洗',
            chong_zhu_xi_tong_shen_du_qing_xi: '冲煮系统深度清洗',
            fen_liao_xi_tong_shen_du_qing_xi: '粉料系统深度清洗',
            chu_fa_shi_jian_label: '触发时间:',
            qing_xuan_ze_chu_fa_shi_jian: '请选择触发时间',
            shi_jian_she_zhi: '时间设置',
            geng_xin_she_zhi: '更新设置',
            monday: '周一',
            tuesday: '周二',
            wednesday: '周三',
            thursday: '周四',
            friday: '周五',
            saturday: '周六',
            sunday: '周日',
            shan_chu_wei_hu_xin_xi_dui_ji_you_she_bei_bu_hui_chan_sheng_ying_xiang: '删除维护信息对既有设备不会产生影响，是否继续删除',
            mei_ri_bi_bei: '(每日必备)',
            mei_zhou_zhi_shao_yi_ci: '(每周至少一次)'


        },


        operate: {
            jin_ru_ping_bao_shi_jian: '进入屏保时间',
            qing_shu_ru_jin_ru_ping_bao_shi_jian: '请输入进入屏保时间',
            tui_chu_ping_bao_shi_jian: '退出屏保时间',
            qing_shu_ru_tui_chu_ping_bao_shi_jian: '请输入退出屏保时间',
            ping_bao_jian_ge_shi_jian: '屏保间隔时间',
            qing_shu_ru_ping_bao_jian_ge_shi_jian: '请输入屏保间隔时间',
            logo_change: 'LOGO更换',
            kai_ji_tu_pian_geng_huan: '开机图片更换',
            zhi_zuo_tu_pian_geng_huan: '制作图片更换',
            ping_bao_tu_pian_geng_huan: '屏保图片更换',
            su_cai_ku_dao_ru: '素材库导入',
            ben_di_shang_chuan: '本地上传',
            xin_zeng_tu_pian: '新增图片',
            geng_huan_tu_pian: '更换图片',
            xin_zeng_tu_pian_cheng_gong: '新增图片成功!',
            geng_xin_tu_pian_cheng_gong: '更新图片成功!',
            ti_shi_logo: '提示：点击图片进行更换。图片大小：250x48像素，图片格式：png',
            ti_shi_start: '提示：点击图片进行更换。图片大小：1024x600像素，图片格式：bmp',
            ti_shi_make_pic: '提示：点击图片进行更换。图片大小：620x540像素，图片格式：png',
            ti_shi_screen: '提示：点击图片进行更换。图片大小：1024x600像素，图片格式：png'

        },

        switch: {
            xin_zeng_kai_guan_ji: '新增开关机',
            yun_xing_dong_zuo: '运行动作',
            chu_fa_shi_jian: '触发时间',
            zhou_qi: '周期',
            qi_yong_zhuang_tai: '启用状态',
            chu_fa_shi_jian_label: '触发时间:',
            zhou_qi_label: '周期:',
            qing_xuan_ze_chu_fa_shi_jian: '请选择触发时间',
            qing_xuan_ze_zhou_qi: '请选择周期',
            zi_dong_guan_ji: '自动关机',
            zi_dong_kai_ji: '自动开机',
            bian_ji_kai_guan_ji: '编辑开关机',
            geng_xin_kai_guan_ji_xin_xi: '更新开关机信息成功!',
            xin_zeng_kai_guan_ji_cheng_gong: '新增开关机成功!',
            qing_xuan_ze_zhou_qi:'请选择周期!',

        },

        exception: {
            yi_chang_xiao_xi:'异常消息',
            yi_chang_shi_jian:'异常时间',
            yi_chang_ming_cheng: '异常名称',
            jie_jue_fang_shi:'处理方式',
            yi_chang_ming_cheng_label: '异常名称:',
            jie_jue_fang_shi_label:'处理方式:',
            qing_xuan_qu_yi_chang_ming_cheng: '请选取异常名称',
            yi_chang_lei_xing: '异常类型',
            qing_xuan_qu_yi_chang_lei_xing: '请选取异常类型',
            chi_xu_shi_jian: '持续时间',
            shi_jian_fan_wei: '时间范围',
            zhi: '至',
            kai_shi_shi_jian: '开始时间',
            jie_shu_shi_jian: '结束时间',
            yi_chang_bian_ma: '异常编码',
            yi_chang: '异常',
            gu_zhang: '故障',
            xiao_yu: '小于',
            deng_yu: '等于',
            da_yu: '大于',
            s: '秒',
            min: '分',
            hour: '时',
            cmd: {
                5020000: '蓄水盘未安装到位',
                5020001: '容渣盒已满',
                5020002: '水箱缺水',
                5020003: '蓄水盘水满',
                5020004: '桶装水缺水',
                5020005: '废水桶水满',
                5020006: '冲泡器未安装到位',
                5020007: '门未关闭到位',
                5020008: '左豆盒未安装到位',
                5020009: '右豆盒未安装到位',
                5020010: '豆盒3未安装到位',
                5020011: '左豆盒缺豆',
                5020012: '右豆盒缺豆',
                5020013: '豆盒缺豆',
                5020014: '左粉料盒未安装到位',
                5020015: '右粉料盒未安装到位',
                5020016: '中粉料盒未安装到位',
                5020017: '左粉料盒缺粉',
                5020018: '右粉料盒缺粉',
                5020019: '中粉料盒缺粉',
                5020020: '研磨机需要校验',
                5020021: '咖啡机需要除垢',
                5020022: '冲煮系统需要清洗',
                5020023: '冲泡器需要清洁',
                5020024: '牛奶系统需要清洗',
                5020025: '粉料系统需要清洗',
                5020026: '牛奶温度过低',
                5020027: '牛奶温度过高',
                5020028: '牛奶不足',
                5020029: '牛奶盒未安装',
                5020030: '系统缺水',
                5020031: '系统压力过大',
                5020032: '冲泡器自检',
                5020033: '分向阀自检',
                5020034: '环境温度过高',
                5020035: '环境温度过低',
                5020036: '咖啡锅炉温度过高',
                5020037: '蒸汽锅炉温度过高',
                5020038: '热水锅炉温度过高',
                5020039: '咖啡锅炉温度过低',
                5020040: '蒸汽锅炉温度过低',
                5020041: '热水锅炉温度过低',
                5020042: '系统补水超时',
                5020044: '蒸汽杆ntc异常',
                5020045: '滤芯需要更换',
                5020046: '牛奶系统需要冲洗',
                5020047: '粉料系统需要冲洗',
                5020048: '粉料锅炉温度过高',
                5020049: '粉料锅炉温度过低',
                5020050: '容渣盒未安装到位',
                5020051: '豆盒4未安装到位',
                5020052: '豆盒4缺豆',
                5020056: '粉料机门被打开',
                5020058: '设备需要一键保养',
                5030001: 'HMI通信异常',
                5030002: '咖啡锅炉加热超时',
                5030003: '蒸汽锅炉加热超时',
                5030004: '热水锅炉加热超时',
                5030005: '咖啡锅炉NTC异常',
                5030006: '蒸汽锅炉NTC异常',
                5030007: '热水锅炉NTC故障',
                5030008: '咖啡锅炉供水故障',
                5030009: '蒸汽锅炉供水故障',
                5030010: '热水锅炉供水故障',
                5030011: '冲煮系统异常',
                5030012: '分向阀系统异常',
                5030013: '磨豆机1异常',
                5030014: '磨豆机2异常',
                5030015: '磨豆机3异常',
                5030016: '冰箱系统通信异常',
                5030017: '蒸汽锅炉补水超时异常',
                5030018: '粉料锅炉加热超时',
                5030019: '粉料锅炉NTC异常',
                5030020: '粉料锅炉供水故障',
                5030021: '粉料系统通信异常',
                5030022: '磨豆机4异常',
                1020000: '设备网络断开',
                5030023: 'P30已断开',
                5020057: '饮品罩门未关闭',

            }

        },

        maintain: {
            wei_hu_lei_xing: '维护类型',
            qing_xuan_qu_wei_hu_lei_xing: '请选取维护类型',
            shi_jian_fan_wei: '时间范围',
            zhi: '至',
            kai_shi_shi_jian: '开始时间',
            jie_shu_shi_jian: '结束时间',
            qing_xi_he_ji: '清洗合计',
            chong_zhu_xi_tong_shen_du_qing_xi: '冲煮系统深度清洗',
            niu_nai_xi_tong_shen_du_qing_xi: '牛奶系统深度清洗',
            chu_gou: '除垢',
            yi_jian_bao_yang: '一键保养',
            qing_kong_shui_lu: '清空水路',
            she_bei_ming_cheng: '设备名称',
            chan_pin_xing_hao: '产品型号',
            she_bei_sn: '设备sn',
            wei_hu_bian_ma: '维护编码',
            cmd: {
                5010007: '除垢',
                5010006: '牛奶深度清洗',
                5010002: '牛奶快速冲洗',
                5010003: '粉料快速冲洗',
                5010005: '咖啡深度清洗',
                5010008: '清空水路',
                5010022: '一键清洗',
                5010035: '粉料深度清洗',
                5000011: '冲煮系统快速清洗',
                5010012: '研磨校准',
                5010013: '粉料校准',
            }
        },

        record: {
            shi_jian_lei_xing: '事件类型',
            qing_xuan_qu_shi_jian_lei_xing: '请选取事件类型',

        },
        material: {
            yin_pin_su_cai: '饮品素材',
            ping_bao_su_cai: '屏保素材',
            zhi_zuo_lun_bo_tu: '制作轮播图',
            xin_zeng_su_cai: '新增素材',
            xin_zeng_su_cai_cheng_gong: '新增素材成功!',
            yi_xuan_zhong: '已选中',
            ge_yin_pin_tu_pian: '个素材',
            ruo_su_cai_bei_zheng_chang_shi_yong: '若素材被正常使用，则无法删除，是否继续删除',
            pi_liang_xia_zai: '批量下载',
            qing_xuan_ze_xia_zai_su_cai: '请选择下载素材!',
            pleaseUploadFixedPic:'请根据上传图片类型上传对应尺寸的图片!'

        },
        setting: {
            s: '秒',
            cup: '杯',
            ml: '毫升',
            immediate: '立即',
            fiveMinLater: '五分钟后',
            tenMinLater: '十分钟后',
            fifteenMinLater: '十五分钟后',
            twentyMinLater: '二十分钟后',
            twoHourLater: '两小时后',
            lightBlue: '蓝',
            lightGreen: '绿',
            lightYellow: '黄',
            lightCyan: '青',
            lightPurple: '紫',
            lightWhite: '白',
            lightCycle: '循环',
            makeModeRefuseSet: '制作前不可调量',
            makeModeCanSet: '制作前可调量',
            paySetting: '支付设置',
            matainSet: '维护设置',
            diySetting: '个性化设置',
            functionSw: '功能开关',
            iotSw: 'IOT开关',
            paySw: '支付开关',
            scanPaySw: '扫码支付',
            mdbSw: 'MDB支付',
            coffeeQuickCleanSw: '咖啡快速冲洗开关',
            coffeeQuickCleanTs: '咖啡定时快速冲洗时间',
            deepCoffeeCleanLimit: '咖啡深度清洗杯数',
            powerQuickCleanTs: '粉料快速清洗时间',
            descale: '除垢开关',
            descaleCupLimit: '除垢杯量设定',
            milkPumpTs: '奶泵冲洗时间',
            milkQuickCleanTs: '牛奶快冲时间',
            deepMilkCleanLimit: '牛奶深度冲洗杯数',
            trashSw: '渣满开关',
            beanBoxFew: '缺豆开关',
            beanBox2Few: '右豆盒缺豆开关',
            leftPowerFew: '左粉料缺粉开关',
            midPowerFew: '中粉料缺粉开关',
            rightPowerFew: '右粉料缺粉开关',
            steamBoilerSwitch: '蒸汽锅炉开关',
            hotWaterSw: '热水开关',
            hotWaterVol: '热水值设定',
            drinkModeSelect: '饮品展示模式选择',
            makeModeSelect: '制作模式选择',
            screenBrigressVal: '屏幕亮度设定',
            decorateLightSw: '设备灯光开关',
            lightColor: '灯光颜色',
            lightCycleSec: '灯光切换间隔',
            businessSw: '运营设置同步开关',
            dailyCleanSw: '日常清洁同步开关',
            autoPowerSw: '自动开关机同步开关',
            drinkSw: '饮品同步开关',
            beanBox1Few: '左豆盒缺豆开关',
            closeFullCountTip: '因关闭此选项导致的冲泡系统故障，不在免费保修范围，建议开启此项功能。',
            closeBeanFewTip: '关闭此选项，豆盒缺豆不做提示，建议开启此项功能。',
            closeLeftPowerTip: '关闭此选项，左粉料盒缺粉不做提示，建议开启此项功能。',
            closeRightPowerTip: '关闭此选项，右粉料盒缺粉不做提示，建议开启此项功能。',
            closeMidPowerTip: '关闭此选项，中粉料盒缺粉不做提示，建议开启此项功能。',
            closeDescaleTip: '因关闭此选项导致的咖啡机系统故障，不在免费保修范围，建议开启此项功能。',
            closeMilkCleanTip: '因关闭此选项导致的牛奶系统故障，不在免费保修范围，建议开启此项功能。',
            no: '否',
            drinkPowderSw: '粉料开关',
            drinkSortSw: '饮品分类开关',
            drinkLock: '饮品状态同步锁定',
            ecoState:'ECO模式',
            globalConfigure:'全局通用',
            needConfigure:'按需配置',
            groupConfigure:'分组配置',
            customerConfigure:'客户配置',
            configureSync:'同步应用',
            isConfirmGlobalConfigure:'全局通用已启用,即将同步所有机器设置信息,是否继续?'
        },
        drink: {
            nameTitle: '饮品分类',
            selfCreateDrink: '自定义新增',
            deviceImport: '设备导入',
            tableNo: '编号',
            tableDrinkInfo: '饮品信息',
            tableDrinkBrand: '饮品分类',
            tableDrinkCreatedAt: '创建时间',
            tableDrinkHidden: '显示状态',
            tableDrinkSort: '排序(越小越靠前)',
            drinkSortTip: '饮品分类（数量只允许四个）',
            hiddenTxt: '是否隐藏',
            hidden: '隐藏',
            display: '展示',
            editBtn: '信息编辑',
            editCupBtn: '杯型编辑',
            searchDrinkNo: '编号搜索',
            searchDrinkName: '名称搜索',
            searchDrinkHidden: '显示状态',
            searchDrinkBrand: '饮品分类',
            createDrinkSort: '新增分类',
            editDrinkSort: '编辑分类',
            name: '饮品分类名称',
            nameTip: '请输入分类名称',
            sort: '排序',
            sortTip: '请输入排序编号',
            hiddenLabel: '隐藏分类',
            drinkImage:'饮品图片',
            drinkImg: '饮品图片:',
            drinkNo: '饮品编号:',
            drinkNoTip: '请输入饮品编号',
            drinkName: '饮品名称:',
            drinkNameTip: '请输入饮品名称',
            drinkEngName: '饮品英文名称:',
            drinkEngNameTip: '请输入饮品英文名称',
            drinkSort: '排序位置:',
            drinkSortTipLocation: '请输入排序位置',
            drinkBrand: '饮品分类:',
            drinkBrandTip: '请选取饮品分类',
            create: '新建饮品',
            edit: '编辑饮品',
            all: '全部',
            show: '展示饮品',
            hiddenDrink: '隐藏饮品',
            clearBrand: '全部',
            step: '步骤',
            selectCup: '请选择杯型',
            cup0: '小杯',
            cup1: '中杯',
            cup2: '大杯',
            choicePowder: '请选取粉料',
            fromDevice: '设备添加',
            fromCustomer: '客户同步',
            from: '饮品来源',
            price: '价格',
            inputPrice: '请输入价格',
            recycle: '循环次数',
            inputRecycle: '请输入制作循环次数',
            receptionOrder: '出品顺序',
            deleteStep: '删除步骤',
            powderType: '设置粉料类型',
            choicePowderType: '请选择粉料类型',
            atLeastOneCup: '至少需要一个饮品杯型',
            xuan_qu_la_qu_she_bei: '选取拉取设备',
            xuan_qu_she_bei_label: '选取设备:',
            needReception: '请配置配方',
            receptionTip: '提示：拖动对应的色块填充上方的步骤顺序，即为饮品的出品顺序；填充A和B即为同时制作。',
            percent: '杯型百分比',
            selectPercent: '请输入杯型百分比',
            percentTip: '提示:范围为80~120',
            acceptModel:'适用机型',
            commonModel:'通用机型',
            selectCupTip:'此杯型已被隐藏，请打开隐藏开关!',
            hiddenCup:'杯型隐藏',
            changeProductTip:'参数已修改，是否放弃保存继续切换机型?',
        },
        remote:{
            boilingQuickClean:'冲煮系统快速冲洗',
            powderQucikClean:'粉料系统快速冲洗',
            milkQuickClean:'牛奶系统快速冲洗',
            warning:'警告',
            warningTip:'冲洗过程中，咖啡出口处可能会有蒸汽喷出，禁止将手放在咖啡出口处，以免烫伤！',
            cleaning:'冲煮系统快速冲洗中，请稍候...',

        }
    },
    //菜单
    org: {
        manager: {
            router: '经销商管理',
            list: '列表信息',
            recycle: '回收站',
            paySetting: '支付设置',
            machine: '设备绑定'
        },
        basic: {
            router: '系统设置',
            setting: '基本信息',
            account: '成员管理',
            role: '权限分配',
            pay: '支付设置',
            setting: '机器设置',
            audit:'操作审计',
            dashboard:'大屏设置',
        }
    },
    equipment: {
        device: {
            router: '设备管理',
            list: '设备列表',
            file: '运营素材',
            daily: '日常清洁',
            task: '自动开关机',
            error: '异常列表',
            maintain: '维护明细',
            detail: '设备详情',
            drinks: '设备饮品',
            setting:'机器设置',
            org:'机器设置',
            department:'设备分组',
        },
        machine: {
            router: '机型管理',
            products: '机型列表',
            keys: '平台密钥',
            drinks: '饮品模型',
            version: '机型版本',
            model:'模型管理',
            step:'模型步骤',
            field:'步骤域',
            fieldRouter:'步骤域',
        },
        drink: {
            router: '饮品库',
            material: '原料管理',
            list: '饮品列表',
            cupEdit: "杯型编辑",
            group:'饮品分组',
            productGroup:'机型分组',
        },
        order: {
            router: '制作清单',
            list: '订单列表'
        },
        customer: {
            router: '客户管理',
            list: '客户分组',
            paySetting: '支付设置',
            drinkSetting: '饮品配置',
        },
        org:{
            machineSetting:'机器设置',
        },
        card:{
            router:'IC卡管理',
            list:'IC卡列表',
            history:'IC卡明细',
            register:'注册IC卡',
        },
        errors: '异常库',
        maintain: '维护保养',
    },
    leftPowderBox:'左粉料盒',
    middlePowderBox:'中粉料盒',
    rightPowderBox:'右粉料盒',
    autoPosition:'自动定位',
    coffee: '咖啡',
    powderBox: '粉料',
    hotWater: '热水',
    milk: '牛奶',
    foam: '奶沫',
    step: '步骤',
    recycleTimes: '循环次数',
    grindVal: '研磨量(g)',
    preCoolVal: '预冲煮时间 (s)',
    coffeeVal: '咖啡量',
    extractMode: '萃取方式',
    temperatureGarden: '温度等级',
    hotWaterVol: '热水量',
    milkTemperature: '牛奶温度',
    milkVol: '牛奶量(s)',
    steamVol: '奶沫量(s)',
    extractModeNormal: '常规',
    extractModeOne: '加浓',
    extractModeTwo: '深度',
    temperatureGardenLow: '低温',
    temperatureGardenMiddle: '中温',
    temperatureGardenHigh: '高温',
    drinkNameEngNameTxt: '饮品名/英文显示',
    startPower: '前段出水',
    waterTemperature: '水温',
    stirWater: '总水量',
    powerWeight: '粉料克重(g)',
    powerMode: '下粉速度(%)',
    afterWater: '后段出水',
    grindBeanRight: '右豆仓运转',
    grindBeanAHead: '同时磨豆',
    rightGrinder: '右磨豆机',
    grindBean: '豆仓选择',
    grindBeanLeft: '左豆仓运转',
    powerVal: '出粉量(g)',
    waterVol: '冷水量',
    delayVol: '等待时间(s)',
    pressureMode: '压力模式',
    hasPressure: '有压力',
    noPressure: '无压力',
    boiledMilk: '热牛奶',
    coolMilk: '冷牛奶',
    boiledFoam: '热奶沫',
    coolFoam: '冷奶沫',
    cover: '奶盖',
    leftPowerBox: '左粉料',
    middlePowerBox: '中粉料',
    rightPowerBox: '右粉料',
    water: '常温水',
    delay: '延时',
    priceTip: '提示:最多保留两位小数',
    drinkImport: '饮品导入',
    drinkLibraryImport:'饮品库导入',
    importSuccess: '导入成功!',
    hotSell: '热销',
    oneKeyImport: '一键导入',
    selectedImport: '勾选导入',
    notifyDevicePullSuccess: '通知设备更新成功!',
    notifyDevicePull: '设备同步更新',
    clearDeviceDrinks: '设备饮品清除',
    clearCustomerDrinks: '客户饮品清除',
    clearGroupDrinks:'一键清除',
    OneKeySyncDevice:'一键同步设备',
    chooseCompany: '选取公司',
    uploadOnlyType: '只能上传jpg,png,bmp格式！',
    afterTampingPressure: '后压力值',
    preCookWater: '预冲泡水量',
    tampingPress: '压粉力值',
    watchwxApplyStatus: '查看申请进度',
    watchwxApplyStatusTips: '请用超级管理员微信扫描，完成签约确认',
    superRoleCanNotDelete: '超级管理员账号不允许删除!',
    delHistroyOrder: '删除历史订单',
    isForceUpdate:'强制更新',
    import: '导入',
    downloadTemplate: '下载模板',
    confirmImport: '确认导入',
    pleaseUseTemplate: '请使用模板!',
    getEnterpriseList: '1:获取经销商信息',
    bindMachine: '2:绑定机器',
    deliveryListLabel: '经销商列表：',
    pleaseChooseDelivery: '请选择经销商',
    deliveryNameLabel: '经销商名称:',
    deliveryUuidLabel: '经销商uuid',
    deviceQrCode: '设备二维码:',
    pleaseScanTrueCode: '请扫描正确的条形码!',
    scanImeiNotFoundTip: '当前imei查询不到设备信息，请确认当前imei是否正确!',
    scanNoneMsgTip: '请扫描条形码获取设备信息!',
    scanNotFoundDeviceTip: '系统中不存在此设备信息,请联系管理员!',
    scanTip: '根据扫码枪扫码内容获取设备信息，并分配此设备给对应经销商',
    longTime: '长期',
    pleaseWriteIdCardInfo: '请填写身份证有效期结束时间',
    waitWXCheck: '等待微信审核',
    enterpriseUuidLabel: '经销商uuid:',
    pleaseWriteEnterpriseUuid: '请输入二代经销商uuid',
    getEnterpriseUuidMethod: '获取二代经销商uuid方式：',
    payNameLabel: '支付名称:',
    pleaseWritePayName: '请输入支付名称',
    bindV2PaySuccess: '绑定二代支付成功!',
    exampleUrl: '示例：',
    mixSpeed:'混合数据',
    addDrink:'新增',
    jiao_zhun:'校准',
    ae384efa8401000: '经销商创建',
    ae384efaa401000: '经销商审核',
    ae384efaf401000: '经销商查看',
    ae384efb4001000: '修改企业信息',
    ae384efb6001000: '成员删除',
    ae384efbd001000: '客户创建',
    ae384efbf001000: '客户查看',
    ae384efc1801000: '客户管理',
    ae384efc3401000: '客户删除',
    ae384efc6c01000: '经销商设备管理',
    ae384efc8001000: '设备查看',
    ae384efca401000: '设备信息更新',
    ae384efcc801000: '设备饮品管理',
    ae384efcf001000: '饮品模型创建',
    ae384efd1001000: '饮品模型管理',
    ae384efd3401000: '饮品模型删除',
    ae384efd6801000: '制作清单查看',
    ae384efd8401000: '订单退款',
    ae384efda801000: '机型创建',
    ae384efdc801000: '机型查看',
    ae384efdf001000: '机型版本管理',
    ae384efe2401000: '机型饮品模型',
    ae384efe4001000: '成员权限查看',
    ae384efe6001000: '成员权限管理',
    ae384efe8401000: '成员权限删除',
    ae384efea401000: '成员查看',
    ae384efed001000: '成员新增',
    ae384efef801000: '素材列表',
    ae3a3c910401000: '企业模块开通',
    ae784feb3c01000: '经销商成员管理',
    aec63047dc01000: '机型原料管理',
    aedc9e30a001000: '日常清洁查看',
    aedc9e30d401000: '日常清洁管理',
    aedc9e30d801000: '日常清洁删除',
    aee126563001000: '清洁同步设备',
    aef933609801000: '设备日清设置',
    aef933611801000: '设备自动开关机设置',
    af0959ef8c01000: '自动开关机管理',
    af0959f02001000: '同步设备',
    af0959f08801000: '删除信息',
    af0959f14001000: '异常查看',
    af0959f18401000: '异常导出',
    af0968d16c01000: '维护导出',
    afad5317d001000: '机器设置',
    afedc198a801000: '查看饮品',
    afedc828e001000: '饮品更新',
    afedc9383c01000: '饮品删除',
    b0a5ccbef001000: '导入饮品',
    b2a7f87e0801000: '企业支付配置',
    b2a7f87e0802000: '产线绑定设备',


}
