/**
 *  Shopro全局配置文件
 *  @version 1.2.0
 */

const ENV_BASE_URL = {


  // development: 'http://192.168.30.100:9000', // 开发环境
  // development:'http://192.168.164.128:8088',
  //  development:'http://124.71.174.90:16001',

  // development: 'http://test01.it.dr-coffee.cn', // 生产环境
//  development: 'https://apv3.dr-coffee.cn', // 生产环境

  // development: 'https://api.dr-coffee.cn', // 生产环境
 production: 'https://apv3.dr-coffee.cn' // 生产环境
}
const ENV_API_URL = {
  development: `${ENV_BASE_URL.development}`, // 开发环境
  production: `${ENV_BASE_URL.production}` // 生产环境
}


export let API_URL = ENV_API_URL[process.env.NODE_ENV || 'development'] // 后台接口域名

