/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t= i18n.global.t 
const device = [
    {
        name: 'equipment.device.list',
        path: 'devices',
        meta: {title: t('equipment.device.list'),keepAlive:true},
        component: () => import('@/pages/Delivery/Device/Devices'),
    },
 

]

export default device
