/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t = i18n.global.t
export default {
    name: 'equipment.machine.model',
    path: 'model',
    meta: {title: t('equipment.machine.model')},
    component: () => import('@/pages/Factory/Product/model.vue')
}
