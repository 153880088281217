/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t=i18n.global.t
export default {
    name: 'factory.product.list.redrict',
    path: 'redrict',
    meta: {title: t('equipment.machine.products')},
    component: () => import('@/pages/redirect'),
    children: [
        {
            name: 'factory.product.list.detail',
            path: 'detail/:uuid/:name',
            meta: {title: t('equipment.machine.version')},
            component: () => import('@/pages/Factory/Product/detail.vue'),
        },
        {
            name: 'factory.product.list.step',
            path: 'step/:uuid/:name',
            meta: {title: t('product.drinkModelEdit')},
            component: () => import('@/pages/Factory/Product/DrinkStep/'),
        },
    ]
}
