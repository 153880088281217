/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
import dict from './system/dict.js'


const t =i18n.global.t
const device = {
    name: 'org.basic',
    path: '/delivery/role',
    meta: {title: t('org.basic.router')},
    component: () => import('@/pages/redirect'),
    redirect: {name: 'org.basic.role'},
    children: [
        {
            name: 'org.basic.role',
            path: 'list',
            meta: {title: t('org.basic.role')},
            component: () => import('@/pages/Delivery/Role'),
        },
        {
            name: 'org.basic.account',
            path: 'users',
            meta: {title: t('org.basic.account')},
            component: () => import('@/pages/Delivery/Organization/Users/'),
        },
        {
            name: 'org.basic.pay',
            path: 'pay',
            meta: {title: t('org.basic.pay')},
            component: () => import('@/pages/Delivery/Pay'),
        },
        {
            name: 'org.basic.audit',
            path: 'audit',
            meta: {title: t('org.basic.audit')},
            component: () => import('@/pages/Factory/system/audit'),
        },
        {
            name: 'org.basic.dashboard',
            path: 'dashboard',
            meta: {title: t('org.basic.dashboard')},
            component: () => import('@/pages/Factory/system/dashboard'),
        },
        {
            name:'org.basic.dict',
            path:'dict',
            meta:{title:t('系统字典')},
            component:()=>import('@/pages/Factory/system/dict')
            
        },
        {
            name:'org.basic.entrance',
            path:'entrance',
            meta:{title:t('充值入口')},
            component:()=>import('@/pages/Factory/system/entrance')
            
        },
        {
            name:'org.basic.balance',
            path:'balance',
            meta:{title:t('设备余额')},
            component:()=>import('@/pages/Factory/system/balance')
        },
        {
            name:'org.basic.log',
            path:'log',
            meta:{title:t('余额变更日志')},
            component:()=>import('@/pages/Factory/system/log')
        }
    ].concat(dict)
}

export default device
