import dw from './api-dw'
import device from './api-device'
import customer from './api-customer'
import area from './api-area'
import product from './api-product'
import order from './api-order'
import clean from './api-clean'
import errorRecord from './api-errorRecord'
import autoPower from './api-autoPower'
import maintain from './api-maintain'
import drinkStep from './api-drinkStep'
import org from './api-org'
import group from './api-group'
import department from './api-department'
import card from './api-card'
import material from './api-material'

export default {
  dw: dw,
  device:device,
  customer:customer,
  area:area,
  product:product,
  order: order,
  clean:clean,
  errorRecord:errorRecord,
  autoPower:autoPower,
  maintain:maintain,
  drinkStep:drinkStep,
  org:org,
  group:group,
  department:department,
  card:card,
  material:material,
}