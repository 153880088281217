export default {
    list: {
      url: '/v1/equipment/maintain/list',
      method: 'GET',
      auth: true
    },
    code:{
        url: '/v1/equipment/maintain/code',
        method: 'GET',
        auth: true
    },
    cnt:{
        url: '/v1/equipment/maintain/cnt',
        method: 'GET',
        auth: true
    },
    export:{
        url: '/v1/equipment/maintain/export',
        method: 'GET',
        auth: true
    },
    queryExportTask:{
        url: '/v1/equipment/maintain/queryExportTask',
        method: 'GET',
        auth: true
    },
    update:{
        url: '/v1/equipment/maintain/update',
        method: 'POST',
        auth: true
    },
    create:{
        url: '/v1/equipment/maintain/create',
        method: 'POST',
        auth: true
    },
    union:{
        url: '/v1/equipment/maintain/union',
        method: 'GET',
        auth: true
    },
    maintainSteps:{
        url: '/v1/equipment/maintain/maintainSteps',
        method: 'GET',
        auth: true
    }
}
