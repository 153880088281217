export default {
    list: {
        url: '/v1/equipment/drinkStep/list',
        method: 'GET',
        auth: true
    },
    create:{
        url: '/v1/equipment/drinkStep/create',
        method: 'POST',
        auth: true
    },
    delete: {
        url: '/v1/equipment/drinkStep/delete',
        method: 'GET',
        auth: true
    },
    update: {
        url: '/v1/equipment/drinkStep/update',
        method: 'POST',
        auth: true
    },
    updateField: {
        url: '/v1/equipment/drinkStep/updateField',
        method: 'POST',
        auth: true
    },
    deleteField: {
        url: '/v1/equipment/drinkStep/deleteField',
        method: 'GET',
        auth: true
    },
    createField:{
        url: '/v1/equipment/drinkStep/createField',
        method: 'POST',
        auth: true
    },
    fields: {
        url: '/v1/equipment/drinkStep/fields',
        method: 'GET',
        auth: true
    }

}