const type = {
    // account_enterprise
    SET_ENTERPRISE_NAV_MENU: 'SET_ENTERPRISE_NAV_MENU',
    SET_ENTERPRISE_EMPLOYEE: 'SET_ENTERPRISE_EMPLOYEE',
    SET_PERMISSION_MAP:'SET_PERMISSION_MAP',
    SET_PROVE_NAME:'SET_PROVE_NAME',
    SET_CURRENT_ORG_NAME:'SET_CURRENT_ORG_NAME',
    SET_REPLACE_ORG_NAME:'SET_REPLACE_ORG_NAME',

    // factory_product
    SET_PRODUCT_NAME: 'SET_PRODUCT_NAME',
    SET_NOTIFY_CONNECT: 'SET_NOTIFY_CONNECT',

}
export default type




