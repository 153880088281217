/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t = i18n.global.t
export default {
    name: 'equipment.machine.modelRouter',
    path: 'model',
    meta: { title: t('equipment.machine.model') },
    component: () => import('@/pages/redirect'),
    children: [
        {
            name: 'equipment.machine.field',
            path: 'model',
            meta: { title: t('equipment.machine.field') },
            component: () => import('@/pages/Factory/Product/step.vue')
        }
    ]
}
