export default {
    list: {
      url: '/v1/equipment/order/list',
      method: 'POST',
      auth: true
    },
    union:{
        url: '/v1/equipment/order/union',
        method:'POST',
        auth: true
    },
    cnt:{
        url:'/v1/equipment/order/cnt',
        method: 'POST',
        auth: true
    },
    refund:{
        url:'/v1/equipment/order/refund',
        method: 'POST',
        auth: true
    },
    wxRefund:{
        url:'/v1/equipment/order/wxRefund',
        method: 'POST',
        auth: true
    },
    queryExportTask:{
        url:'/v1/equipment/order/queryExportTask',
        method: 'GET',
        auth: true
    },
    export:{
        url:'/v1/equipment/order/export',
        method:'POST',
        auth: true
    },
    attachInfo:{
        url:'/v1/equipment/order/attachInfo',
        method:'POST',
        auth:true
    },
    todayCountOrders:{
        url:'/v1/equipment/order/todayCountOrders',
        method:'GET',
        auth:true
    }
}
