/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t =i18n.global.t

export default {
    name: 'equipment.drink.productGroup',
    path: 'productGroup',
    meta: {title: t('equipment.drink.productGroup')},
    component: () => import('@/pages/equipment/drink/nGroup.vue'),

}
