/**
 * 接口列表文件
 */
import account from './account/api'
import org from './org/api'
import space from './space/api'
import equipment from './equipment/api'
import log from './log/api'

export default {

  /**  账户板块 ↓ **/
  account: account,

  /**  组织板块 ↓ **/
  org: org,

  /**  空间板块 ↓ **/
  space: space,

  /**  设备模块 ↓ **/
  equipment: equipment,

  /**  日志模块 ↓ **/
  log:log,
}
