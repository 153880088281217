
import payset from './payset'
import i18n from '@/i18n'
const t=i18n.global.t
const device = {
    name: 'equipment.customer.router',
    path: '/delivery/organization',
    meta: {title: t('equipment.customer.router')},
    component: () => import('@/pages/redirect'),
    redirect: {name: 'equipment.customer.list'},
    children: [
        {
            name:'equipment.customer.list',
            path: 'customer',
            meta: {title: t('equipment.customer.list'),keepAlive:true},
            component: () => import('@/pages/Delivery/Organization/Customer'),
        }
    ].concat(payset)
}

export default device


