/**
 * Created by fworld on 2019/6/29.
 */

import product from './product'
import detail from './detail'
import model from './model'
import filed from './filed'
import i18n from '@/i18n'
import productFiled from './productFiled'
const t =i18n.global.t

const device = {
    name: 'equipment.machine',
    path: '/factory/product',
    meta: {title: t('equipment.machine.router')},
    component: () => import('@/pages/redirect'),
    redirect: {name: 'equipment.machine.products'},
    children: [
        
    ].concat(detail).concat(product).concat(model).concat(filed).concat(productFiled)
}

export default device


