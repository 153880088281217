export default {
    create: {
      url: '/v1/equipment/autoPower/create',
      method: 'POST',
      auth: true
    },
    delete:{
        url: '/v1/equipment/autoPower/delete',
        method: 'POST',
        auth: true
    },
    syncDevice:{
        url: '/v1/equipment/autoPower/syncDevice',
        method: 'POST',
        auth: true
    },
    update:{
        url: '/v1/equipment/autoPower/update',
        method: 'POST',
        auth: true  
    },
    list:{
        url: '/v1/equipment/autoPower/list',
        method: 'GET',
        auth: true
    },
    union:{
        url: '/v1/equipment/autoPower/union',
        method: 'GET',
        auth: true
    }
}