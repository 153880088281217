/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t =i18n.global.t 
const device = [
    {
        name: 'delivery.devices.redrict',
        path: 'redrict',
        meta: {title: t('equipment.device.list')},
        component: () => import('@/pages/redirect'),
        children:[
            {
                name: 'delivery.devices.detail',
                path: 'detail',
                meta: {title: t('equipment.device.detail')},
                component: () => import('@/pages/Delivery/Device/Devices/detail.vue'),
            },
            {
                name: 'delivery.devices.drinks',
                path: 'drinks',
                meta: {title: t('equipment.device.drinks')},
                component: () => import('@/pages/Delivery/Device/Devices/deviceDrinks'),
            },
        
        ]
    }

]

export default device
