export default {
    list: {
      url: '/v1/equipment/area/list',
      method: 'GET',
      auth: true
    },
    union: {
        url: '/v1/equipment/area/union',
        method: 'GET',
        auth: true
    },
    create: {
        url: '/v1/equipment/area/create',
        method: 'POST',
        auth: true
    },
    delete: {
        url: '/v1/equipment/area/delete',
        method: 'POST',
        auth: true
    },
    update: {
        url: '/v1/equipment/area/update',
        method: 'POST',
        auth: true
    },
    bindDevice: {
        url: '/v1/equipment/area/bindDevice',
        method: 'POST',
        auth: true
    },
    unbindDevice: {
        url: '/v1/equipment/area/unbindDevice',
        method: 'POST',
        auth: true
    },
    deviceCnt:{
        url: '/v1/equipment/area/deviceCnt',
        method: 'GET',
        auth : true
    },
    deviceList:{
        url: '/v1/equipment/area/deviceList',
        method: 'GET',
        auth: true
    }
}