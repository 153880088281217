export default {
    departmentCreate:{
      url:'/v1/equipment/department/create',
      method:'POST',
      auth:true
    },
    departmentList:{
      url:'/v1/equipment/department/list',
      method:'GET',
      auth:true
    },
    departmentUpdate:{
      url:'/v1/equipment/department/update',
      method:'POST',
      auth:true
    },
    departmentDelete:{
      url:'/v1/equipment/department/delete',
      method:'GET',
      auth:true
    },
    bindDepartmentDevice:{
      url:'/v1/equipment/department/bindDepartmentDevice',
      method:'POST',
      auth:true
    },
    bindProve:{
      url:'/v1/equipment/department/bindProve',
      method:'POST',
      auth:true
    },
    consumePercent:{
      url:'/v1/equipment/department/consumePercent',
      method:'GET',
      auth:true
    },
    deviceList:{
      url:'/v1/equipment/department/deviceList',
      method:'GET',
      auth:true
    },
    proveInfoList:{
      url:'/v1/equipment/department/proveInfoList',
      method:'GET',
      auth:true
    },
    unBindDepartmentDevice:{
      url:'/v1/equipment/department/unBindDepartmentDevice',
      method:'POST',
      auth:true
    },
    unBindProve:{
      url:'/v1/equipment/department/unBindProve',
      method:'POST',
      auth:true
    }
  }
