<template>
    <el-button type="text"
               @click="$router.go(-1)"
    ><i class="iconfont icon-fanhui"></i> {{$t('fan_hui')}}</el-button>

</template>

<style lang="scss">
    .router-back{

    }
</style>
