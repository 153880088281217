export default {
    union:{
      url:'/v1/equipment/group/union',
      method: 'GET',
      auth : true
    },
    groupCreate:{
      url:'/v1/equipment/group/create',
      method:'POST',
      auth:true
    },
    groupList:{
      url:'/v1/equipment/group/list',
      method:'GET',
      auth:true
    },
    groupCnt:{
      url:'/v1/equipment/group/cnt',
      method:'GET',
      auth:true
    },
    groupUpdate:{
      url:'/v1/equipment/group/update',
      method:'POST',
      auth:true
    },
    groupDelete:{
      url:'/v1/equipment/group/delete',
      method:'GET',
      auth:true
    },
    groupProductList:{
      url:'/v1/equipment/group/productList',
      method:'GET',
      auth:true
    },
    drinkDownload:{
      url:'/v1/equipment/group/drinkDownload',
      method:'GET',
      auth:true
    },
    syncDevice:{
      url:'/v1/equipment/group/syncDevice',
      method:'POST',
      auth:true
    },
    unSyncDevice:{
      url:'/v1/equipment/group/unSyncDevice',
      method:'POST',
      auth:true
    },
    versionList:{
      url:'/v1/equipment/group/versionList',
      method:'GET',
      auth:true
    },
    versionCnt:{
      url:'/v1/equipment/group/versionCnt',
      method:'GET',
      auth:true
    },
    versionUnion:{
      url:'/v1/equipment/group/versionUnion',
      method:'GET',
      auth:true
    },
    versionSaveCopy:{
      url:'/v1/equipment/group/versionSaveCopy',
      method:'GET',
      auth:true
    },
    versionQueryCopy:{
      url:'/v1/equipment/group/versionQueryCopy',
      method:'GET',
      auth:true
    }
  }
