/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t =i18n.global.t
export default {
    name: 'delivery.library.drinkLibrary.redrict',
    path: 'drinkLibrary',
    meta: {title: t('equipment.drink.list')},
    component: () => import('@/pages/redirect'),
    children: [
        {
            name: 'equipment.drink.cupEdit',
            path: 'cup',
            meta: {title: t('equipment.drink.cupEdit')},
            component: () => import('@/pages/equipment/drink/cup'),
        }
    ]
}
