export default {
  machineSetting: {
    url: '/v1/equipment/org/machineSetting',
    method: 'GET',
    auth: true
  },
  machineSettingUpdate: {
    url: '/v1/equipment/org/machineSettingUpdate',
    method: 'POST',
    auth: true
  },
  machineSettingSyncCustomer: {
    url: '/v1/equipment/org/settingSync',
    method: 'GET',
    auth: true
  }

}