/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t = i18n.global.t
export default {
    name: 'equipment.machine.products',
    path: 'list',
    meta: {title: t('equipment.machine.products')},
    component: () => import('@/pages/Factory/Product/'),

}
