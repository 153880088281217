/**
 * Created by fworld on 2019/6/29.
 */
import delivery from './delivery'
import payset from './payset'
import machine from './machine'
import i18n from '@/i18n'
const t =i18n.global.t

const device = {
    name: 'org.manager',
    path: '/factory/delivery',
    meta: {title: t('org.manager.router')},
    component: () => import('@/pages/redirect'),
    redirect: {name: 'org.manager.list'},
    children: [
        
    ].concat(delivery).concat(payset).concat(machine)
}

export default device


