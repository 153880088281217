/**
 * Created by fworld on 2019/9/19.
 */
import i18n from '@/i18n'
const t =i18n.global.t
export default {
    name: 'org.basic.redrict',
    path: 'dict',
    meta: {title: t('系统字典')},
    component: () => import('@/pages/redirect'),
    children: [
        {
            name: 'org.basic.dict.data',
            path: 'data',
            meta: {title: t('字典数据')},
            component: () => import('@/pages/Factory/system/dict/data.vue'),
        },
    ]
}
