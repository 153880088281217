/**
 * Created by fworld on 2019/6/29.
 */
import i18n from '@/i18n'
const t= i18n.global.t
const payset = [
    {
        name:'delivery.organization.customer.redirect',
        path: 'customer',
        meta: {title: t('equipment.customer.list')},
        component: () => import('@/pages/redirect'),
        children:[
            {
                name: 'delivery.organization.customer.paySet',
                path: 'payset',
                meta: {title: t('equipment.customer.paySetting')},
                component: () => import('@/pages/Delivery/Organization/Customer/paySet.vue'),
            },
            {
                name: 'delivery.organization.customer.drink',
                path: 'drink',
                meta: {title: t('equipment.customer.drinkSetting')},
                component: () => import('@/pages/Delivery/Organization/Customer/drink'),
            }
        
        ]
    }

]

export default payset
