export default {
  oauth: {
    url: '/v1/space/file/oauth',
    method: 'POST',
    auth: true
  },
  deviceOauth:{
    url: '/v1/space/file/deviceOauth',
    method: 'POST',
    auth: true
  },
  list:{
    url: '/v1/space/file/list',
    method: 'POST',
    auth: true
  },
  union:{
    url: '/v1/space/file/union',
    method: 'POST',
    auth: true
  }
}
